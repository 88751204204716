import { memo } from "react";
import EarnCoinsForm from "./EarnCoinsForm";
import "../../assets/scss/base/pages/cart/EarnPointsBillForm.scss";

const EarnPointsBillForm = memo(() => {
  return (
    <div className="frame-parent9EarnPointsBillForm">
      {/* <EarnCoinsForm /> */}
      <div className="bill-details-parentEarnPointsBillForm">
        <div className="bill-detailsEarnPointsBillForm">Bill Details</div>
        <div className="frame-parent10EarnPointsBillForm">
          <div className="sku-hrw-not-0001601-parentEarnPointsBillForm">
            <div className="sku-hrw-not-0001601EarnPointsBillForm">
              Sub Total
            </div>
            <div className="sku-hrw-not-00016011EarnPointsBillForm">
              RM 2,909.00
            </div>
          </div>
          <div className="sku-hrw-not-0001601-parentEarnPointsBillForm">
            <div className="sku-hrw-not-0001601EarnPointsBillForm">
              Discount on product
            </div>
            <div className="sku-hrw-not-00016013EarnPointsBillForm">
              -RM 90.00
            </div>
          </div>
          <div className="sku-hrw-not-0001601-parentEarnPointsBillForm">
            <div className="sku-hrw-not-0001601EarnPointsBillForm">
              Shipping
            </div>
            <div className="sku-hrw-not-00016011EarnPointsBillForm">
              RM 5.00
            </div>
          </div>
          <div className="sku-hrw-not-0001601-parentEarnPointsBillForm">
            <div className="sku-hrw-not-0001601EarnPointsBillForm">
              Coupon Discount
            </div>
            <div className="sku-hrw-not-00016017EarnPointsBillForm">Apply</div>
          </div>
          <div className="sku-hrw-not-0001601-parentEarnPointsBillForm">
            <div className="sku-hrw-not-0001601EarnPointsBillForm">Voucher</div>
            <div className="sku-hrw-not-00016017EarnPointsBillForm">Apply</div>
          </div>
          <div className="sku-hrw-not-0001601-parentEarnPointsBillForm">
            <div className="sku-hrw-not-0001601EarnPointsBillForm">
              P-Coin Redemption
            </div>
            <div className="sku-hrw-not-00016011EarnPointsBillForm">-</div>
          </div>
          <div className="line-divEarnPointsBillForm" />
          <div className="sku-hrw-not-0001601-parent4EarnPointsBillForm">
            <div className="sku-hrw-not-000160112EarnPointsBillForm">Total</div>
            <div className="sku-hrw-not-000160113EarnPointsBillForm">
              RM 3,004.00
            </div>
          </div>
        </div>
      </div>
      <div className="button-wrapperEarnPointsBillForm">
        <button className="button1EarnPointsBillForm">
          <div className="state-layer3EarnPointsBillForm">
            <div className="label-text3EarnPointsBillForm">Continue</div>
          </div>
        </button>
      </div>
    </div>
  );
});

export default EarnPointsBillForm;
