/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

//components
import OrderedItem from "../../components/SingleOnGoingOrder/OrderedItem";
//styles
import "../../assets/scss/base/pages/SingleOngoingOrder/SingleOngoingOrderPage/SingleOngoingOrderPage.scss";
import AccountMenu from "./AccountMenuNavigation/AccountMenu";
//images
import DeliveredTick from "../../assets/images/account/Order/DeliveredTick.svg";
import PCoin from "../../assets/images/account/Order/PCoin.svg";
import FileIcon from "../../assets/images/fileIcon.svg";
//services
import { OrderDetailService } from "../../service/OrderList.service";
import RateReviewModal from "../../components/order/Modal/RateReviewModal";
import SuccessMessageModal from "../../components/order/Modal/SuccessMessageModal";
import ProductTracker from "../../components/ProductTracker/ProductTracker";
import Loader from "../../components/micro/loader/Loader";
import { priceCalculator } from "../../utils/Utility";
import { Helmet } from "react-helmet";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

const SingleOngoingOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [orderedItem, setOrderedItem] = useState();
  const [Total, setTotal] = useState();
  const [totalProductPrice, setTotalProductPrice] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isOpenSuccesModal, setIsOpenSuccessModal] = useState(false);
  console.log(orderedItem, "orderedItem");
  const totalOrderAmount = () => {
    let totalPrice = 0;
    let grandTotal = 0;
    for (let i = 0; i < orderedItem?.OrderItems?.length; i++) {
      totalPrice = totalPrice + Number(orderedItem?.OrderItems[i]?.price);

      setTotal(totalPrice);
    }
    grandTotal =
      orderedItem?.total +
      Number(orderedItem?.shippingCost) -
      (Number(orderedItem?.discountOnProduct) +
        Number(orderedItem?.couponDiscount) +
        Number(orderedItem?.voucherDiscount) +
        Number(orderedItem?.redeemedPcoinValue));
    setTotalProductPrice(grandTotal);
  };

  const fetchOrderDetail = async () => {
    const response = await OrderDetailService({ id: id });
    setOrderedItem(response?.data?.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchOrderDetail();
  }, [isOpenSuccesModal]);
  useEffect(() => {
    totalOrderAmount();
  });
  //Order Tracker Function

  const OrderTracker = (e, trackingID) => {
    e.preventDefault();

    TrackButton.track({
      tracking_no: trackingID,
    });
  };

  return (
    <AccountMenu>
       <Helmet>
                <meta charSet="utf-8" />
                <title > Account | Order Detail</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      {isLoading ? (
        <div style={{ width: "100%" }}>
          <Loader />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <h4>Order ID : #PC000{id}</h4>

            {orderedItem?.invoice && (
              <p
                className="Invoice__Btn"
                onClick={() => window.open(`${url}/${orderedItem?.invoice}`)}
              >
                <img src={FileIcon} alt="File-Icon" /> Invoice
              </p>
            )}
          </div>
          {orderedItem?.status === "Payment Failed" && (
            <div>
              <span style={{ fontFamily: "Poppins", color: "#d71513" }}>
                {orderedItem?.status}
              </span>
            </div>
          )}

          {orderedItem?.OrderItems?.map((item, index) => {
            return (
              <OrderedItem
                orderedItem={item}
                setOpenModal={setOpenModal}
                deliverystatus={orderedItem?.deliverystatus?.delivered?.done}
                key={index}
                setSelectedProduct={setSelectedProduct}
              />
            );
          })}

          {orderedItem?.deliverystatus?.delivered?.done ? (
            <>
              <div className="Delivered__OrderPage__Block">
                <img src={DeliveredTick} alt="delivered-icon" />
                <div>
                  <h6 style={{ fontFamily: "Poppins" }}>Delivered </h6>
                  <p>
                    on{" "}
                    {moment
                      .unix(orderedItem?.deliverystatus?.delivered?.date)
                      .format("MMM, Do YYYY")}
                  </p>
                </div>
              </div>
            </>
          ) : (
            orderedItem?.status !== "Payment Failed" && (
              <ProductTracker deliverystatus={orderedItem?.deliverystatus} />
            )
          )}
          <div className="SingleOngoingOrder__page__RewardSec">
            Your Rewards on this order <br />
            <img src={PCoin} alt="PCoin" className="PCoin_Icon" />
            {orderedItem?.earnedPcoin} P-Coins
          </div>
          <div className="SingleOngoingOrder__page__ShipAddressSec">
            <h6 className="SingleOngoingOrder__page__Header">
              Shipping Address
            </h6>
            <h4 className="SingleOngoingOrder__page__Title">
              {orderedItem?.shippingAddress?.receiptient}
            </h4>
            <p className="SingleOngoingOrder__page__Desc">
            {orderedItem?.shippingAddress?.company}              <br />
            {orderedItem?.shippingAddress?.tin}  <br />
              {orderedItem?.shippingAddress?.address},
              {orderedItem?.shippingAddress?.city}
              <br />
              {orderedItem?.shippingAddress?.state},
              {orderedItem?.shippingAddress?.country}-
              {orderedItem?.shippingAddress?.zipCode}

              
            </p>
            <div className="SingleOngoingOrder__page__ContactDesc">
              <p className="SingleOngoingOrder__page__ContactDesc__Header">
                Mobile:
              </p>
              <p>+6 {orderedItem?.shippingAddress?.mobile}</p>
            </div>
          </div>
          {orderedItem?.billingAddress && (
            <div className="SingleOngoingOrder__page__ShipAddressSec">
              <h6 className="SingleOngoingOrder__page__Header">
                Billing Address
              </h6>
              <h4 className="SingleOngoingOrder__page__Title">
                {orderedItem?.billingAddress?.receiptient}
              </h4>
              <p className="SingleOngoingOrder__page__Desc">
              {orderedItem?.billingAddress?.company} <br />
              {orderedItem?.billingAddress?.tin} <br />
                {orderedItem?.billingAddress?.address},
                {orderedItem?.billingAddress?.city}
                <br />
                {orderedItem?.billingAddress?.state},
                {orderedItem?.billingAddress?.country}-
                {orderedItem?.billingAddress?.zipCode}
              
               
              </p>
              <div className="SingleOngoingOrder__page__ContactDesc">
                <p className="SingleOngoingOrder__page__ContactDesc__Header">
                  Mobile:
                </p>
                <p>+6 {orderedItem?.billingAddress?.mobile}</p>
              </div>
            </div>
          )}
          <div className="SingleOngoingOrder__page__ShipAddressSec">
            <h4 className="SingleOngoingOrder__page__Header">Bill Details</h4>
            <div className="SingleOngoingOrder_Page_Wrapper">
              <p className="SingleOngoingOrder__page__Desc">Sub Total</p>
              <p className="SingleOngoingOrder__page__DescValue">
                RM{" "}
                {/* {priceCalculator({
                  price: totalProductPrice,
                  discountPrice: totalProductDiscount,
                }) + orderedItem?.discountOnProduct.toFixed(2)} */}
                {orderedItem?.total?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="SingleOngoingOrder_Page_Wrapper">
              <p className="SingleOngoingOrder__page__Desc">
                Discount on product
              </p>
              <p
                className="SingleOngoingOrder__page__DescValue"
                style={{ color: "green" }}
              >
                {`-RM ${orderedItem?.discountOnProduct.toFixed(2)}`}
              </p>
            </div>
            <div className="SingleOngoingOrder_Page_Wrapper">
              <p className="SingleOngoingOrder__page__Desc">Shipping</p>
              <p className="SingleOngoingOrder__page__DescValue">
                RM {orderedItem?.shippingCost.toFixed(2)}
              </p>
            </div>
            {
              <div className="SingleOngoingOrder_Page_Wrapper">
                <p className="SingleOngoingOrder__page__Desc">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {orderedItem?.couponDiscount && (
                      <div>
                        Coupon discount <span>({orderedItem?.coupon}) </span>
                      </div>
                    )}
                    {orderedItem?.voucherDiscount && (
                      <div style={{ textAlign: "start" }}>
                        Voucher <span>({orderedItem?.vocuher})</span>
                      </div>
                    )}
                  </div>
                  {orderedItem?.redeemedPcoin && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <p>P-Coin Redemption</p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.2rem",
                          color: "black",
                        }}
                      >
                        <img src={PCoin} alt="pCoin" />
                        {orderedItem?.redeemedPcoin}
                      </p>
                      <p style={{ color: "black" }}>P-Coins</p>
                    </div>
                  )}
                </p>
                <div
                  className="SingleOngoingOrder__page__DescValue"
                  style={{ color: "green" }}
                >
                  <p>
                    {orderedItem?.couponDiscount &&
                      "-RM" + " " + orderedItem?.couponDiscount.toFixed(2)}
                  </p>
                  <p style={{ textAlign: "end" }}>
                    {orderedItem?.voucherDiscount &&
                      "-RM" + " " + orderedItem?.voucherDiscount.toFixed(2)}
                  </p>
                  <p style={{ textAlign: "end" }}>
                    {orderedItem?.redeemedPcoinValue &&
                      "-RM " +
                        `${parseFloat(orderedItem?.redeemedPcoinValue)?.toFixed(
                          2
                        )}`}
                  </p>
                </div>
              </div>
            }

            {/* 
            <div className="SingleOngoingOrder_Page_Wrapper">
              <p className="SingleOngoingOrder__page__Desc">
                P-Coin redemption
              </p>
              <p className="SingleOngoingOrder__page__DescValue">
                {orderedItem?.redeemedPcoinValue}
              </p>
            </div> */}
            <hr />
            <div className="SingleOngoingOrder_Page_Wrapper">
              <h4 className="SingleOngoingOrder__page__Header">Total</h4>
              <p className="SingleOngoingOrder__page__Value">
                RM{" "}
                {Math.abs(totalProductPrice)?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>
          {orderedItem?.trackingNo && (
            <div className="SingleOngoingOrder__page__ShipAddressSec">
              <h3 className="SingleOngoingOrder__page__Header">Tracking</h3>
              <div className="SingleOngoingOrder_Page_Wrapper">
                <p className="SingleOngoingOrder_Page_Wrapper__Head">
                  {orderedItem?.trackingNo}
                </p>
                <p
                  className="SingleOngoingOrder_Page_Wrapper__Link"
                  onClick={(e) => OrderTracker(e, orderedItem?.trackingNo)}
                >
                  <u>Track my order</u>
                </p>
              </div>
            </div>
          )}
          <div className="SingleOngoingOrder__page__ShipAddressSec">
            <h3 className="SingleOngoingOrder__page__Header">Payment Method</h3>
            <div className="SingleOngoingOrder_Page_Wrapper">
              <p className="SingleOngoingOrder_Page_Wrapper__Head">
                {orderedItem?.PaymentMethod?.name}
              </p>
              <img
                src={`${url}/${orderedItem?.PaymentMethod?.file}`}
                alt="Payment-Icon"
                className="Payment__Icon"
              />
            </div>
          </div>
          <div className="SingleOngoingOrder__page__ShipAddressSec">
            <h3 className="SingleOngoingOrder__page__Header">
              Shipping Method
            </h3>
            <div>
              <p className="SingleOngoingOrder_Page_Wrapper__Head">
                {orderedItem?.isSelfPickup &&
                  "Self-Collect from Wisma Pineapple"}
                {!orderedItem?.isSelfPickup &&
                  `Shipping rate RM
                ${(orderedItem?.shippingCost).toFixed(2)} `}
              </p>
            </div>
          </div>
          {isOpenSuccesModal && (
            <SuccessMessageModal
              isOpenSuccesModal={isOpenSuccesModal}
              setIsOpenSuccessModal={setIsOpenSuccessModal}
            />
          )}
          {openModal && (
            <RateReviewModal
              isOpen={openModal}
              orderedItem={orderedItem}
              selectedProduct={selectedProduct}
              setOpenModal={setOpenModal}
              setIsOpenSuccessModal={setIsOpenSuccessModal}
            />
          )}
        </div>
      )}
    </AccountMenu>
  );
};

export default SingleOngoingOrder;
