import { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import { Label, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import Drawer from "react-modern-drawer";
import { CiCircleRemove } from "react-icons/ci";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

// API-Service
import {
  getProductList,
  getProductListWithFilter,
} from "../../service/catalogue.service";

// Redux-Settings
import { selectCategoriesList } from "../../redux/categoriesSlice";
import {
  selectIsSmallScreen,
  selectCategoryDropdownValue,
  selectBannerList,
} from "../../redux/utilitySlice";
import {
  setProductList,
  setFilteredList,
  setTotalItem,
  selectPageCount,
  selectTotalItem,
} from "../../redux/productSlice";

// Component
import MultiRangeSlider from "../../components/micro/multiRangeSlider/multiRangeSlider";
import CataloguePagination from "../../components/catalogue/CataloguePagination";
import ScrollToTop from "../../utils/ScrollToTop";
// Higher-Order-Component
import withLayout from "../../components/higherOrderComponent/PagesLayout/PagesLayout";

// Images
import PromoBannerImage from "../../assets/images/homepage/new-arrival-banner.png";

// Styles
import "react-loading-skeleton/dist/skeleton.css";
import "../../assets/scss/base/pages/homepage/CataloguePage.scss";
import { getBannersList } from "../../service/dashboard.service";

const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

const PromoPage = () => {
  const dispatch = useDispatch();
  const pages = useSelector(selectPageCount);
  const bannerList = useSelector(selectBannerList);
  const { name } = useParams();
  const { state } = useLocation();
  // const { state } = useSelector(selectCategoryDropdownValue);
  const totalItem = useSelector(selectTotalItem);
  const categories = useSelector(selectCategoriesList);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState({});
  const [selectedBrand, setselectedBrand] = useState({});
  const [barndsSelection, setBarndsSelection] = useState([]);
  const [subCategoriesSlection, setSubCategoriesSlection] = useState([]);
  const [optionSelection, setOptionSelection] = useState("");
  const [banner, setBanner] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(name, "name")
  var maxPriceValue = 1000;
  var minPriceValue = 0;
  const handleMinMax = ({ min, max }) => {
    maxPriceValue = max;
    minPriceValue = min;
  };
  const toggleDrawer = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const sleceTedCategoryResult = categories?.find(
      (categoryItem) => state?.id === categoryItem?.id
    );

    if (state?.subCatId) {
      const sleceTedSubCategoryResult =
        sleceTedCategoryResult?.SubCategory?.find(
          (subCategoryItem) => state?.subCatId === subCategoryItem?.id
        );
      const sleceTedBrandResult =
        sleceTedSubCategoryResult?.CategoryBrands?.find(
          (brandItem) => state?.brandId === brandItem?.Brand?.id
        );

      const modifiedSubCategoryData =
        sleceTedCategoryResult?.SubCategory?.map((checkbox) =>
          checkbox.id === state?.subCatId
            ? { ...checkbox, checked: true }
            : { ...checkbox, checked: false }
        ) || [];

      const modifiedBrandData =
        sleceTedSubCategoryResult?.CategoryBrands?.map((brandItem) =>
          brandItem?.Brand?.id === state?.brandId
            ? { ...brandItem, checked: true }
            : { ...brandItem, checked: false }
        ) || [];

      const newSubCtegoryData = {
        id: "subCategoryAll",
        name: "All",
        checked: false,
      };

      const newBrandData = {
        id: "barndAll",
        Brand: { name: "All", id: "barndAll" },
        checked: false,
      };

      setSelectedCategory(sleceTedCategoryResult);
      setSelectedSubCategory(sleceTedSubCategoryResult);
      setselectedBrand(sleceTedBrandResult);
      setSubCategoriesSlection([newSubCtegoryData, ...modifiedSubCategoryData]);
      setBarndsSelection([newBrandData, ...modifiedBrandData]);
    } else {
      const modifiedSubCategoryData =
        sleceTedCategoryResult?.SubCategory?.map((item) => ({
          checked: false,
          ...item,
        })) || [];

      const newSubCtegoryData = {
        id: "subCategoryAll",
        name: "All",
        checked: true,
      };

      setSubCategoriesSlection([newSubCtegoryData, ...modifiedSubCategoryData]);
    }
  }, [state]);
 
  const handleCategoryCheckboxChange = (event) => {
    const { name: subCategoryIdLocal, checked } = event.target;
    const sleceTedSubCategoryResult = selectedCategory?.SubCategory?.find(
      (subCategoryItem) =>
        Number(subCategoryIdLocal) === Number(subCategoryItem?.id)
    );
    const updatedCheckboxes = subCategoriesSlection?.map((subCategorieItem) =>
      subCategorieItem.id === subCategoryIdLocal
        ? { ...subCategorieItem, checked }
        : { ...subCategorieItem, checked: false }
    );
    setSubCategoriesSlection(updatedCheckboxes);

    const newData = {
      id: "subCategoryAll",
      name: "All",
      checked: false,
    };

    const newBrandData = {
      id: "barndAll",
      Brand: { name: "All", id: "barndAll" },
      checked: true,
    };

    if (subCategoryIdLocal === "subCategoryAll") {
      setBarndsSelection([newBrandData]);
      setselectedBrand(newBrandData);
      setSelectedSubCategory(newData);
      // setBarndsSelection([])
    } else {
      const modifiedBrandData =
        sleceTedSubCategoryResult?.CategoryBrands?.map((brandItem) => ({
          ...brandItem,
          checked: false,
        })) || [];
      setSelectedSubCategory(sleceTedSubCategoryResult);
      setBarndsSelection([newBrandData, ...modifiedBrandData]);
      setselectedBrand(newBrandData);
    }
  };

  const handleBrandCheckboxChange = (event) => {
    const { name: brandIdLocal, checked } = event.target;

    const updatedCheckboxes = barndsSelection.map((brandItem) =>
      brandItem?.Brand?.id === brandIdLocal
        ? { ...brandItem, checked }
        : { ...brandItem, checked: false }
    );
    setBarndsSelection(updatedCheckboxes);

    const newBrandData = {
      id: "barndAll",
      Brand: { name: "All", id: "barndAll" },
      checked: true,
    };

    if (brandIdLocal === "barndAll") {
      setselectedBrand(newBrandData);
    } else {
      const sleceTedBrandResult = selectedSubCategory?.CategoryBrands?.find(
        (brandItem) => Number(brandIdLocal) === Number(brandItem?.Brand?.id)
      );
      setselectedBrand(sleceTedBrandResult);
    }
  };
  console.log(pages, "pages")
  // Fetch products
  const handleFetchListing = async () => {
    setLoading(true);
    const response = await getProductList({
      categoryId: state?.id || "",
      brandId: state?.brandId || "",
      subCategoryId: state?.subCatId || "",
      subCategoryTypeId: state?.subCategoryTypeId || "",
      isOnFlashSale: state?.isOnFlashSale || "",
      search: "",
      // ...(state?.promoFilter && { [state?.promoFilter]: true }),
     ...(name ==="new-arrival" && { isNewArrival:name ==="new-arrival" && true}),
     ...(name ==="trending" && { isTrending:name ==="trending" && true}),
     ...(name ==="best-selling" && { isBestSelling:name ==="best-selling" && true}),
      ...(name ==="super-saver" && {isSuperSaver:name ==="super-saver" && true}),
      page: pages > 0 ? pages : "",
    });
    if (response?.data?.success) {
      dispatch(setProductList(response?.data?.data?.list));
      dispatch(setFilteredList(response?.data?.data?.list));
      dispatch(setTotalItem(response?.data?.data?.count));
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchListing();
  }, [name]);
  
   const handleFetchProductBasedOnFilterListing = async (page) => {
    setLoading(true);
    const response = await getProductList({
      
      // ...(state?.promoFilter && { [state?.promoFilter]: true }),
     ...(name ==="new-arrival" && { isNewArrival:name ==="new-arrival" && true}),
     ...(name ==="trending" && { isTrending:name ==="trending" && true}),
     ...(name ==="best-selling" && { isBestSelling:name ==="best-selling" && true}),
      ...(name ==="super-saver" && {isSuperSaver:name ==="super-saver" && true}),
        page: page,
    });
    
    if (response?.data?.success) {
      dispatch(setProductList(response?.data?.data?.list));
      dispatch(setFilteredList(response?.data?.data?.list));
      dispatch(setTotalItem(response?.data?.data?.count));
      setLoading(false);
    }
  };

  // Fetch products
  // const handleFetchProductBasedOnFilterListing = async (number) => {
  //   console.log(number, "number");
  //   const response = await getProductListWithFilter({
  //     categoryIds: subCategoriesSlection
  //       ?.filter((item) => {
  //         if (item?.checked) {
  //           return item?.id;
  //         }
  //       })
  //       .map((entry) => entry?.id),
  //     brandIds: barndsSelection
  //       ?.filter((item) => {
  //         if (item?.checked) {
  //           return item?.id;
  //         }
  //       })
  //       .map((entry) => entry?.id),
  //     maxPrice: maxPriceValue,
  //     minPrice: minPriceValue,
  //     sort: optionSelection || "",
  //   ...(name ==="new-arrival" && { isNewArrival:name ==="new-arrival" && true}),
  //    ...(name ==="trending" && { isTrending:name ==="trending" && true}),
  //    ...(name ==="best-selling" && { isBestSelling:name ==="best-selling" && true}),
  //     ...(name ==="super-saver" && {isSuperSaver:name ==="super-saver" && true}),
  //   });
  //   if (response?.data?.success) {
  //     dispatch(setProductList(response?.data?.data?.list));
  //     dispatch(setFilteredList(response?.data?.data?.list));
  //     dispatch(setTotalItem(response?.data?.data?.list?.length));
  //     toggleDrawer();
  //   }
  // };
  // const handleFetchProductBasedOnFilterListing = async (page) => {
  //   console.log("mmmmmmm")
  //   const response = await getProductList({
  //     // categoryId: categoryName || "",
  //     // ...(brandName && {
  //     //   brandId: brandName,
  //     // }),
  //     // ...(subCategoryName && {
  //     //   subCategoryName: subCategoryName,
  //     // }),
  //       ...(name ==="new-arrival" && { isNewArrival:name ==="new-arrival" && true}),
  //    ...(name ==="trending" && { isTrending:name ==="trending" && true}),
  //    ...(name ==="best-selling" && { isBestSelling:name ==="best-selling" && true}),
  //     ...(name ==="super-saver" && {isSuperSaver:name ==="super-saver" && true}),
  //     page: page,
  //     maxPrice: maxPriceValue,
  //     minPrice: minPriceValue,
  //   });

  //   if (response?.data?.success) {
  //     dispatch(setProductList([]));
  //     dispatch(setFilteredList([]));
  //     dispatch(setProductList(response?.data?.data?.list));
  //     dispatch(setFilteredList(response?.data?.data?.list));
  //     dispatch(setTotalItem(response?.data?.data?.count));
  //     toggleDrawer();
  //   }
  // };

  const handleClearFilter = () => {
    handleFetchListing();
    setSubCategoriesSlection(
      categories?.map((item) => ({
        checked: false,
        id: item?.id,
        name: item?.name,
      }))
    );
    //  fetchBarndList();
    toggleDrawer();
    maxPriceValue = 10000;
    minPriceValue = 0;
  };
  useEffect(() => {
    const fetchBanner = async () => {
      let BannerType;
      if (name == "new-arrival") {
        BannerType = "isNewArrival";
      } else if (name == "trending") {
        BannerType = "isTrending";
      } else if (name == "best-selling") {
        BannerType = "isBestSelling";
      } else {
        BannerType = "isSuperSaver";
      }
      try {
        const response = await getBannersList({
          filter: "Active",
          type: BannerType,
        });
        if (response?.data?.success) {
          setBanner(response?.data?.data?.list);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchBanner();
  }, [name]);

 function capitilize(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>{capitilize(name?.replaceAll(/-/g, " "))}</title>
            </Helmet>
      <ScrollToTop />
      <div className="catalogue__page__block">
        <div className="brands__info__block promo__page__info__block">
          <span
            className="brands__info__block--name"
            style={{ marginRight: "0.2rem", textTransform: "capitalize"}}
          >
            {name?.replaceAll(/-/g, " ")}{" "}
          </span>
          <span className="brands__info__block--itemsCount">
            {" "}
            - {totalItem} Items
          </span>
        </div>
        <div className="promo__banner__block">
          <img src={`${imageCDN}/${banner[0]?.file}`} alt="" />
        </div>
        <div className="catalogue__page__category">
          {isSmallScreen ? (
            <div>
              <button className="filter__toggle__btn" onClick={toggleDrawer}>
                Filter
              </button>
              <Drawer
                open={isSidebarOpen}
                onClose={toggleDrawer}
                direction="left"
                lockBackgroundScroll={true}
                className="productFilter__drawer__block__navbar"
              >
                <CiCircleRemove
                  onClick={toggleDrawer}
                  className="bannerType-menu-icon"
                />
                <div className="catalogue__page__sidebar">
                  <div className="catalogue__page__filter__category">
                    <h3>Sub Categories</h3>
                    <div className="catalogue__page__filter__category__item">
                      <Input
                        type="checkbox"
                        name="All"
                        checked={true}
                        onChange={handleCategoryCheckboxChange}
                      />{" "}
                      <Label check>All</Label>
                    </div>
                    {subCategoriesSlection?.map((subCategoriesSlectionItem) => (
                      <div
                        key={subCategoriesSlectionItem?.id}
                        className="catalogue__page__filter__category__item"
                      >
                        <Input
                          type="checkbox"
                          name={subCategoriesSlectionItem?.id}
                          checked={subCategoriesSlectionItem?.checked}
                          onChange={handleCategoryCheckboxChange}
                        />{" "}
                        <Label check>{subCategoriesSlectionItem?.name}</Label>
                      </div>
                    ))}
                  </div>
                  {/* {barndsSelection?.length > 0 && <div className="catalogue__page__filter__category catalogue__page__filter__category--v2">
                                        <h3>Brands</h3>
                                        {barndsSelection.map(barndsSlectionItem => 
                                            <div key={barndsSlectionItem?.id} className="catalogue__page__filter__category__item">
                                                <Input
                                                    type="checkbox"
                                                    name={barndsSlectionItem?.Brand?.id}
                                                    checked={barndsSlectionItem.checked}
                                                    onChange={handleBrandCheckboxChange}
                                                />
                                                {' '}
                                                <Label check>
                                                    {barndsSlectionItem?.Brand?.name}
                                                </Label>
                                            </div> 
                                        )}
                                    </div>} */}
                  <div className="catalogue__page__filter__category catalogue__page__filter__category--v2">
                    <h3>Price</h3>
                    <MultiRangeSlider
                      min={0}
                      max={10000}
                      onChange={({ min, max }) => {
                        handleMinMax({ min, max });
                      }}
                    />
                  </div>
                  <div className="filter__btn__block">
                    <button
                      onClick={handleFetchProductBasedOnFilterListing}
                      className="filter__btn"
                    >
                      Apply Filter
                    </button>
                    <button
                      onClick={handleClearFilter}
                      className="clear_all__btn"
                    >
                      Clear all
                    </button>
                  </div>
                </div>
              </Drawer>
            </div>
          ) : (
            <h3>Filters</h3>
          )}
        </div>
        <div className="catalogue__page__main__block">
          <div
            className={`${
              !isSmallScreen
                ? "catalogue__page__sidebar"
                : "catalogue__page__sidebar display__none"
            } `}
          >
            <div className="catalogue__page__filter__category">
              <h3>Sub Categories</h3>
              {subCategoriesSlection?.map((subCategoriesSlectionItem) => (
                <div
                  key={subCategoriesSlectionItem?.id}
                  className="catalogue__page__filter__category__item"
                >
                  <Input
                    type="checkbox"
                    name={subCategoriesSlectionItem.id}
                    checked={subCategoriesSlectionItem.checked}
                    onChange={handleCategoryCheckboxChange}
                  />{" "}
                  <Label check>{subCategoriesSlectionItem?.name}</Label>
                </div>
              ))}
            </div>
            {/* <div className="catalogue__page__filter__category catalogue__page__filter__category--v2">
                    <h3>Brands</h3>
                    {barndsSelection.map(barndsSlectionItem => 
                        <div key={barndsSlectionItem?.id} className="catalogue__page__filter__category__item">
                            <Input
                                type="checkbox"
                                name={barndsSlectionItem.id}
                                checked={barndsSlectionItem.checked}
                                onChange={handleBrandCheckboxChange}
                            />
                            {' '}
                            <Label check>
                                {barndsSlectionItem?.name}
                            </Label>
                        </div>
                    )}
                </div> */}
            {barndsSelection?.length > 0 && (
              <div className="catalogue__page__filter__category catalogue__page__filter__category--v2">
                <h3>Brands</h3>
                {barndsSelection.map((barndsSlectionItem) => (
                  <div
                    key={barndsSlectionItem?.id}
                    className="catalogue__page__filter__category__item"
                  >
                    <Input
                      type="checkbox"
                      name={barndsSlectionItem?.Brand?.id}
                      checked={barndsSlectionItem.checked}
                      onChange={handleBrandCheckboxChange}
                    />{" "}
                    <Label check>{barndsSlectionItem?.Brand?.name}</Label>
                  </div>
                ))}
              </div>
            )}
            <div className="catalogue__page__filter__category catalogue__page__filter__category--v2">
              <h3>Price</h3>
              <MultiRangeSlider
                min={0}
                max={10000}
                onChange={({ min, max }) => {
                  handleMinMax({ min, max });
                }}
              />
            </div>
            <div className="filter__btn__block">
              <button
                onClick={handleFetchProductBasedOnFilterListing}
                className="filter__btn"
              >
                Apply Filter
              </button>
              <button onClick={handleClearFilter} className="clear_all__btn">
                Clear all
              </button>
            </div>
          </div>
          <div className="catalogue__page__products__block">
            <div className="catalogue__page__products__block__wrapper">
              {loading ? (
                <div className="catalogueProductsContainerMain">
                  {[1, 2, 3, 4, 5, 6, 7, 8]?.map((placeHolderItem) => (
                    <div
                      key={placeHolderItem}
                      className="catalouge__product__item"
                    >
                      <Skeleton height={150} />
                      <Skeleton height={25} />
                      <Skeleton count={3} />
                    </div>
                  ))}
                </div>
              ) : (
                <CataloguePagination setLoading={setLoading} handleFetchProductBasedOnFilterListing={handleFetchProductBasedOnFilterListing} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default withLayout(PromoPage, { isProductPage: true });
