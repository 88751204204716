import axios from "axios";

import { getToken } from "../utils/AuthService";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

export const getNotificationService = async (limit) => {
  console.log(limit, "limit");
  try {
    const token = getToken();
    const { tokenAccess, foodAccessToken } = token;
    const result = await axios.get(
      `${url}/notif/manage/userSide?filter=User&limit=${
        limit ? limit : "10"
      }&page=1`,
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};
