import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Redux-Settings
 import { 
  selectIsPaymentFailure,
  setIsOrderPlcedSuccess
 } from '../../redux/cartSlice';

// Images
import PayFailBanner from "../../assets/images/cart/PayFailBanner.svg"
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;


function PaymentFailedModal({ErrDesc}) {
  const isPaymentFailModal=useSelector(selectIsPaymentFailure)
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const PaymentFailToggle = () => {
      dispatch(setIsOrderPlcedSuccess(!isPaymentFailModal))
    }

    const closeBtn = (
        <button className="close" onClick={PaymentFailToggle} type="button">
          &times;
        </button>
    );

  return (
    <div>
      <Modal 
      className='orderPlace__success__Modal addrees__modal' 
      isOpen={true} 
      toggle={PaymentFailToggle} 
      fullscreen
      backdropClassName='light__modal__background'
      >
        {/* <ModalHeader toggle={PaymentFailToggle} close={closeBtn} /> */}
        <ModalBody>
            <img src={PayFailBanner} alt="" />
            <div className='orderPlace__success__Modal__content'>
              <h3 style={{color:"#D71513",  marginTop:"2rem",marginBottom:"1rem"}}>Payment failed!</h3>
              <p style={{marginBottom:"0rem"}}>{ErrDesc ? ErrDesc :"Try again in sometimes" }</p>
              <button style={{marginTop:"1rem"}}  onClick={() => navigate('/')}>
                Home
              </button>
            </div>
        </ModalBody>
       
      </Modal>
    </div>
  );
}



export default PaymentFailedModal;