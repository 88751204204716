import axios from "axios";

import { getToken } from "../utils/AuthService";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

/**
 * @function addCartService
 * @param
 * @description This function is used to add cart item
 */

export const addCartService = async ({
  productId,
  productVariationId,
  quantity,
}) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(
      `${url}/cart/add`,
      {
        productId,
        productVariationId,
        quantity: quantity || 1,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function cartListService
 * @param
 * @description This function is used to get all cart item
 */

export const cartListService = async ({ filter = ""}) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.get(`${url}/cart/list?filter=${filter}&getAll=true`, {
      headers: {
        Authorization: `Bearer ${tokenAccess}`,
        foodauthtoken: `${foodAccessToken}`,
      },
    });
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function cartItemRemoveService
 * @param
 * @description This function is used to remove cart item
 */

export const cartItemRemoveService = async ({ id }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.delete(`${url}/cart/remove?id=${id}`, {
      headers: {
        Authorization: `Bearer ${tokenAccess}`,
        foodauthtoken: `${foodAccessToken}`,
      },
    });
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function applyVoucherService
 * @param
 * @description This function is used to apply voucher code on cart item
 */

export const applyVoucherService = async ({
  shippingCost,
  cartItemsId,
  code,
  amount,
}) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(
      `${url}/cart/applyVoucher`,
      {
        shippingCost,
        cartItemsId,
        code,
        amount,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function applyCouponService
 * @param
 * @description This function is used to apply coupon code on cart item
 */

export const applyCouponService = async ({
  shippingCost,
  cartItemsId,
  code,
  amount,
  zipCode,
}) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(
      `${url}/cart/applyCoupon`,
      {
        shippingCost,
        cartItemsId,
        code,
        amount,
        zipCode,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function paymentMethodListService
 * @param
 * @description This function is used to get all payment methods item
 */

export const paymentMethodListService = async ({ filter = "", type = "" }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.get(
      `${url}/paymentMethods/manage/list?filter=${filter}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function getShippingCostService
 * @param
 * @description This function is used to get shipping cost
 */

export const getShippingCostService = async ({ id = "", weight }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.get(
      `${url}/shippingCosts/manage/getByAddress?id=${id}&weight=${weight}`,
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function placeOrderService
 * @param
 * @description This function is used to place an order
 */

export const placeOrderService = async (data) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(`${url}/order/place`, data, {
      headers: {
        Authorization: `Bearer ${tokenAccess}`,
        foodauthtoken: `${foodAccessToken}`,
      },
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const updateProductQuantity = async (
  id,
  productId,
  productVariationId,
  value
) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.patch(
      `${url}/cart/update?id=${id}`,
      {
        productId: productId,
        productVariationId: productVariationId,
        quantity: value,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const PaymentService = async (data) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(`${url}/order/placeWithPayment`, data, {
      headers: {
        Authorization: `Bearer ${tokenAccess}`,
        foodauthtoken: `${foodAccessToken}`,
      },
    });
    return result;
  } catch (err) {
    return err;
  }
};

export default cartListService;
