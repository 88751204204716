import axios from "axios";

import { getToken } from "../utils/AuthService";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

/**
 * @function OrderListingService
 * @param
 * @description This function is used to list ordered items
 */

export const OrderListingService = async ({ filter = "", limit = 30 }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.get(
      `${url}/order/my?filter=${filter}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

//single order fetch function
export const OrderDetailService = async ({ id }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.get(`${url}/order/detail?id=${id}`, {
      headers: {
        Authorization: `Bearer ${tokenAccess}`,
        foodauthtoken: `${foodAccessToken}`,
      },
    });
    return result;
  } catch (err) {
    return err;
  }
};
