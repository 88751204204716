import React, { useState, useMemo, useReducer } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import countryList from "react-select-country-list";

// API-Service
import {
  addAddressService,
  addressListService,
} from "../../../service/address.service";

// Redux-Settings
import {
  setShippingAddressList,
  setBillingAddressList,
  setDefaultBillingAddressId,
  setDefaultShippingAddressId,
} from "../../../redux/addressSlice";

const initialState = {
  receiptientError: false,
  mobileError: false,
  countryError: false,
  addressError: false,
  stateError: false,
  cityError: false,
  zipCodeError: false,
};

// Define action types
const TOGGLE_ERROR = "TOGGLE_ERROR";

const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_ERROR:
      return { ...state, [action?.field]: action?.value };
    default:
      return state;
  }
};

function AddAddressModal({ toggle, addressType, setAddAddressClicked }) {
  const dispatch = useDispatch();
  const [localState, localDispatch] = useReducer(reducer, initialState);
  const [phoneLengthError, setPhoneLengthError] = useState();
  const [phoneInputError, setPhoneInputError] = useState();
  const [zipcodeError, setZipcodeError] = useState();
  const [isTinNumber, setTinNumber] = useState(false);
  // Define your reducer function
  // Form Data
  const [formData, setFormData] = useState({
    receiptient: "",
    mobile: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    company: "",
    tin: "",
    isDefault: false,
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    localDispatch({
      type: "TOGGLE_ERROR",
      field: `${name}Error`,
      value: false,
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleZipcodeFieldChange = (e) => {
    const { name, value } = e.target;
    localDispatch({
      type: "TOGGLE_ERROR",
      field: `${name}Error`,
      value: false,
    });
    if (value.length !== 5) {
      setZipcodeError(true);
    } else {
      setZipcodeError(false);

      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;
    setPhoneLengthError(false);
    if (inputValue.length >= 9 && inputValue.length <= 11) {
      setFormData({
        ...formData,
        mobile: e.target.value,
      });
      setPhoneInputError("");
    } else {
      setFormData({
        ...formData,
        mobile: e.target.value,
      });
      setPhoneLengthError(true);
      setPhoneInputError("Phone number must be between 9 and 11 digits.");
    }
    if (inputValue.length < 1) {
      setPhoneInputError("Phone number field cannot be left empty");
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  // Handle Country Select
  const [value, setValue] = useState("");

  const fetchShippingAddressList = async () => {
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Shipping",
    });
    if (response?.data?.success) {
      dispatch(setShippingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultShippingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  };

  const fetchBillingAddressList = async () => {
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Billing",
    });
    if (response?.data?.success) {
      dispatch(setBillingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultBillingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  };

  // Validation function
  const validateAndSetError = (field, value) => {
    if (value?.length < 1) {
      localDispatch({ type: TOGGLE_ERROR, field, value: true });
    } else {
      localDispatch({ type: TOGGLE_ERROR, field, value: false });
    }
  };

  const handleCreateItem = async (e) => {
    e.preventDefault();

    if (formData?.company && !formData?.tin) {
      setTinNumber(true);
    } else {
      const {
        receiptient,
        mobile,
        address,
        city,
        state,
        tin,
        zipCode,
        company,
        isDefault,
      } = formData;

      validateAndSetError("receiptientError", receiptient);
      validateAndSetError("mobileError", mobile);
      validateAndSetError("addressError", address);
      validateAndSetError("stateError", state);
      validateAndSetError("cityError", city);
      validateAndSetError("zipCodeError", zipCode);
      if (!value?.label) {
        localDispatch({
          type: TOGGLE_ERROR,
          field: "countryError",
          value: true,
        });
      }

      if (
        localState?.receiptientError ||
        localState?.mobileError ||
        localState?.addressError ||
        localState?.stateError ||
        localState?.cityError ||
        localState?.zipCodeError
      ) {
        return false;
      } else {
        const response = await addAddressService({
          receiptient,
          mobile,
          address,
          city,
          state,
          zipCode,
          tin,
          company,
          isDefault,
          type: addressType,
          country: "Malaysia",
        });
        if (response?.data?.success) {
          if (addressType === "Shipping") {
            setAddAddressClicked(true);

            fetchShippingAddressList();
          }
          if (addressType === "Billing") {
            fetchBillingAddressList();
          }
          toggle();
        }
      }
    }
  };

  // const closeBtn = (
  //     <button className="close" onClick={toggle} type="button">
  //       &times;
  //     </button>
  // );

  return (
    <div>
      <Form onSubmit={handleCreateItem}>
        <FormGroup>
          <Label for="receiptient">Full Name*</Label>
          <Input
            type="text"
            name="receiptient"
            id="receiptient"
            placeholder="Enter your name"
            value={formData.receiptient}
            onChange={handleInputChange}
            required
          />
          {localState?.receiptientError && (
            <span className="address_error">Name field must not be empty!</span>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="mobile">Phone Number*</Label>
          <Input
            type="text"
            name="mobile"
            id="mobile"
            placeholder="Enter your phone number"
            value={formData.mobile}
            onChange={handlePhoneInputChange}
            required
          />
          {phoneLengthError && (
            <span className="address_error">{phoneInputError}</span>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="company">Company</Label>
          <Input
            type="text"
            name="company"
            id="company"
            placeholder="Enter company name here"
            value={formData.company}
            onChange={handleInputChange}
          />
        </FormGroup>

        {formData?.company && (
          <FormGroup>
            <Label for="company">TIN</Label>
            <Input
              type="text"
              name="tin"
              id="tin"
              placeholder="Enter TIN name here"
              value={formData.tin}
              onChange={handleInputChange}
            />
            {isTinNumber && (
              <span className="address_error">Enter Tin Number here</span>
            )}
          </FormGroup>
        )}

        <FormGroup>
          <Label for="countrySelect">Country*</Label>
          {/* <Select
            options={options}
            value={value}
            onChange={changeHandler}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                boxShadow: "none",
                borderColor: state.isFocused ? "#D71513" : "#dee2e6",
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                overflow: "visible",
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                color: state.isSelected ? "#FFF" : "#000",
                backgroundColor: state.isSelected ? "#D71513" : "white",
              }),
            }}
          /> */}
          <Input
            type="text"
            name="address"
            id="address"
            placeholder="Enter your street address"
            value="Malaysia"
          />
        </FormGroup>
        <FormGroup>
          <Label for="address">Street Address*</Label>
          <Input
            type="text"
            name="address"
            id="address"
            placeholder="Enter your street address"
            value={formData.address}
            onChange={handleInputChange}
            required
          />
          {localState?.addressError && (
            <span className="address_error">
              Address field must not be empty!
            </span>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="city">City*</Label>
          <Input
            type="text"
            name="city"
            id="city"
            placeholder="Enter city here"
            value={formData.city}
            onChange={handleInputChange}
            required
          />
          {localState?.cityError && (
            <span className="address_error">City field must not be empty!</span>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="state">State*</Label>
          <Input
            type="text"
            name="state"
            id="state"
            placeholder="Enter state here"
            value={formData.state}
            onChange={handleInputChange}
            required
          />
          {localState?.stateError && (
            <span className="address_error">
              State field must not be empty!
            </span>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="zipCode">Zip/Postal Code*</Label>
          <Input
            type="text"
            name="zipCode"
            id="zipCode"
            placeholder="Enter postal code here"
            // value={formData.zipCode}
            onChange={handleZipcodeFieldChange}
            required
          />
          {(localState?.zipCodeError || zipcodeError) && (
            <span className="address_error">
              Zip Code must be of 5 digits and must not be empty!
            </span>
          )}
        </FormGroup>

        <FormGroup check>
          <Input
            name="isDefault"
            id="isDefault"
            type="checkbox"
            checked={formData.isDefault}
            onChange={handleCheckboxChange}
          />
          <Label for="isDefault" check>
            Make this my default address
          </Label>
        </FormGroup>
        <FormGroup>
          <button
            type="submit"
            className="primary__btn"
            onClick={handleCreateItem}
          >
            Add Address
          </button>
        </FormGroup>
      </Form>
    </div>
  );
}

export default AddAddressModal;
