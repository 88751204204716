import { memo } from "react";
import { useNavigate } from "react-router-dom";
import MobileAppStoreBadge from "./MobileAppStoreBadge";
import StoreGooglePlayStyleWhite from "./StoreGooglePlayStyleWhite";
import "../../assets/scss/base/pages/homepage/Footer.scss";
import {
  mobilePlayBadge,
  mobileAppBadge,
} from "../../assets/images/footer/index";
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

const FooterBlock = memo(() => {
  const navigate = useNavigate();
  return (
    <div className="container2Footer">
      <div className="content9Footer">
        <div className="logo-and-linksFooter">
          <div className="logo-and-supporting-textFooter" Footer>
            <div className="supporting-textFooter">
              Pineapple Store is one of the most trusted e-commerce platform in
              Malaysia that delivers top quality products to our customers with
              super high speed and continuously improving customer shopping
              experience in the same time.
            </div>
          </div>
          <div
            className="footer-linksFooter"
            onClick={() => window.open(`/privacypolicy`, "_blank")}
          >
            <div className="footer-linkFooter">
              <div className="buttons4Footer">
                <img className="icon12Footer" alt="" src="/icon2.svg" />
                <div className="text31Footer">Privacy Policy</div>
                <img className="icon12Footer" alt="" src="/icon2.svg" />
              </div>
            </div>
            <div
              className="footer-linkFooter"
              onClick={() => window.open(`/return-refund-warranty`, "_blank")}
            >
              <div className="buttons4Footer">
                <img className="icon12Footer" alt="" src="/icon7.svg" />
                <div className="text31Footer">Return-Refund-Warranty</div>
                <img className="icon12Footer" alt="" src="/icon7.svg" />
              </div>
            </div>
            <div
              className="footer-linkFooter"
              onClick={() => window.open(`/deliverypolicy`, "_blank")}
            >
              <div className="buttons4Footer">
                <img className="icon12Footer" alt="" src="/icon8.svg" />
                <div className="text31Footer">Delivery Policy</div>
                <img className="icon12Footer" alt="" src="/icon8.svg" />
              </div>
            </div>
            <button
              className="footer-link3Footer"
              onClick={() => window.open(`/terms`, "_blank")}
            >
              <div className="buttons4Footer">
                <img className="icon12Footer" alt="" src="/icon9.svg" />
                <div className="text34Footer">Term of Service</div>
                <img className="icon12Footer" alt="" src="/icon9.svg" />
              </div>
            </button>
            {/* <button className="footer-link3Footer">
              <div className="buttons4Footer">
                <img className="icon12Footer" alt="" src="/icon10.svg" />
                <div className="text34Footer">Privacy</div>
                <img className="icon12Footer" alt="" src="/icon10.svg" />
              </div>
            </button> */}
            <button
              className="footer-link3Footer"
              onClick={() => window.open(`/FAQ`, "_blank")}
            >
              <div className="buttons4Footer">
                <img className="icon12Footer" alt="" src="/icon2.svg" />
                <div className="text34Footer">Help</div>
                <img className="icon12Footer" alt="" src="/icon2.svg" />
              </div>
            </button>
          </div>
        </div>
        <div className="app-store-actionsFooter">
          <div className="headingFooter">Get the app</div>
          <div className="actions1Footer">
            <a
              href="https://apps.apple.com/us/app/pineapple-e-store/id1626465032"
              target="_blank"
            >
              <img
                className="mobileAppBadge"
                alt="badge"
                src={mobileAppBadge}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.pineapple.my&hl=en_IN&gl=US"
              target="_blank"
            >
              <img
                className="mobileAppBadge"
                alt="badge"
                src={mobilePlayBadge}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

export default FooterBlock;
