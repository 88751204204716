import OrderingSteps from "../../components/cart/OrderingSteps";
import OrderedItemsList from "../../components/cart/OrderedItemsList";
import BillVoucherForm from "../../components/cart/BillVoucherForm";
import FooterCard from "../../components/homepage/FooterCard";
import Header from "../../components/homepage/Header";
import CategoriesTab from '../../components/catalogue/CategoriesTab';
import '../../assets/scss/base/pages/cart/CartItems.scss';

const CartItems = () => {
  return (
    <div className="cartCartItems">
     <Header />
     <div className="divider" />
      <CategoriesTab />
      <div className="divider" />
      <OrderingSteps />
      <section className="cart-innerCartItems">
        <div className="frame-parentCartItems">
          <OrderedItemsList />
          <BillVoucherForm />
        </div>
      </section>
      <FooterCard />
    </div>
  );
};

export default CartItems;
