import axios from "axios";

import { getToken } from "../utils/AuthService";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

/**
 * @function review&RatingService
 * @param
 * @description This function is used to rate and review products
 */

export const giveRatingReviewService = async ({ rating, review, id }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.patch(
      `${url}/order/rating?id=${id}`,
      {
        rating,
        review,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};
