import React, { Suspense } from "react";
import { Helmet } from "react-helmet";

import { useSelector, useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
// Redux-Settings
import { selectCategoriesList } from "../../redux/categoriesSlice";

// import Header from "../../components/homepage/Header";
// import CategoriesList from "../../components/homepage/MainCategoriesDropdown/MainCategoriesDropdown";
import FooterCard from "../../components/homepage/FooterCard";
import whatsApppIcon from "../../assets/images/whatsApppIcon.svg";
// Style
import "../../assets/scss/base/pages/homepage/Home.scss";
import SplashBannerPopup from "../splashBannerPopup/SplashBannerPopup";
import { isLoggedIn } from "../../redux/authSlice";
import Loader from "../../components/micro/loader/Loader";
const Header = React.lazy(() => import("../../components/homepage/Header"));
const Navigation = React.lazy(() =>
  import("../../components/homepage/Navigation/Navigation")
);
const Banner = React.lazy(() =>
  import("../../components/homepage/Banner/Banner")
);
const Categories = React.lazy(() =>
  import("../../components/homepage/Categories/Categories")
);
const FlashSaleSection = React.lazy(() =>
  import("../../components/homepage/flashSale/FlashSaleSection")
);
const BrandsSection = React.lazy(() =>
  import("../../components/homepage/BrandsSection")
);
const HompageProductV1Section = React.lazy(() =>
  import("../../components/homepage/HompageProductV1/HompageProductV1")
);

const url = process.env.PORT_NUMBER;
const Home = () => {
  const categoriesArray = useSelector(selectCategoriesList);
  const isLoggdin = useSelector(isLoggedIn);
  const fallback = () => {
    return (
      <div>
        <Loader />
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          PC & Accessories, Office Solutions, Printing Solutions | Pineapple
          Computers Sdn Bhd
        </title>
        <meta
          name="description"
          content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
        />
      </Helmet>
      <div className="home">
        <Suspense fallback={fallback}>
          <Header />
          <Navigation />
          <Banner />
          <FlashSaleSection />
          {/* <Categories /> */}
          <BrandsSection />
          {categoriesArray?.length > 0 && (
            <>
              {categoriesArray
                .filter((item) => item.isOnDashboard === true)
                .map((categorieItem) => (
                  <HompageProductV1Section
                    key={categorieItem?.id}
                    categorieItem={categorieItem}
                  />
                ))}
            </>
          )}
          <div
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
              cursor: "pointer",
              zIndex: 2000,
            }}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=60193278400",
                "_blank"
              )
            }
          >
            <img
              style={{ width: "70px" }}
              src={whatsApppIcon}
              alt="whats-app-icon"
            />
          </div>
          <FooterCard />
        </Suspense>
        <Toaster position="top-right" />

        <SplashBannerPopup />
      </div>
    </>
  );
};

export default Home;
