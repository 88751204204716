import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { BsArrowLeftCircle } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";

import { GoPerson } from "react-icons/go";
import {
  getUserProfile,
  updateUserProfile,
  uploadProfileImage,
} from "../../service/user.service";
//images

import ProfileAvatar from "../../assets/images/account/Profile/ProfileAvatar.svg";
import "../../assets/scss/base/pages/account/Account.scss";
import "../../assets/scss/base/pages/global.scss";
import "../../assets/scss/base/pages/account/EditProfileForm.scss";
import { useNavigate } from "react-router-dom";

import AccountMenu from "./AccountMenuNavigation/AccountMenu";
import Loader from "../../components/micro/loader/Loader";
import Alertircle from "../../assets/images/homepage/alert-circle.svg";

import CheckCircleBrokenIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import deleteIcon from "../../assets/images/Delete.svg";
import { setUserDetail } from "../../redux/userSlice";
import DeleteAccModal from "./DeleteAccountModals/DeleteAccModal";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);

  const [user, setUser] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editUserClicked, setEditUserClicked] = useState(false);
  const [editedSuccessfully, setEditedSuccessfully] = useState();
  const [changeMobileNoError, setChangeMobileNoError] = useState("");
  const [notValidated, setNotValidated] = useState(false);
  const [openDeleteAccModal, setOpenDeleteAccModal] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [formData, setFormData] = useState({
    username: null,
    mobileNo: null,
    email: null,
    gender: null,
    dob: null,
    file: null,
  });
  const [mobile, setMobile] = useState();
  const [fileImage, setFileImage] = useState({});
  const [showOptions, setShowOptions] = useState(false);

  const isSmallScreen = windowWidth <= 960;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFileChange = (event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFormData({
        ...formData,
        file: selectedFile,
      });
      setShowOptions(false);
    }
  };

  const handleUploadClick = async (e) => {
    setShowOptions(true);
    e.preventDefault();
  };

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      const response = await getUserProfile();

      if (response?.data?.success) {
        setUser(response?.data?.data);
        dispatch(setUserDetail(response?.data?.data));
      } else {
        console.log(response?.data?.errorMessage);
      }
      setIsLoading(false);
    };

    fetchProfile();
  }, [editedSuccessfully]);
  const handleEditClick = (e) => {
    e.preventDefault();

    setEditUserClicked(true);
    setTimeout(() => {
      setIsEditing(true);
    }, 100);
  };

  /**
   * @function handleSaveDetailsClick
   * @param
   * @description This function is used to save the updates of profile
   */
  const handleChangeProfileData = async (e) => {
    setSaveLoading(true);

    e.preventDefault();

    if (
      formData.file !== null ||
      formData.username !== null ||
      formData.mobileNo !== null ||
      formData.email !== null ||
      formData.gender !== null ||
      formData.dob !== null
    ) {
      const response = await updateUserProfile({
        username: formData?.username ? formData?.username : user?.username,
        ...(formData.mobileNo && {
          mobileNo: formData.mobileNo
            ? formData.mobileNo
            : user?.mobileNo.replace(0, " ").trim(),
        }),

        email: formData?.email ? formData?.email : user?.email,
        gender: formData.gender
          ? formData?.gender
          : user?.gender
          ? user?.gender
          : "",
        dob: formData?.dob ? formData?.dob : user?.dob ? user?.dob : "",
      });

      handleSaveDetailsClick();
      if (response?.data?.success) {
        setSaveLoading(false);

        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={CheckCircleBrokenIcon} alt="" />
              </div>
              <h4 style={{ marginTop: "0.5rem" }}>
                Profile updated successfully
              </h4>
              {/* <p>This product is successfully added to cart list</p> */}
              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#12B76A",
              color: "#C5F6DF",
            },
          }
        );
        setIsEditing(false);
        if (formData?.mobileNo) {
          navigate(`/changeMobileNo/otpverification`, {
            state: {
              phoneNumber: formData.mobileNo,
            },
          });
        } else {
          navigate("/account/profile");
        }
      } else {
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={Alertircle} alt="" />
              </div>
              <h4 style={{ marginTop: "0.5rem" }}>
                {response?.data?.errorMessage}
              </h4>
              {/* <p>This product is successfully added to cart list</p> */}
              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#D92D20",
              color: "#FFD7D3",
            },
          }
        );
      }
      if (
        response?.data?.errorMessage?.includes(
          "Your Mobile Number Verification otp is already sent"
        )
      ) {
        setChangeMobileNoError(response?.data?.errorMessage);
        // toast(
        //   (t) => (
        //     <div className="toast__block">
        //       <div className="toast__block__icon">
        //         <img src={Alertircle} alt="" />
        //       </div>
        //       <div className="toast__block__content">
        //         <h4 style={{ marginTop: "0.5rem" }}>
        //           {response?.data?.errorMessage}
        //         </h4>
        //         {/* <p>This product is not added to cart list</p> */}
        //       </div>

        //       <button onClick={() => toast.dismiss(t.id)}>
        //         <img src={XClose} alt="" />
        //       </button>
        //     </div>
        //   ),
        //   {
        //     style: {
        //       borderRadius: "10px",
        //       background: "#D92D20",
        //       color: "#FFD7D3",
        //     },
        //   }
        // );
      }
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4 style={{ marginTop: "0.5rem" }}>
                You have not edited any data
              </h4>
              {/* <p>You have not edited any data</p> */}
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
    setSaveLoading(false);
  };
  const handleSaveDetailsClick = async (e) => {
    const response = await uploadProfileImage({
      file: formData.file ? formData?.file : user?.file,
    });
    setEditedSuccessfully(response);
  };
  const renderProfile = (
    <div className="profile-details-parentAccount">
      <h3 className="accountAccount">Profile Details</h3>
      {isLoading ? (
        // eslint-disable-next-line react/style-prop-object
        <div style={{ margin: "auto" }}>
          <Loader />
        </div>
      ) : (
        <div className="dropdown-parentAccount">
          {(
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              className="dropdown-iconAccount"
              alt="profile-image"
              src={user?.file ? `${imageCDN}/${user.file}` : ProfileAvatar}
            />
          ) || <GoPerson className="dropdown-iconAccount" />}
          <div className="frame-divAccount">
            <div className="full-name-parentAccount">
              <div className="full-nameAccount">Full Name</div>
              <div className="full-nameAccount">Mobile Number</div>
              <div className="full-nameAccount">Email ID</div>
              <div className="full-nameAccount">Date of Birth</div>
              <div className="full-nameAccount">Gender</div>
            </div>
            <div className="full-name-parentAccount">
              <div className="full-nameAccount">{user?.username}</div>
              <div className="full-nameAccount">+6{user?.mobileNo}</div>
              <div className="full-nameAccountEmail">{user?.email}</div>
              <div className="full-nameAccount">{user?.dob}</div>
              <div className="full-nameAccount">{user?.gender}</div>
            </div>
          </div>

          <button onClick={handleEditClick} className="buttonAccount">
            <div className="state-layer1Account">
              <div className="label-text1Account">Edit</div>
            </div>
          </button>
        </div>
      )}
    </div>
  );

  const handleNameInput = (e) => {
    setFormData({
      ...formData,
      username: e.target.value,
    });
  };
  // const handlePhoneInput = (e) => {
  //   console.log(e.target.value);
  //
  //   console.log(mobileNo);
  // };
  const handlePhoneNo = (e) => {
    setFormData({
      ...formData,
      mobileNo: e.target.value,
    });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleEmailInput = (e) => {
    if (!isValidEmail(e.target.value)) {
      setNotValidated(true);
    } else {
      setNotValidated(false);
    }
    setFormData({
      ...formData,
      email: e.target.value,
    });
  };
  const handleDobInput = (e) => {
    setFormData({
      ...formData,
      dob: e.target.value,
    });
  };

  const handleMaleClick = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      gender: "Male",
    });
  };

  const handleFemaleClick = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      gender: "Female",
    });
  };
  /**
   * @function handleUploadProfilePictureClick
   * @params  {file}
   * @usage   used for uploading profile image
   */

  const getImageUrl = (userImage) => {
    try {
      var image = URL.createObjectURL(userImage);
      return image;
    } catch (e) {
      console.log(e);
    }
  };
  const handleInputFocus = (e) => {
    e.preventDefault();
    inputRef.current.focus();
  };
  // const number = user?.mobileNo?.split("").splice(1, 10).join("");

  let today = new Date().toISOString().slice(0, 10);
  const renderEditProfile = (
    <div className="frameparent5EditProfile">
      <BsArrowLeftCircle
        style={{
          color: "#D71513",
          width: "4%",
          height: "4%",
          marginBottom: "1rem",
          cursor: "pointer",
        }}
        onClick={() => setIsEditing(false)}
      />
      <form className="dropdown-groupEditProfile">
        <div className="dropdown1EditProfile">
          <div className="avatar-parentEditProfile">
            <div className="avatar-imageDiv">
              {formData?.file ? (
                <img
                  src={getImageUrl(formData?.file)}
                  alt="Profile-Avatar"
                  className="avatar-image"
                />
              ) : (
                <img
                  src={`${imageCDN}/${user?.file}`}
                  alt="user-Icon"
                  className="avatar-image"
                />
              )}
              {/* <GoPerson className="dropdown-iconEditProfile" /> */}
            </div>
            <button onClick={handleUploadClick} className="label3EditProfile">
              Change Profile picture
            </button>
            {showOptions && (
              <div className="options-modal">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <button onClick={() => setShowOptions(false)}>Cancel</button>
              </div>
            )}

            {/* {fileImage && <img className='selectedProfileImage' src={URL.createObjectURL(fileImage)} alt="Profile" />} */}
          </div>
        </div>
        <div className="formEditProfile">
          <div className="input-fieldEditProfile">
            <div className="input-fieldEditProfile">
              <div className="label4EditProfile">Full Name</div>
              <input
                className="input3EditProfile"
                type="text"
                value={formData.name}
                defaultValue={user?.username}
                onChange={(e) => handleNameInput(e)}
                placeholder="Enter your name"
              />
            </div>
            {/* <div className="hint-text3EditProfile">
              Must be at least 8 characters.
            </div> */}
          </div>
          <div className="input-fieldEditProfile">
            <div className="input-fieldEditProfile">
              <div className="label4">Mobile Number</div>
              <div className="phoneInput__And__Button">
                <div className="phoneDivEditProfile">
                  {/* <input value="+60" className="codeEditProfile" /> */}
                  <p className="DefaultCodeText">+6</p>
                  <input
                    ref={inputRef}
                    className="input3EditProfile  phoneEditProfile"
                    type="number"
                    defaultValue={user?.mobileNo}
                    value={
                      formData?.mobileNo ? formData?.mobileNo : user?.mobileNo
                    }
                    onChange={(e) => handlePhoneNo(e)}
                    placeholder="0987654321"
                  />
                </div>
                {/* <button
                  className="Phone__Input__Btn"
                  onClick={(e) => handleInputFocus(e)}
                >
                  Change mobile Number
                </button> */}
              </div>
            </div>
            <div className="hint-text3EditProfile" style={{ color: "#D71513" }}>
              {changeMobileNoError}
            </div>
          </div>

          <div className="input-fieldEditProfile">
            <div className="input-fieldEditProfile">
              <div className="label4EditProfile">Email Address</div>
              <input
                className="input3EditProfile"
                type="text"
                defaultValue={user?.email}
                onChange={(e) => handleEmailInput(e)}
              />
            </div>
            {notValidated && (
              <span className="hint-text4-email">
                Enter valid email address
              </span>
            )}
            {/* <div className="hint-text3EditProfile">
              Must be at least 8 characters.
            </div> */}
          </div>
          <div className="input-fieldEditProfile">
            <div className="input-fieldEditProfile">
              <div className="label4EditProfile">Date of Birth</div>
              <input
                className="input3EditProfile"
                type="date"
                max={today}
                defaultValue={user?.dob}
                onChange={(e) => handleDobInput(e)}
                placeholder="Enter date of birth"
              />
            </div>
            {/* <div className="hint-text3EditProfile">
              Must be at least 8 characters.
            </div> */}
          </div>
        </div>
        <div className="input-parentEditProfile">
          <button
            onClick={(e) => handleMaleClick(e)}
            className={
              formData.gender
                ? formData.gender == "Male"
                  ? "input7EditProfile"
                  : "input8EditProfile"
                : user.gender == "Male"
                ? "input7EditProfile"
                : "input8EditProfile"
            }
          >
            {/* <img className="check-icon" alt="" src="/check.svg" /> */}
            <div
              className={
                formData.gender
                  ? formData.gender == "Male"
                    ? "text29EditProfile"
                    : "text30EditProfile"
                  : user.gender == "Male"
                  ? "text29EditProfile"
                  : "text30EditProfile"
              }
            >
              Male
            </div>
          </button>
          <button
            onClick={(e) => handleFemaleClick(e)}
            className={
              formData.gender
                ? formData.gender == "Female"
                  ? "input7EditProfile"
                  : "input8EditProfile"
                : user.gender == "Female"
                ? "input7EditProfile"
                : "input8EditProfile"
            }
          >
            <div
              className={
                formData.gender
                  ? formData.gender == "Female"
                    ? "text29EditProfile"
                    : "text30EditProfile"
                  : user.gender == "Female"
                  ? "text29EditProfile"
                  : "text30EditProfile"
              }
            >
              Female
            </div>
          </button>
        </div>
        <div
          className="Delete__Btn__Container"
          onClick={() => setOpenDeleteAccModal(true)}
        >
          <img src={deleteIcon} alt="deleteIcon" />
          <p className="Delete__Btn__Text">Delete my Profile</p>
        </div>
        <button
          onClick={handleChangeProfileData}
          className="button1EditProfile"
        >
          <div className="state-layer2EditProfile">
            <div className="label-text2EditProfile">
              {isSaveLoading ? "Loading..." : "Save Details"}
            </div>
          </div>
        </button>
      </form>
    </div>
  );

  return (
    <>
      <AccountMenu>{isEditing ? renderEditProfile : renderProfile}</AccountMenu>
      {openDeleteAccModal && (
        <DeleteAccModal
          setOpenDeleteAccModal={setOpenDeleteAccModal}
          openDeleteAccModal={openDeleteAccModal}
          username={user?.username}
          mobileNo={user?.mobileNo}
          Email={user?.email}
          ProfileImage={user?.file}
        />
      )}
    </>
  );
};

export default Profile;
