import React from "react";
import { Helmet } from "react-helmet";

const TermsServiceMobile = () => {
  return (
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Terms & Service</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <div style={{ fontFamily: "Poppins" }}>
        <h3 style={{ color: "black", paddingBottom: "10px", margin: "2rem 0" }}>
          Terms of Service for Pineapple.my
        </h3>
        <p>
          If you require any more information or have any questions about our
          Terms of Service, please feel free to contact us by email at&nbsp;
          <a
            href="mailto:pineapplecomputer88@gmail.com"
            style={{ textDecoration: "none", color: "black" }}
          >
            https://www.pineapple.my/contact-us/
          </a>
          .
        </p>
        <h3>&nbsp;</h3>
        <h3 style={{ marginBottom: "10px" }}>Introduction</h3>
        <p>
          These terms and conditions govern your use of this website; by using
          this website, you accept these terms and conditions in full and
          without reservation. If you disagree with these terms and conditions
          or any part of these terms and conditions, you must not use this
          website.
        </p>
        <p>
          You must be at least 18 [eighteen] years of age to use this website.
          By using this website and by agreeing to these terms and conditions,
          you warrant and represent that you are at least 18 years of age.
        </p>
        <p>&nbsp;</p>
        <h3 style={{ marginBottom: "10px" }}>License to use website</h3>
        <p>
          Unless otherwise stated, www.pineapple.my and/or its licensors own the
          intellectual property rights published on this website and materials
          used on www.pineapple.my. Subject to the license below, all these
          intellectual property rights are reserved.
        </p>
        <p>
          You may view, download for caching purposes only, and print pages,
          files or other content from the website for your own personal use,
          subject to the restrictions set out below and elsewhere in these terms
          and conditions.
        </p>
        <p>&nbsp;</p>
        <p>You must not:</p>
        <ul>
          <li>
            republish material from this website in neither print nor digital
            media or documents (including republication on another website);
          </li>
          <li>sell, rent or sub-license material from the website;</li>
          <li>show any material from the website in public;</li>
          <li>
            reproduce, duplicate, copy or otherwise exploit material on this
            website for a commercial purpose;
          </li>
          <li>edit or otherwise modify any material on the website;</li>
          <li>
            redistribute material from this website – except for content
            specifically and expressly made available for redistribution; or
          </li>
          <li>
            republish or reproduce any part of this website through the use of
            iframes or screenscrapers.
          </li>
        </ul>
        <p>
          Where content is specifically made available for redistribution, it
          may only be redistributed within your organisation.
        </p>
        <p>&nbsp;</p>
        <h3 style={{ marginBottom: "10px" }}>Acceptable use</h3>
        <p>
          You must not use this website in any way that causes, or may cause,
          damage to the website or impairment of the availability or
          accessibility of www.pineapple.my or in any way which is unlawful,
          illegal, fraudulent or harmful, or in connection with any unlawful,
          illegal, fraudulent or harmful purpose or activity.
        </p>
        <p>
          You must not use this website to copy, store, host, transmit, send,
          use, publish or distribute any material which consists of (or is
          linked to) any spyware, computer virus, Trojan horse, worm, keystroke
          logger, rootkit or other malicious computer software.
        </p>
        <p>
          You must not conduct any systematic or automated data collection
          activities on or in relation to this website without
          www.pineapple.my’s express written consent.
          <br />
          This includes:
        </p>
        <ul>
          <li>scraping</li>
          <li>data mining</li>
          <li>data extraction</li>
          <li>data harvesting</li>
          <li>‘framing’ (iframes)</li>
          <li>Article ‘Spinning’</li>
        </ul>
        <p>
          You must not use this website or any part of it to transmit or send
          unsolicited commercial communications.
        </p>
        <p>
          You must not use this website for any purposes related to marketing
          without the express written consent of www.pineapple.my.
        </p>
        <p>&nbsp;</p>
        <h3 style={{ marginBottom: "10px" }}>Restricted access</h3>
        <p>
          Access to certain areas of this website is restricted.
          www.pineapple.my reserves the right to restrict access to certain
          areas of this website, or at our discretion, this entire website.
          www.pineapple.my may change or modify this policy without notice.
        </p>
        <p>
          If www.pineapple.my provides you with a user ID and password to enable
          you to access restricted areas of this website or other content or
          services, you must ensure that the user ID and password are kept
          confidential. You alone are responsible for your password and user ID
          security..
        </p>
        <p>
          www.pineapple.my may disable your user ID and password at
          www.pineapple.my’s sole discretion without notice or explanation.
        </p>
        <p>&nbsp;</p>
        <h3 style={{ marginBottom: "10px" }}>User content</h3>
        <p>
          In these terms and conditions, “your user content” means material
          (including without limitation text, images, audio material, video
          material and audio-visual material) that you submit to this website,
          for whatever purpose.
        </p>
        <p>
          You grant to www.pineapple.my a worldwide, irrevocable, non-exclusive,
          royalty-free license to use, reproduce, adapt, publish, translate and
          distribute your user content in any existing or future media. You also
          grant to www.pineapple.my the right to sub-license these rights, and
          the right to bring an action for infringement of these rights.
        </p>
        <p>
          Your user content must not be illegal or unlawful, must not infringe
          any third party’s legal rights, and must not be capable of giving rise
          to legal action whether against you or www.pineapple.my or a third
          party (in each case under any applicable law).
        </p>
        <p>
          You must not submit any user content to the website that is or has
          ever been the subject of any threatened or actual legal proceedings or
          other similar complaint.
        </p>
        <p>
          www.pineapple.my reserves the right to edit or remove any material
          submitted to this website, or stored on the servers of
          www.pineapple.my, or hosted or published upon this website.
        </p>
        <p>
          www.pineapple.my’s rights under these terms and conditions in relation
          to user content, www.pineapple.my does not undertake to monitor the
          submission of such content to, or the publication of such content on,
          this website.
        </p>
        <p>&nbsp;</p>
        <h3 style={{ marginBottom: "10px" }}>No warranties</h3>
        <p>
          This website is provided “as is” without any representations or
          warranties, express or implied. www.pineapple.my makes no
          representations or warranties in relation to this website or the
          information and materials provided on this website.
        </p>
        <p>
          Without prejudice to the generality of the foregoing paragraph,
          www.pineapple.my does not warrant that:
        </p>
        <ul>
          <li>
            this website will be constantly available, or available at all; or
          </li>
          <li>
            the information on this website is complete, true, accurate or
            non-misleading.
          </li>
        </ul>
        <p>
          Nothing on this website constitutes, or is meant to constitute, advice
          of any kind. If you require advice in relation to any legal, financial
          or medical matter you should consult an appropriate professional.
        </p>
        <p>&nbsp;</p>
        <h3 style={{ marginBottom: "10px" }}>Limitations of liability</h3>
        <p>
          www.pineapple.my will not be liable to you (whether under the law of
          contact, the law of torts or otherwise) in relation to the contents
          of, or use of, or otherwise in connection with, this website:
        </p>
        <ul>
          <li>
            to the extent that the website is provided free-of-charge, for any
            direct loss;
          </li>
          <li>for any indirect, special or consequential loss; or</li>
          <li>
            for any business losses, loss of revenue, income, profits or
            anticipated savings, loss of contracts or business relationships,
            loss of reputation or goodwill, or loss or corruption of information
            or data.
          </li>
        </ul>
        <p>
          These limitations of liability apply even if www.pineapple.my has been
          expressly advised of the potential loss.
        </p>
        <h3>&nbsp;</h3>
        <h3 style={{ marginBottom: "10px" }}>Exceptions</h3>
        <p>
          Nothing in this website disclaimer will exclude or limit any warranty
          implied by law that it would be unlawful to exclude or limit; and
          nothing in this website disclaimer will exclude or limit the liability
          of Pineapple Computer Systems Sdn Bhd in respect of any:
        </p>
        <ul>
          <li>
            death or personal injury caused by the negligence of
            www.pineapple.my or its agents, employees or shareholders/owners;
          </li>
          <li>
            fraud or fraudulent misrepresentation on the part of
            www.pineapple.my; or
          </li>
          <li>
            matter which it would be illegal or unlawful for www.pineapple.my to
            exclude or limit, or to attempt or purport to exclude or limit, its
            liability.
          </li>
        </ul>
        <h3>&nbsp;</h3>
        <h3 style={{ marginBottom: "10px" }}>Reasonableness</h3>
        <p>
          By using this website, you agree that the exclusions and limitations
          of liability set out in this website disclaimer are reasonable.
        </p>
        <p>
          If you do not think they are reasonable, you must not use this
          website.
        </p>
        <p>&nbsp;</p>
        <h3 style={{ marginBottom: "10px" }}>Other parties</h3>
        <p>
          You accept that, as a limited liability entity, www.pineapple.my has
          an interest in limiting the personal liability of its officers and
          employees. You agree that you will not bring any claim personally
          against www.pineapple.my’s officers or employees in respect of any
          losses you suffer in connection with the website.
        </p>
        <p>
          Without prejudice to the foregoing paragraph, you agree that the
          limitations of warranties and liability set out in this website
          disclaimer will protect www.pineapple.my’s officers, employees,
          agents, subsidiaries, successors, assigns and sub-contractors as well
          as www.pineapple.my.
        </p>
        <p>&nbsp;</p>
        <h3 style={{ marginBottom: "10px" }}>Unenforceable provisions</h3>
        <p>
          If any provision of this website disclaimer is, or is found to be,
          unenforceable under applicable law, that will not affect the
          enforceability of the other provisions of this website disclaimer.
        </p>
        <h3>&nbsp;</h3>
        <h3 style={{ marginBottom: "10px" }}>Indemnity</h3>
        <p>
          You hereby indemnify www.pineapple.my and undertake to keep
          www.pineapple.my indemnified against any losses, damages, costs,
          liabilities and expenses (including without limitation legal expenses
          and any amounts paid by www.pineapple.my to a third party in
          settlement of a claim or dispute on the advice of www.pineapple.my’s
          legal advisers) incurred or suffered by www.pineapple.my arising out
          of any breach by you of any provision of these terms and conditions,
          or arising out of any claim that you have breached any provision of
          these terms and conditions.
        </p>
        <p>&nbsp;</p>
        <h3 style={{ marginBottom: "10px" }}>
          Breaches of these terms and conditions
        </h3>
        <p>
          Without prejudice to www.pineapple.my’s other rights under these terms
          and conditions, if you breach these terms and conditions in any way,
          www.pineapple.my may take such action as www.pineapple.my deems
          appropriate to deal with the breach, including suspending your access
          to the website, prohibiting you from accessing the website, blocking
          computers using your IP address from accessing the website, contacting
          your internet service provider to request that they block your access
          to the website and/or bringing court proceedings against you.
        </p>
        <h3>&nbsp;</h3>
        <h3 style={{ marginBottom: "10px" }}>Variation</h3>
        <p>
          www.pineapple.my may revise these terms and conditions from
          time-to-time. Revised terms and conditions will apply to the use of
          this website from the date of the publication of the revised terms and
          conditions on this website. Please check this page regularly to ensure
          you are familiar with the current version.
        </p>
        <h3>&nbsp;</h3>
        <h3>&nbsp;</h3>
        <h3 style={{ marginBottom: "10px" }}>Assignment</h3>
        <p>
          www.pineapple.my may transfer, sub-contract or otherwise deal with
          www.pineapple.my’s rights and/or obligations under these terms and
          conditions without notifying you or obtaining your consent.
        </p>
        <p>
          You may not transfer, sub-contract or otherwise deal with your rights
          and/or obligations under these terms and conditions.
        </p>
        <h3>&nbsp;</h3>
        <h3 style={{ marginBottom: "10px" }}>Severability</h3>
        <p>
          If a provision of these terms and conditions is determined by any
          court or other competent authority to be unlawful and/or
          unenforceable, the other provisions will continue in effect. If any
          unlawful and/or unenforceable provision would be lawful or enforceable
          if part of it were deleted, that part will be deemed to be deleted,
          and the rest of the provision will continue in effect.
        </p>
        <h3>&nbsp;</h3>
        <h3 style={{ marginBottom: "10px" }}>Entire agreement</h3>
        <p>
          These terms and conditions, together with www.pineapple.my’s Privacy
          Policy constitute the entire agreement between you and
          www.pineapple.my in relation to your use of this website, and
          supersede all previous agreements in respect of your use of this
          website.
        </p>
        <h3>&nbsp;</h3>
        <h3 style={{ marginBottom: "10px" }}>Law and jurisdiction</h3>
        <p>
          These terms and conditions will be governed by and construed in
          accordance with the laws of Malaysia, and any disputes relating to
          these terms and conditions will be subject to the exclusive
          jurisdiction of the courts of Malaysia
        </p>
      </div>
    </div>
  );
};

export default TermsServiceMobile;
