import React from "react";
import { useDispatch, useSelector } from "react-redux";

import AccountMenu from "./AccountMenuNavigation/AccountMenu";
import DailyLoginBonusCard from "../../components/DailyLoginBonus/DailyLoginBonus";
//images
import PCoinIcon from "../../assets/images/account/DailyLoginBonus/P-CoinLargeIcon.png";
//style
import "../../assets/scss/base/pages/account/DailyLoginBonusPage/DailyLoginBonusPage.scss";
import {
  selectPcoinBalance,
  selectUserDetails,
  setPCoinBalance,
} from "../../redux/userSlice";
import { useEffect, useState } from "react";
import { getPCoinBalanceService } from "../../service/user.service";
import { Helmet } from "react-helmet";

const DailyLoginBonus = () => {
  let userDetail = useSelector(selectUserDetails);
  const PcoinBalance = useSelector(selectPcoinBalance);

  const dispatch = useDispatch();
  const [userPCoinBalance, setUserPCoinBalance] = useState();
  const [secussfullyUpdated, setSuccessfullyUpdated] = useState();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AccountMenu>
      <Helmet>
                <meta charSet="utf-8" />
                <title > Account | Daily Login Bonus</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <div style={{ width: "100%" }}>
        <div className="DailyBonus__Page__Container">
          <h5 className="DailyBonus__Page__Heading">Daily Login Bonus</h5>
          <div className="Available__Coin__Card">
            <div>
              <img
                src={PCoinIcon}
                alt="P-Coin"
                className="Available__Coin__Icon"
              />
            </div>
            <div>
              <h3 className="Available__Coin">{PcoinBalance}</h3>
              <p className="Available__Coin__Text">Available coins</p>
            </div>
          </div>
          {userDetail?.loginBonus?.map((item, index) => {
            return (
              <DailyLoginBonusCard
                BonusPerDay={item}
                key={index}
                setSuccessfullyUpdated={setSuccessfullyUpdated}
                loginBonuses={userDetail?.loginBonus}
              />
            );
          })}
        </div>
      </div>
    </AccountMenu>
  );
};

export default DailyLoginBonus;
