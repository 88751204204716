import React from "react";

import Lottie from "lottie-react";
import celebration from "../../../assets/animation/celebration.json";

import { Modal, ModalBody } from "reactstrap";
import { MdClear } from "react-icons/md";

//images
import check from "../../../assets/images/account/Order/check.png";
import Pcoin from "../../../assets/images/account/DailyLoginBonus/P-Coin.png";

//styled
import "../../../assets/scss/base/pages/account/DailyLoginBonus/Modal/SuccessModal.scss";
const SuccessModal = ({
  isOpenSuccesModal,
  setIsOpenSuccessModal,
  PCoin,
  Day,
  setSuccessfullyUpdated,
}) => {
  return (
    <div className="main">
      <Modal isOpen={isOpenSuccesModal}>
        <MdClear
          className="Success__Modal__Cross__Icon"
          onClick={() => {
            setIsOpenSuccessModal(false);
            window.location.reload(true);
            // setSuccessfullyUpdated(true);
          }}
        />
        <ModalBody>
          <div className="Success__Modal__Cont">
            <div className="Animation__Container">
              <Lottie animationData={celebration} loop={true} />
            </div>
            <img src={check} alt="succesIcon" className="S" />

            <p className="Modal__Message__Text">
              Successfully claimed <br />
              Day {Day}
            </p>
            <p className="Modal__Last__text">
              <img src={Pcoin} alt="Icon" /> {PCoin} P-Coins
            </p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SuccessModal;
