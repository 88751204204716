import React, { useEffect, useState } from "react";
//image
import PCoinIcon from "../../assets/images/account/DailyLoginBonus/P-CoinLargeIcon.png";
//styles
import "../../assets/scss/base/pages/account/ProfilePCoinPage/ProfilePCoinPage.scss";
//redux
import ProfilePcoin from "../../components/ProfilePCoin/ProfilePCoin";
import AccountMenu from "./AccountMenuNavigation/AccountMenu";
import { getAllWalletTransactions } from "../../service/wallet.service";
import Loader from "../../components/micro/loader/Loader";
import { Helmet } from "react-helmet";
const ProfilePCoin = () => {
  const [transactions, setTransactions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchAllTransactions = async () => {
      setIsLoading(true);
      const result = await getAllWalletTransactions("");
      setTransactions(result?.data?.data);
      setIsLoading(false);
    };
    fetchAllTransactions();
  }, []);
  return (
    <AccountMenu>
      <Helmet>
                <meta charSet="utf-8" />
                <title > Account | Profile</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <div style={{ width: "100%" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="ProfilePCoin__Page__Container">
              <h5 className="ProfilePCoin__Page__Heading">P-Coins</h5>
              <div className="ProfilePCoin__Available__Coin__Card">
                <div>
                  <img
                    src={PCoinIcon}
                    alt="P-Coin"
                    className="ProfilePCoin__Available__Coin__Icon"
                  />
                </div>
                <div>
                  <h3 className="ProfilePCoin__Available__Coin">
                    {transactions?.balance}
                  </h3>
                  <p className="ProfilePCoin__Available__Coin__Text">
                    Available coins
                  </p>
                </div>
              </div>
            </div>
            <h6 className="ProfilePCoin__Page__Heading">History</h6>
            {transactions?.list?.map((item, index) => {
              return <ProfilePcoin transaction={item} key={index} />;
            })}
          </>
        )}
      </div>
    </AccountMenu>
  );
};

export default ProfilePCoin;
