import React from "react";
import { FiHome } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import toast, { Toaster } from "react-hot-toast";
// Components
import Header from "../../homepage/Header";
import Navigation from "../../homepage/Navigation/Navigation";
import FooterCard from "../../homepage/FooterCard";

// Redux-Settings
import { selectCategoryDropdownValue } from "../../../redux/utilitySlice";

// Style
import "../../../assets/scss/base/layout.scss";

//image
import whatsApppIcon from "../../../assets/images/whatsApppIcon.svg";

const withLayout = (
  WrappedComponent,
  { isProductPage = false, isNestedPageName = false, isFAQPageName = false }
) => {
  return function WithLayout(props) {
    // const { state } = useSelector(selectCategoryDropdownValue);
    const { state } = useLocation();
    const { name } = useParams();
    console.log(name, "name");
    const { categoryName, subCategoryName, brandName } = useParams();
    return (
      <div className="page__layout">
        <Header />
        <Navigation />
        <main className={`${isFAQPageName ? "" : "page__layout__main"}`}>
          {isProductPage && (
            <div className="product__page__breadcrumb">
              <Link to="/">
                <FiHome />
              </Link>
              <span className="breadcrumb__divider">/</span>
              <span className="base__page__name">
                {!name && state?.categoryName && state?.categoryName}
                {name && name.replaceAll(/-/g, " ")}
              </span>
              {isNestedPageName && (
                <div className="nested__page__block">
                  <span className="breadcrumb__divider">/</span>
                  <span className="breadcrumb__dots">...</span>
                  <span className="breadcrumb__divider">/</span>
                  <span className="nested__page__name">
                    {state?.subCatagoryName && state?.subCatagoryName}
                  </span>
                </div>
              )}
            </div>
          )}

          <WrappedComponent {...props} />
          <Toaster position="top-right" />
        </main>
        <div
          style={{
            position: "fixed",
            bottom: 20,
            right: 20,
            cursor: "pointer",
            zIndex: 2000,
          }}
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=60193278400",
              "_blank"
            )
          }
        >
          <img
            style={{ width: "70px" }}
            src={whatsApppIcon}
            alt="whats-app-icon"
          />
        </div>{" "}
        <FooterCard />
      </div>
    );
  };
};

export default withLayout;
