import { memo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
// Redux-Settings
import {
  selectCartList,
  setCartList,
  selectSelectedCartItemsList,
  setSelectedCartItemsList,
  selectSelectedCartItemsId,
  setSelectedCartItemsId,
} from "../../redux/cartSlice";

// Images
import ProductPlaceHolder from "../../assets/images/placeholder/ProductPlaceHolder.svg";
import Alertircle from "../../assets/images/homepage/alert-circle.svg";
import CheckCircleBrokenIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import PCoin from "../../assets/P-Coin.png";
// Utility
import { priceCalculator } from "../../utils/Utility";
import {
  addWishlistService,
  movetoWishlistService,
} from "../../service/wishlist.service";
import toast, { Toaster } from "react-hot-toast";

// Components
import RemoveCartModal from "./Modals/RemoveCartModal";

// Styles
import "../../assets/scss/base/pages/cart/OrderedItemsList.scss";
import cartListService, {
  cartItemRemoveService,
  updateProductQuantity,
} from "../../service/cart.service";
import { useEffect } from "react";
import { selectPCoinValue } from "../../redux/utilitySlice";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const OrderedItemsList = memo(({ setLoading, loading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const cartItems = useSelector(selectCartList);
  const perPCoinValue = useSelector(selectPCoinValue);

  // Modal-Controll
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [formValue, setFormValue] = useState("");
  const [isMovedToWishlist, setIsMovedToWishlist] = useState(false);
  const [isRemovedFromCart, setIsRemovedFromCart] = useState(false);
  const [loadingMinus, setLoadingMinus] = useState(false);

  const [error, setError] = useState(false);
  const [isQuantityUpdate, setIsQuantityUpdate] = useState(false);

  const toggle = () => setIsOpenModal(!isOpenModal);
  // Cart-Item Select Settings
  const selectedCartItemsList = useSelector(selectSelectedCartItemsList);
  const selectedCartItemsId = useSelector(selectSelectedCartItemsId);
  // const [selectedCartItemsId, setSelectedCartItemsId] = useState([]);
  // Check-cart-item-selection Settings
  function isAllhaveCommonPaymentMethod() {
    // Extract paymentMethod arrays from the data array
    const paymentMethodArrays = selectedCartItemsList.map(
      (item) => item?.Product?.paymentMethods
    );
    // Check if all arrays have at least one common element
    paymentMethodArrays.every((methodArray, index) => {
      // Compare each methodArray with the first methodArray
      if (index === 0) {
        return true;
      }
      // Check if there is at least one common element between methodArray and the first methodArray
      return methodArray.some((method) =>
        paymentMethodArrays[0].includes(method)
      );
    });

    // // Extract the paymentMethod array from the new item
    // const newItemPaymentMethod = newItem?.Product?.paymentMethods;
    //   // If the data array is empty, consider the new item as valid
    //   if (data.length === 0) {
    //     return true;
    //   }
    // // Check if any of the previous items have a common value with the new item
    // for (const item of data) {
    //   const existingPaymentMethod = item.Product.paymentMethods;
    //   const commonValues = newItemPaymentMethod.filter(value => existingPaymentMethod.includes(value));
    //   if (commonValues.length > 0) {
    //     return true; // The new item contains a value already present in previous items
    //   }
    // }
    // return false; // The new item is valid
  }

  const handleCartItemSelection = (cartItem) => {
    if (selectedCartItemsId.includes(cartItem?.id)) {
      // If the cart-item is already selected, remove it
      dispatch(
        setSelectedCartItemsList(
          selectedCartItemsList.filter(
            (selectedCartItem) => selectedCartItem?.id !== cartItem?.id
          )
        )
      );
      dispatch(
        setSelectedCartItemsId(
          selectedCartItemsId.filter((id) => id !== cartItem?.id)
        )
      );
    } else {
      // If the cart-item is not selected, add it
      dispatch(setSelectedCartItemsList([...selectedCartItemsList, cartItem]));
      dispatch(setSelectedCartItemsId([...selectedCartItemsId, cartItem?.id]));
    }
    // if(isNewItemValid(cartItem, selectedCartItemsList)){
    //   if(selectedCartItemsId.includes(cartItem?.id)){
    //   // If the cart-item is already selected, remove it
    //     dispatch(setSelectedCartItemsList(selectedCartItemsList.filter((selectedCartItem) => selectedCartItem?.id !== cartItem?.id)));
    //     dispatch(setSelectedCartItemsId(selectedCartItemsId.filter((id) => id !== cartItem?.id)));
    //   } else {
    //     // If the cart-item is not selected, add it
    //     dispatch(setSelectedCartItemsList([...selectedCartItemsList, cartItem]));
    //     setSelectedCartItemsId([...selectedCartItemsId, cartItem?.id]);
    //   }
    // }
  };

  // Quantity Incress/Decress Settings
  const handleFieldChange = async (
    name,
    index,
    value,
    id,
    productId,
    productVariationId
  ) => {
    //api call of update quantity

    setIsQuantityUpdate(true);
    setLoading(true);
    const response = await updateProductQuantity(
      id,
      productId,
      productVariationId,
      value
    );
    if (!response.data.success) {
      setError(id);
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{response?.data?.errorMessage}</h4>
              {/* <p>This product is not moved to wishlist</p> */}
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
    // if (selectedCartItemsList?.some((obj) => obj.id == id)) {
    //   const newArr = selectedCartItemsList.map((item) => {
    //     if (item?.id == id) {
    //       return { ...item, [name]: value };
    //     }
    //     return item;
    //   });
    //   dispatch(setSelectedCartItemsList(newArr));
    // }
    // const newArr = cartItems.map((item, i) => {
    //   if (index == i) {
    //     return { ...item, [name]: value };
    //   }
    //   return item;
    // });
    // dispatch(setCartList(newArr));
    setLoading(false);
  };

  useEffect(() => {
    const fetchCartList = async () => {
      setIsQuantityUpdate(false);
      const response = await cartListService({ filter: "" });
      if (response?.data?.success) {
        dispatch(setCartList(response?.data?.data?.list));
        dispatch(setSelectedCartItemsList(response?.data?.data?.list));
        dispatch(
          setSelectedCartItemsId(
            response?.data?.data?.list?.map((item) => item?.id)
          )
        );
      }
    };
    fetchCartList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuantityUpdate, isMovedToWishlist, isRemovedFromCart]);
  //Move to wishlist
  const handleMoveProductWishlist = async (cartItem) => {
    const respons = await movetoWishlistService({
      productId: Number(cartItem?.id),
      productVariationsId: cartItem?.productVariationId,
    });
    if (respons?.data?.success) {
      setIsMovedToWishlist(`${cartItem?.id}` + true);
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Successfully moved to wishlist!</h4>
              <p>This product is successfully moved to wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{respons?.data?.errorMessage}</h4>
              <p>This product is not moved to wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };
 
  return (
    <>
      <div className="frame-parent11OrderedItemsList" style={{height:'620px', overflowY:'auto'}}>
        <div className="checkbox-base-parentOrderedItemsList">
          <FormGroup check>
            <Input
              className="checkbox-allOrderedItemsList"
              type="checkbox"
              checked={selectedCartItemsId?.length > 0}
            />
          </FormGroup>
          <div className="items-selectedOrderedItemsList">
            {selectedCartItemsId?.length} /{cartItems?.length} items selected
          </div>
        </div>
        {cartItems?.map((cartItem, index) => (
          <div key={cartItem?.id} className="frame-parent14OrderedItemsList">
            <div
              className="hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList"
              onClick={() => navigate(`/product/${cartItem?.Product?.slug}`)}
            >
              {cartItem?.Product?.thumbnail ? (
                <img
                  className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                  alt="item"
                  src={`${imageCDN}/${cartItem?.Product?.thumbnail}`}
                />
              ) : (
                <img
                  className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                  alt="item"
                  src={ProductPlaceHolder}
                />
              )}
            </div>
            <FormGroup check>
              <Input
                className="checkbox-baseOrderedItemsList"
                type="checkbox"
                value={cartItem.id}
                checked={selectedCartItemsId.includes(cartItem.id)}
                onChange={() => handleCartItemSelection(cartItem)}
              />
            </FormGroup>
            <div className="frame-parent15OrderedItemsList">
              <div className="hp-pavilion-14-dv2029tu-i5-123-parentOrderedItemsList">
                <div
                  className="hp-pavilion-14-dv2029tuOrderedItemsList"
                  onClick={() =>
                    navigate(`/product/${cartItem?.Product?.slug}`)
                  }
                >
                  {cartItem?.Product?.name}
                </div>

                <div
                  className="parentOrderedItemsList"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    className="rm290900OrderedItemsList"
                    style={{ display: "flex", fontWeight: "700" }}
                  >
                    <p style={{ fontSize: "11px" }}>RM</p>
                    {Number(
                      priceCalculator({
                        price: cartItem?.ProductVariation?.price,
                        discountPrice: cartItem?.ProductVariation?.discount,
                      })
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                  {cartItem?.ProductVariation?.discount > 0 && (
                    <div
                      className="divOrderedItemsList"
                      style={{ fontSize: "11px", marginTop: "2px" }}
                    >
                      RM
                      {cartItem?.ProductVariation?.price.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </div>
                  )}

                  <div
                    className="offOrderedItemsList"
                    style={{ fontSize: "11px", marginTop: "2px" }}
                  >
                    {cartItem?.ProductVariation?.discount > 0 &&
                      Math.floor(cartItem?.ProductVariation?.discount) +
                        "% OFF"}
                  </div>
                </div>
              </div>
              <div className="frame-parent13OrderedItemsList">
                {cartItem?.ProductVariation?.attibutes?.Color !== "Default" &&
                  cartItem?.ProductVariation?.attibutes?.Color !== "other" && (
                    <div className="coloringDivOrderedItemsList">
                      <p className="colorTitleOrderedItemsList">Color:</p>
                      <div
                        style={{
                          border: "2px solid #d3d3d3",
                          height: 20,
                          width: 20,
                          borderRadius: 20,
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            height: 16,
                            width: 16,
                            borderRadius: 20,
                            backgroundColor: cartItem?.ProductVariation
                              ?.attibutes?.Color
                              ? cartItem?.ProductVariation?.attibutes?.Color?.toLowerCase()
                              : cartItem?.ProductVariation?.attibutes?.color?.toLowerCase(),
                          }}
                        />
                      </div>
                      <p style={{ textTransform: "capitalize" }}>
                        {cartItem?.ProductVariation?.attibutes?.Color
                          ? cartItem?.ProductVariation?.attibutes?.Color
                          : cartItem?.ProductVariation?.attibutes?.color}
                      </p>
                    </div>
                  )}
                {(cartItem?.ProductVariation?.attibutes?.Size ||
                  cartItem?.ProductVariation?.attibutes?.storage) && (
                  <div className="coloringDivOrderedItemsList">
                    <p className="colorTitleOrderedItemsList">Size:</p>
                    <p>
                      {cartItem?.ProductVariation?.attibutes?.Size
                        ? cartItem?.ProductVariation?.attibutes?.Size + " "
                        : null}
                      {cartItem?.ProductVariation?.attibutes?.storage
                        ? cartItem?.ProductVariation?.attibutes?.storage
                        : null}
                    </p>
                  </div>
                )}
              </div>
              <div className="quantity-1-parentOrderedItemsList">
                <div className="blackOrderedItemsList">
                  Quantity :
                  <span
                    className={loading && "quantity__controllerr"}
                    onClick={() =>
                      handleFieldChange(
                        "quantity",
                        index,
                        Number(cartItem?.quantity) === 1
                          ? cartItem?.quantity
                          : Number(cartItem?.quantity) - 1,
                        cartItem?.id,
                        cartItem?.productId,
                        cartItem?.productVariationId
                      )
                    }
                  >
                    -
                  </span>
                  <span className="product__quantity">
                    {cartItem?.quantity}
                  </span>
                  <span
                    className={
                      (loading ||
                        cartItem?.ProductVariation?.stock ==
                          cartItem?.quantity) &&
                      "quantity__controllerr"
                    }
                    onClick={() =>
                      handleFieldChange(
                        "quantity",

                        index,
                        Number(cartItem?.quantity) + 1,
                        cartItem?.id,
                        cartItem?.productId,
                        cartItem?.productVariationId
                      )
                    }
                  >
                    +
                  </span>
                </div>
                {/* <img
                  className="vector-iconOrderedItemsList"
                  alt=""
                  src="/vector-1711.svg"
                /> */}
              </div>
              {/* <div style={{ marginTop: "0.3rem" }}>
                <p>
                  SKU :{" "}
                  <span style={{ color: "grey" }}>
                    {cartItem?.ProductVariation?.sku}
                  </span>
                </p>
              </div> */}
              <p className="Cart__Item__PCoin">
                Redeemable Pcoins :{" "}
                {priceCalculator({
                  price: cartItem?.ProductVariation?.price,
                  discountPrice: cartItem?.ProductVariation?.discount,
                }) *
                  perPCoinValue <
                cartItem?.ProductVariation?.redeemPcoinsLimit
                  ? parseFloat(
                      priceCalculator({
                        price: cartItem?.ProductVariation?.price,
                        discountPrice: cartItem?.ProductVariation?.discount,
                      }) * perPCoinValue
                    ) * cartItem?.quantity
                  : cartItem?.ProductVariation?.redeemPcoinsLimit *
                    cartItem?.quantity}
                {/* {cartItem?.ProductVariation?.redeemPcoinsLimit} */}
              </p>
              <p className="Cart__Item__PCoin">
                Available Stock : {cartItem?.ProductVariation?.stock}
              </p>
              <div className="remove-groupOrderedItemsList">
                <div
                  onClick={() => {
                    toggle();
                    setFormValue(cartItem);
                  }}
                  className="remove2OrderedItemsList"
                >
                  Remove
                </div>
                <img
                  className="frame-child3OrderedItemsList"
                  alt=""
                  src="/vector-1701.svg"
                />
                <div
                  className="move-to-wishlist1OrderedItemsList"
                  onClick={() => handleMoveProductWishlist(cartItem)}
                >
                  Move to Wishlist
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isOpenModal && (
        <RemoveCartModal
          isOpenModal={isOpenModal}
          toggle={toggle}
          formValue={formValue}
          setIsRemovedFromCart={setIsRemovedFromCart}
        />
      )}
    </>
  );
});

export default OrderedItemsList;