import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
// API-Service
import {
  addressMakeDefaultService,
  addressListService,
} from "../../../service/address.service";
// Redux-Settings
import {
  setBillingAddressList,
  selectBillingAddressList,
  setDefaultBillingAddressId,
} from "../../../redux/addressSlice";

// Components
import AddAddressModal from "./AddAddressModal";
import UpdateAddressModal from "./UpdateAddressModal";
import RemoveAddressModal from "./RemoveAddressModal";

function BillingAddressListingModal({
  billingAddressListingModaltoggle,
  isOpenBillingAddressListingModal,
}) {
  const dispatch = useDispatch();
  const billingAddressList = useSelector(selectBillingAddressList);

  // Add Modal-Controll
  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggle = () => setIsOpenModal(!isOpenModal);

  // Update Modal-Controll
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const toggleUpdate = () => setIsUpdateOpenModal(!isUpdateOpenModal);

  // Remove Modal-Controll
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const toggleRemoveModal = () => setIsOpenRemoveModal(!isOpenRemoveModal);

  // Set-Form-value
  const [formValue, setFormValue] = useState({});
  const [addressType, setAddressType] = useState("");

  //  Selected Address Settings
  const [selectedBillingAddress, setSelectedBillingAddress] = useState("");

  const closeBtn = (
    <button
      className="close"
      onClick={billingAddressListingModaltoggle}
      type="button"
    >
      &times;
    </button>
  );

  useEffect(() => {
    setSelectedBillingAddress(
      billingAddressList?.find((item) => item?.isDefault === true)?.id
    );
  }, [billingAddressList]);

  const fetchBillingAddressList = async () => {
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Billing",
    });
    if (response?.data?.success) {
      dispatch(setBillingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultBillingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  };

  const handleMakeDefaultBillingAddress = async (id) => {
    const response = await addressMakeDefaultService({
      id,
      type: "Billing",
    });
    if (response?.data?.success) {
      fetchBillingAddressList();
    }
  };

  return (
    <>
      <Modal
        modalClassName="left__modal"
        className="addrees__modal big__modal"
        backdropClassName="light__modal__background"
        isOpen={isOpenBillingAddressListingModal}
        toggle={billingAddressListingModaltoggle}
        fullscreen
      >
        <ModalHeader toggle={billingAddressListingModaltoggle} close={closeBtn}>
          {!isUpdateOpenModal &&
            !isOpenRemoveModal &&
            !isOpenModal &&
            "Select Address"}
          {isOpenModal && "Add Address"}
          {isUpdateOpenModal && "Update Address"}
          {isOpenRemoveModal && "Remove Address"}
        </ModalHeader>
        <ModalBody>
          {!isUpdateOpenModal && !isOpenRemoveModal && !isOpenModal && (
            <div className="address__block">
              <div className="address__title__block">
                <h3>Billing Address</h3>
                <button
                  onClick={() => {
                    toggle();
                    setAddressType("Billing");
                  }}
                >
                  + Add Address
                </button>
              </div>
              <div className="address__item__block">
                {billingAddressList.length > 0 ? (
                  <>
                    {billingAddressList?.map((address) => {
                      return (
                        <div className="frame-parent6DeliveryAddressListing">
                          <div className="frame-wrapperDeliveryAddressListing">
                            <FormGroup check>
                              <Input
                                type="radio"
                                value={address.id}
                                checked={selectedBillingAddress === address.id}
                                onChange={() => {
                                  setSelectedBillingAddress(address.id);
                                  setTimeout(() => {
                                    handleMakeDefaultBillingAddress(address.id);
                                  }, 2000);
                                }}
                                className="frame-inputDeliveryAddressListing"
                              />
                            </FormGroup>
                          </div>
                          <div className="sinan-afthar-parentDeliveryAddressListing">
                            <div className="sinan-aftharDeliveryAddressListing">
                              {address?.receiptient}
                             
                            </div>
                            <div className="jalan-tun-sambanthan-containerDeliveryAddressListing">
                            <p className="jalan-tun-sambanthanDeliveryAddressListing">
                                {address?.company}
                              </p>
                              <p className="jalan-tun-sambanthanDeliveryAddressListing">
                                {address?.tin}
                              </p>
                              <p className="jalan-tun-sambanthanDeliveryAddressListing">
                                {address?.address}
                              </p>
                              <p className="jalan-tun-sambanthanDeliveryAddressListing">
                                {address?.city}
                              </p>
                              <p className="jalan-tun-sambanthanDeliveryAddressListing">
                                {address?.state}, {address?.zipCode} -{" "}
                                {address?.country}
                              </p>
                             
                            </div>
                            <div className="jalan-tun-sambanthan-containerDeliveryAddressListing">
                              <span>{`Mobile : `}</span>
                              <span className="spanDeliveryAddressListing">
                                {address?.mobile}
                              </span>
                            </div>
                            <div className="frame-parent7DeliveryAddressListing">
                              <button
                                onClick={() => {
                                  setFormValue(address);
                                  setAddressType("Billing");
                                  toggleRemoveModal();
                                }}
                                className="remove-wrapperDeliveryAddressListing"
                              >
                                <div className="removeDeliveryAddressListing">
                                  Remove
                                </div>
                              </button>
                              <button
                                onClick={() => {
                                  setFormValue(address);
                                  toggleUpdate();
                                  setAddressType("Billing");
                                }}
                                className="remove-wrapperDeliveryAddressListing"
                              >
                                <div className="editDeliveryAddressListing">
                                  Edit
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="address__empty__block">
                    <h3>There is no default billing address found!</h3>
                  </div>
                )}
              </div>
              <button
                onClick={() => {
                  toggle();
                  setAddressType("Billing");
                }}
                className="add-new-address-wrapperDeliveryAddressListing"
              >
                <div className="add-new-addressDeliveryAddressListing">
                  + Add New Address
                </div>
              </button>
            </div>
          )}
          {isOpenModal && (
            <AddAddressModal
              toggle={toggle}
              addressType={addressType}
              isOpenModal={isOpenModal}
            />
          )}
          {isUpdateOpenModal && (
            <UpdateAddressModal
              toggleUpdate={toggleUpdate}
              addressType={addressType}
              isUpdateOpenModal={isUpdateOpenModal}
              formValue={formValue}
            />
          )}
          {isOpenRemoveModal && (
            <RemoveAddressModal
              toggleRemoveModal={toggleRemoveModal}
              addressType={addressType}
              isOpenRemoveModal={isOpenRemoveModal}
              formValue={formValue}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default BillingAddressListingModal;
