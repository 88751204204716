import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
// API-Service
import { voucherListService } from "../../../service/voucher.service";
import { applyVoucherService } from "../../../service/cart.service";

// Redux-Settings
import {
  selectCartList,
  selectSelectedCartItemsList,
  selectShipingCost,
  selectSubtotalAmount,
  setDiscountAmount,
  setIsAppliedVoucher,
  setVoucherContent,
  setVoucherDiscountAmount,
} from "../../../redux/cartSlice";

// Components
import ShowMoreLessText from "../../micro/ShowMoreLessText/ShowMoreLessText";

// Images
import VoucherNotFoundIcon from "../../../assets/images/homepage/voucher-not-found.svg";
import Alertircle from "../../../assets/images/homepage/alert-circle.svg";
import CheckCircleIcon from "../../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../../assets/images/homepage/x-close.svg";
import VoucherBackground from "../../../assets/images/homepage/Voucher-BG.svg";

function VouchersModal({ voucherModaltoggle, isOpenVoucherModal }) {
  const selectedCartItems = useSelector(selectSelectedCartItemsList);
  const subtotalAmount = useSelector(selectSubtotalAmount);
  const shipingCost = useSelector(selectShipingCost);
  const cartItems = useSelector(selectCartList);

  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(true);
  const [voucherList, setVoucherList] = useState([]);
  let productIDs = [];

  cartItems.forEach((item) => productIDs.push(item?.productId));

  const fetchList = async () => {
    setIsloading(true);
    const response = await voucherListService({
      amount: subtotalAmount,
      productIds: productIDs,
    });
    if (response?.data?.success) {
      setIsloading(false);
      setVoucherList(response?.data?.data?.list);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  // // Modal-Controll
  // const [formValue, setFormValue] = useState({});
  // const [isOpenVoucherApplyConfirmModal, setIsOpenVoucherApplyConfirmModal] = useState(false);
  // const voucherApplyConfirmToggle = () => setIsOpenVoucherApplyConfirmModal(!isOpenVoucherApplyConfirmModal);

  const closeBtn = (
    <button className="close" onClick={voucherModaltoggle} type="button">
      &times;
    </button>
  );

  const handleApplyVoucher = async ({ voucherItem }) => {
    const response = await applyVoucherService({
      shippingCost: shipingCost,
      cartItemsId: selectedCartItems?.map((cartItem) => cartItem?.id),
      code: voucherItem?.code,
      amount: subtotalAmount,
    });
    if (response?.data?.success) {
      dispatch(setVoucherDiscountAmount(response?.data?.data));
      dispatch(setDiscountAmount(response?.data?.data));
      dispatch(setVoucherContent(voucherItem?.code));
      dispatch(setIsAppliedVoucher(true));
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleIcon} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Successfully applied!</h4>
              <p>The voucher code is successfully applied</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{response?.data?.errorMessage}</h4>
              <p>This voucher can't applied</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };

  return (
    <div>
      <Modal
        className="addrees__modal voucher__modal__block big__modal"
        modalClassName="left__modal"
        backdropClassName="light__modal__background"
        isOpen={isOpenVoucherModal}
        toggle={voucherModaltoggle}
        fullscreen
      >
        <ModalHeader toggle={voucherModaltoggle} close={closeBtn}>
          Vouchers
        </ModalHeader>
        <ModalBody>
          {!isLoading ? (
            <>
              {voucherList?.length > 0 ? (
                <>
                  {voucherList?.map((voucherItem) => (
                    <div key={voucherItem?.id} className="voucher__item">
                      <img
                        src={VoucherBackground}
                        alt=""
                        className="voucher__bg"
                      />
                      <div className="voucher__content__block">
                        <div className="voucher__item__title__block">
                          <h3>{voucherItem?.code}</h3>
                          <button
                            onClick={() => {
                              handleApplyVoucher({ voucherItem });
                            }}
                          >
                            APPLY
                          </button>
                        </div>
                        <p className="voucher__item__subTitle">
                          Save{" "}
                          {voucherItem?.type === "Flat"
                            ? `RM${voucherItem?.typeValue}`
                            : `${voucherItem?.typeValue}%`}{" "}
                          on this order!
                        </p>
                        <div className="voucher__item__details__block">
                          <ShowMoreLessText
                            text={voucherItem?.description}
                            maxLength={100}
                          />
                        </div>
                        <h4 className="voucher__item__dicount__block">
                          {voucherItem?.type === "Flat"
                            ? `RM${voucherItem?.typeValue}`
                            : `${voucherItem?.typeValue}%`}{" "}
                          OFF
                        </h4>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="voucher__item__notfound__block">
                  <img src={VoucherNotFoundIcon} alt="" />
                  <div>
                    <h3>No Voucher found</h3>
                    <p>You don’t have any vouchers at this moment</p>
                  </div>
                  <button
                    className="voucher__item__back__btn"
                    onClick={voucherModaltoggle}
                  >
                    Back
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              {[1, 2, 3]?.map((placeHolderItem) => (
                <div key={placeHolderItem} className="voucher__item">
                  <Skeleton height={50} />
                  <Skeleton height={30} />
                  <Skeleton count={2} height={20} />
                </div>
              ))}
            </>
          )}
        </ModalBody>
        {/* {voucherList?.length > 0 && (
          <ModalFooter>
            <button
              className="voucher__item__back__btn"
              onClick={voucherModaltoggle}
            >
              Back
            </button>
          </ModalFooter>
        )} */}
      </Modal>
      {/* {isOpenVoucherApplyConfirmModal &&
       <VoucherApplyConfirmModal 
        isOpenVoucherApplyConfirmModal={isOpenVoucherApplyConfirmModal} 
        voucherApplyConfirmToggle={voucherApplyConfirmToggle}
        formValue={formValue}
       />} */}
      <Toaster position="top-right" />
    </div>
  );
}

export default VouchersModal;
