// wishlistSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishList: [],
  pageCount: 0,
  totalItem: 0,
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    setWishList: (state, action) => {
      state.wishList = action.payload;
    },

    setPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setTotalItem: (state, action) => {
      state.totalItem = action.payload;
    },
  },
});
export const { setWishList, setPageCount, setTotalItem } =
  wishlistSlice.actions;

export const selectWishList = (state) => state.wishlist.wishList;
export const selectPageCount = (state) => state.wishlist.pageCount;
export const selectTotalItem = (state) => state.wishlist.totalItem;

export default wishlistSlice.reducer;
