import React from 'react';
import './Loader.scss';

function Loader({customeHeight = false}) {
  return (
    <div className={`loader-container ${customeHeight ? 'custom-height' : null}`}>
      <div className="loader"></div>
    </div>
  );
}

export default Loader;