import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ReactStars from "react-rating-stars-component";
import { AiFillStar } from "react-icons/ai";
import "../../assets/scss/base/pages/wishlist/wishlistCard.scss";
import BannerImage from "../../assets/images/wishlist/CanonPrinter.png";
import TickIcon from "../../assets/images/wishlist/TickIcon.png";
import CrossIcon from "../../assets/images/wishlist/CrossIcon.svg";
import toast from "react-hot-toast";
import CheckCircleBrokenIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import Alertircle from "../../assets/images/homepage/alert-circle.svg";

import { addCartService } from "../../service/cart.service";
import { addWishlistService } from "../../service/wishlist.service";
import { selectCartList } from "../../redux/cartSlice";

const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

function WishlistCard({ item, setSuccessFullyRemoved }) {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleAddProductToCart = async () => {
    const response = await addCartService({
      productId: item?.Product?.id,
      productVariationId: item?.ProductVariation?.id,
    });
    if (response?.data?.success) {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Successfully added to cart!</h4>
              <p>This product is successfully added to cart list</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{response?.data?.errorMessage}</h4>
              <p>This product is not added to cart list</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };

  const handleRemovefromCart = async (event) => {
    event.stopPropagation();
    const response = await addWishlistService({
      productId: item?.Product?.id,
      productVariationsId: item?.productVariationsId,
    });
    if (response.data.success) {
      setSuccessFullyRemoved(true);
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Successfully removed from wishlist!</h4>
              <p>This product is successfully removed from wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{response?.data?.errorMessage}</h4>
              <p>This product is not removed from wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };
  return (
    <div
      className="wishlist__Card"
      onClick={() => navigate(`/product/${item?.Product?.slug}`)}
    >
      <div className="wishlistCard__CrossIcn">
        <img src={CrossIcon} alt="crossIcon" onClick={handleRemovefromCart} />
      </div>
      <img
        src={`${imageCDN}/${item?.Product?.thumbnail}`}
        alt="BannerImage"
        className="wishlistCard__Image"
      />
      <div className="wishlistCard__Details">
        <h1 className="wishlistCard__Title">
          {item.Product.name.length > 40
            ? item.Product.name.slice(0, 40) + "..."
            : item.Product.name}
        </h1>
        <div className="wishlist__Price">
          <p className="discount__Price" style={{ display: "flex" }}>
            <p style={{ fontSize: "11px" }}>RM</p>
            {Number.parseFloat(
              item?.ProductVariation?.price -
                (item?.ProductVariation?.discount / 100) *
                  item?.ProductVariation?.price
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          {item?.ProductVariation?.discount > 0 && (
            <p className="old__Price">
              <s>
                {" "}
                RM
                {item?.ProductVariation?.price.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </s>
            </p>
          )}

          {item?.ProductVariation?.discount > 0 && (
            <p className="discount__Precent">
              {Math.floor(item?.ProductVariation?.discount)}%OFF
            </p>
          )}
        </div>
        <div className="wishlist__Rating">
          <ReactStars
            count={5}
            value={item?.Product?.averageRating}
            size={24}
            edit={false}
            emptyIcon={<AiFillStar />}
            halfIcon={<AiFillStar />}
            fullIcon={<AiFillStar />}
            activeColor="#168952"
          />
          {item?.Product?.reviewCount && (
            <span className="wishlist__ReviewCount">
              ({item?.Product?.reviewCount})
            </span>
          )}
        </div>
        {item?.isOnCart ? (
          <button className="wishlistCard_AddedBtn">
            <img src={TickIcon} alt="tickIcon" /> Item in Cart
          </button>
        ) : (
          <button
            className="wishlistCard_AddBtn"
            onClick={handleAddProductToCart}
          >
            Add to cart
          </button>
        )}
      </div>
    </div>
  );
}

export default WishlistCard;
