import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useNavigate } from "react-router-dom";
import { BiSolidCheckCircle } from "react-icons/bi";

// API-Service
import cartListService from "../../service/cart.service";

// Higher-Order-Component
import withLayout from "../../components/higherOrderComponent/PagesLayout/PagesLayout";

// Components
import BillContainer from "../../components/cart/BillContainer";
import OrderedItemsList from "../../components/cart/OrderedItemsList";
import FinalOrderedItemsList from "../../components/cart/FinalOrderedItemsList";
import BillVoucherForm from "../../components/cart/BillVoucherForm";
import DeliveryAddressListing from "../../components/cart/DeliveryAddressListing";

// Redux-Settings
import { 
  setCartList, 
  selectCartList,
  setSelectedCartItemsId,
  setSelectedCartItemsList,
  selectCartPageTabIndex,
  setCartPageTabIndex,
  selectSelectedCartItemsList,
  selectIsOrderPlcedSuccess
} from "../../redux/cartSlice";

// Images
import { empty_cart } from "../../assets/images/cart";

// Components
import OrderSuccessModal from "./OrderSuccessModal";

// Styles
import "../../assets/scss/base/pages/cartPage/cartPage.scss"
import { Helmet } from "react-helmet";
import Loader from "../../components/micro/loader/Loader";

const CartPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const cartItems = useSelector(selectCartList);
    const selectedCartItemsList = useSelector(selectSelectedCartItemsList);
    const tabIndex = useSelector(selectCartPageTabIndex);
    const isOrderPlcedSuccessModal = useSelector(selectIsOrderPlcedSuccess)
      const [loading, setLoading] = useState(false);

    const fetchCartList = async () => {
      const response = await cartListService({filter: ''});
      if(response?.data?.success){
        dispatch(setCartList(response?.data?.data?.list))
        dispatch(setSelectedCartItemsList(response?.data?.data?.list))
        dispatch(setSelectedCartItemsId(response?.data?.data?.list?.map(item => item?.id)))
      }
    }

    useEffect(() => { 
      fetchCartList(); 
    },[])
    
    if(loading){
      return (
        <Loader/>
      )
    }

    return(
    <div className="cart__page__block"> 
    <Helmet>
                <meta charSet="utf-8" />
                {
                  tabIndex === 0 &&

                <title >Shopping Cart</title> 
                }
                {
                  tabIndex === 1 &&

                <title >Shopping Address</title> 
                }
                {
                  tabIndex === 2 &&

                <title >Checkout</title> 
                }
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
       {cartItems?.length > 0 && <ul className="cart__page__tab">
          {tabIndex === 1 || tabIndex === 2 ?
           <li className="already-marked"
           onClick={()=>{
            dispatch(setCartPageTabIndex(0))
          }}
           ><BiSolidCheckCircle /> Bag</li>:
           <li className={tabIndex === 0 ? 'active' : null}><span/> Bag</li>
          }
          {tabIndex === 2 ?
           <li className="already-marked"
            onClick={()=>{
              dispatch(setCartPageTabIndex(1))
            }}
           ><BiSolidCheckCircle /> Address</li>:
           <li className={tabIndex === 1 ? 'active' : null}><span/> Address</li>
          }
          <li className={tabIndex === 2 ? 'active' : null}><span/> Payment</li>
        </ul>}
        {tabIndex === 0 &&
          <div className="cart__page__inner__block">
            <div className="cart__page__content__block">
              {cartItems?.length < 1 && <div className="frame-groupCartMain">
                <div className="group-parentCartMain">
                  <img className="frame-childCartMain" alt="empty-cart-icon" src={empty_cart} />
                  <div className="your-cart-is-empty-parentCartMain">
                    <div className="your-cart-isCartMain">Your Cart is Empty!</div>
                    <div className="add-items-toCartMain">Add items to your cart</div>
                  </div>
                </div>
                <button onClick={() => navigate('/')} className="add-items-wrapperCartMain">
                  <div className="add-itemsCartMain">+ Add Items</div>
                </button>
              </div>}
              {cartItems?.length > 0 && <OrderedItemsList setLoading={setLoading} loading={loading}/>}
            </div>
            {cartItems?.length > 0 &&<div className="cart__page__sidebar__block">
              <BillVoucherForm/>
            </div>}
          </div>
        }
        {tabIndex === 1 &&
           <div className="cart__page__inner__block">
              <div className="cart__page__content__block">
                <DeliveryAddressListing/>
              </div> 
              <div className="cart__page__sidebar__block">
              {cartItems?.length < 1 &&<BillContainer/>}
              {cartItems?.length > 0 &&<BillVoucherForm/>}
              </div>
            </div>
        }
        {tabIndex === 2 &&
          <div className="cart__page__inner__block">
            <div className="cart__page__content__block">
              {selectedCartItemsList?.length < 1 && <div className="frame-groupCartMain">
                <div className="group-parentCartMain">
                  <img className="frame-childCartMain" alt="empty-cart-icon" src={empty_cart} />
                  <div className="your-cart-is-empty-parentCartMain">
                    <div className="your-cart-isCartMain">There is no cart item!</div>
                    <div className="add-items-toCartMain">Add items to your cart</div>
                  </div>
                </div>, 
              </div>}
              {selectedCartItemsList?.length > 0 && <FinalOrderedItemsList/>}
            </div>
            <div className="cart__page__sidebar__block">
            {selectedCartItemsList?.length < 1 &&<BillContainer/>}
            {selectedCartItemsList?.length > 0 &&<BillVoucherForm/>}
            </div>
          </div>
        }
        {/* <Tabs className='cart__page__tab' selectedIndex={tabIndex} onSelect={(index) => dispatch(setCartPageTabIndex(index))}>
          <TabList>
              <Tab><span/> Bag</Tab>
              <Tab><span/> Address</Tab> 
              <Tab><span/> Payment</Tab>
          </TabList>
          <TabPanel>  
            <div className="cart__page__inner__block">
              <div className="cart__page__content__block">
                {cartItems?.length < 1 && <div className="frame-groupCartMain">
                  <div className="group-parentCartMain">
                    <img className="frame-childCartMain" alt="empty-cart-icon" src={empty_cart} />
                    <div className="your-cart-is-empty-parentCartMain">
                      <div className="your-cart-isCartMain">Your Cart is Empty!</div>
                      <div className="add-items-toCartMain">Add items to your cart</div>
                    </div>
                  </div>
                  <button onClick={() => navigate('/')} className="add-items-wrapperCartMain">
                    <div className="add-itemsCartMain">+ Add Items</div>
                  </button>
                </div>}
                {cartItems?.length > 0 && <OrderedItemsList/>}
              </div>
              {cartItems?.length > 0 &&<div className="cart__page__sidebar__block">
                 <BillVoucherForm/>
              </div>}
          </div>
          </TabPanel>
          <TabPanel>
            <div className="cart__page__inner__block">
              <div className="cart__page__content__block">
                <DeliveryAddressListing/>
              </div>
              <div className="cart__page__sidebar__block">
              {cartItems?.length < 1 &&<BillContainer/>}
              {cartItems?.length > 0 &&<BillVoucherForm/>}
              </div>
          </div>
          </TabPanel>
          <TabPanel>
            <div className="cart__page__inner__block">
              <div className="cart__page__content__block">
                {selectedCartItemsList?.length < 1 && <div className="frame-groupCartMain">
                  <div className="group-parentCartMain">
                    <img className="frame-childCartMain" alt="empty-cart-icon" src={empty_cart} />
                    <div className="your-cart-is-empty-parentCartMain">
                      <div className="your-cart-isCartMain">There is no cart item!</div>
                      <div className="add-items-toCartMain">Add items to your cart</div>
                    </div>
                  </div>
                </div>}
                {selectedCartItemsList?.length > 0 && <FinalOrderedItemsList/>}
              </div>
              <div className="cart__page__sidebar__block">
              {selectedCartItemsList?.length < 1 &&<BillContainer/>}
              {selectedCartItemsList?.length > 0 &&<BillVoucherForm/>}
              </div>
          </div>
          </TabPanel>
        </Tabs> */}
        {isOrderPlcedSuccessModal && <OrderSuccessModal/>}
    </div>
    )
};
  
export default withLayout(CartPage,{isProductPage: false, isNestedPageName: false});  