import { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
// API Service
import {
  paymentMethodListService,
  getShippingCostService,
} from "../../service/cart.service";

// Redux-Settings
import {
  selectSelectedCartItemsList,
  setShipingCost,
  selectShipingCost,
  setWeight,
  setPaymentMethod,
  selectPaymentMethod,
  setShippingEnabled,
  setSelfPickup,
  selectSelfPickup,
  setDiscountAmount,
  setRedeemedpCoin,
  setIsAppliedPCoin,
  setpCoinDiscountAmount,
  setCouponContent,
  setIsAppliedCoupon,
  setCouponDiscountAmount,
  selectCouponType,
} from "../../redux/cartSlice";
import {
  selectDefaultShippingAddressId,
  selectShippingAddressList,
} from "../../redux/addressSlice";
// Images
import ProductPlaceHolder from "../../assets/images/placeholder/ProductPlaceHolder.svg";
// Utility
import { priceCalculator } from "../../utils/Utility";
// Components
import RemoveCartModal from "./Modals/RemoveCartModal";

// Styles
import "../../assets/scss/base/pages/cart/OrderedItemsList.scss";
import { selectPCoinValue } from "../../redux/utilitySlice";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const FinalOrderedItemsList = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defultShippingID = useSelector(selectDefaultShippingAddressId);
  const shippingAddressList = useSelector(selectShippingAddressList);
  const perPCoinValue = useSelector(selectPCoinValue);

  const selectedPaymentMethod = useSelector(selectPaymentMethod);
  const isSelfPickup = useSelector(selectSelfPickup);
  const couponType = useSelector(selectCouponType);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const shipingCost = useSelector(selectShipingCost);
  // Modal-Controll
  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggle = () => setIsOpenModal(!isOpenModal);

  // Cart-Item Select Settings
  const selectedCartItemsList = useSelector(selectSelectedCartItemsList);

  // Fetch payment method
  const fetchPaymentMethodList = async () => {
    const response = await paymentMethodListService({
      getAll: true,
      filter: "Active",
    });
    if (response?.data?.success) {
      setPaymentMethods(response?.data?.data?.list);
    }
  };

  useEffect(() => {
    fetchPaymentMethodList();
  }, []);

  return (
    <div className="frame-parent11OrderedItemsList" style={{height:'880px', overflowY:'auto'}}>
      <div className="checkbox-base-parentOrderedItemsList">
        <div className="items-selectedOrderedItemsList">Order Overview</div>
      </div>
      {selectedCartItemsList?.map((cartItem, index) => (
        <div key={cartItem?.id} className="frame-parent14OrderedItemsList">
          <div
            // onClick={() => {
            //   navigate(
            //     `/product/${cartItem?.Product?.id}/${cartItem?.Product?.name
            //       ?.trim()
            //       ?.replace(/ /g, "-")}`,
            //     { state: { productId: cartItem?.Product?.id } }
            //   );
            // }}
            className="hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList"
          >
            {cartItem?.Product?.thumbnail ? (
              <img
                className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                alt="item"
                src={`${imageCDN}/${cartItem?.Product?.thumbnail}`}
              />
            ) : (
              <img
                className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                alt="item"
                src={ProductPlaceHolder}
              />
            )}
          </div>
          <div className="frame-parent15OrderedItemsList">
            <div className="hp-pavilion-14-dv2029tu-i5-123-parentOrderedItemsList">
              <div
                // onClick={() => {
                //   navigate(
                //     `/product/${cartItem?.Product?.id}/${cartItem?.Product?.name
                //       ?.trim()
                //       ?.replace(/ /g, "-")}`,
                //     { state: { productId: cartItem?.Product?.id } }
                //   );
                // }}
                className="hp-pavilion-14-dv2029tuOrderedItemsList"
              >
                {cartItem?.Product?.name}
              </div>
              <div
                className="parentOrderedItemsList"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className="rm290900OrderedItemsList"
                  style={{ display: "flex", fontWeight: "700" }}
                >
                  <p style={{ fontSize: "11px" }}>RM</p>
                  {Number(
                    priceCalculator({
                      price: cartItem?.ProductVariation?.price,
                      discountPrice: cartItem?.ProductVariation?.discount,
                    })
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
                {cartItem?.ProductVariation?.discount > 0 && (
                  <div
                    className="divOrderedItemsList"
                    style={{ fontSize: "11px", marginTop: "3px" }}
                  >
                    RM
                    {cartItem?.ProductVariation?.price.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                )}

                <div
                  className="offOrderedItemsList"
                  style={{ fontSize: "11px", marginTop: "3px" }}
                >
                  {cartItem?.ProductVariation?.discount > 0 &&
                    Math.floor(cartItem?.ProductVariation?.discount) + "% OFF"}
                </div>
              </div>
            </div>

            <div className="frame-parent13OrderedItemsList">
              {cartItem?.ProductVariation?.attibutes?.Color !== "Default" &&
                cartItem?.ProductVariation?.attibutes?.Color !== "other" && (
                  <div className="coloringDivOrderedItemsList">
                    <p className="colorTitleOrderedItemsList">Color:</p>
                    <div
                      style={{
                        border: "2px solid #d3d3d3",
                        height: 20,
                        width: 20,
                        borderRadius: 20,
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          height: 16,
                          width: 16,
                          borderRadius: 20,
                          backgroundColor: cartItem?.ProductVariation?.attibutes
                            ?.Color
                            ? cartItem?.ProductVariation?.attibutes?.Color?.toLowerCase()
                            : cartItem?.ProductVariation?.attibutes?.color?.toLowerCase(),
                        }}
                      />
                    </div>
                    <p style={{ textTransform: "capitalize" }}>
                      {cartItem?.ProductVariation?.attibutes?.Color
                        ? cartItem?.ProductVariation?.attibutes?.Color
                        : cartItem?.ProductVariation?.attibutes?.color}
                    </p>
                  </div>
                )}

              <div className="coloringDivOrderedItemsList">
                <p className="colorTitleOrderedItemsList">Size:</p>
                <p>
                  {cartItem?.ProductVariation?.attibutes?.Size
                    ? cartItem?.ProductVariation?.attibutes?.Size
                    : null}
                  {cartItem?.ProductVariation?.attibutes?.storage
                    ? cartItem?.ProductVariation?.attibutes?.storage
                    : null}
                </p>
              </div>
            </div>
            <div className="quantity-1-parentOrderedItemsList">
              <div className="blackOrderedItemsList">
                Quantity :
                <span className="product__quantity">{cartItem?.quantity}</span>
              </div>
              <img
                className="vector-iconOrderedItemsList"
                alt=""
                src="/vector-1711.svg"
              />
            </div>
            {/* <div style={{ marginTop: "0.2rem" }}>
              <p>
                SKU :{" "}
                <span style={{ color: "grey" }}>
                  {cartItem?.ProductVariation?.sku}
                </span>
              </p>
            </div> */}
            <p className="Cart__Item__PCoin">
              Redeemable Pcoins :{" "}
              {priceCalculator({
                price: cartItem?.ProductVariation?.price,
                discountPrice: cartItem?.ProductVariation?.discount,
              }) *
                perPCoinValue <
              cartItem?.ProductVariation?.redeemPcoinsLimit
                ? parseFloat(
                    priceCalculator({
                      price: cartItem?.ProductVariation?.price,
                      discountPrice: cartItem?.ProductVariation?.discount,
                    }) * perPCoinValue
                  ) * cartItem?.quantity
                : cartItem?.ProductVariation?.redeemPcoinsLimit *
                  cartItem?.quantity}
            </p>
          </div>
          {isOpenModal && (
            <RemoveCartModal
              isOpenModal={isOpenModal}
              toggle={toggle}
              data={cartItem?.id}
            />
          )}
        </div>
      ))}
      <div className="cart__payment__option__block">
        <div className="cart__payment__option__title">
          <h3>Payment Method</h3>
        </div>
        <>
          {paymentMethods?.map((payMentMethodItem) => (
            <div
              key={payMentMethodItem?.id}
              className="cart__payment__option__wrap"
            >
              <FormGroup check>
                <Input
                  name="paymentMethod"
                  id="isDefaultAsShippingAddress"
                  type="checkbox"
                  onClick={() =>
                    dispatch(setPaymentMethod(payMentMethodItem?.id))
                  }
                  checked={selectedPaymentMethod == payMentMethodItem?.id}
                />
                <Label for="isDefaultAsShippingAddress" check>
                  <div className="cart__payment__option__content__block">
                    <p>{payMentMethodItem?.name}</p>
                    <img
                      src={`${imageCDN}/${payMentMethodItem?.file}`}
                      alt=""
                      style={{ height: "100%" }}
                    />
                  </div>
                </Label>
              </FormGroup>
            </div>
          ))}
        </>
      </div>

      <div className="cart__payment__option__block">
        <div className="cart__payment__option__title">
          <h3>Shipping Cost</h3>
        </div>
        <div className="cart__payment__option__wrap">
          <FormGroup check>
            <Input
              name="paymentMethod"
              id="isDefaultAsShippingAddress"
              type="checkbox"
              checked={isSelfPickup ? false : true}
              onChange={() => dispatch(setSelfPickup(false))}
            />
            <Label for="isDefaultAsShippingAddress" check>
              <div className="cart__payment__option__content__block">
                <p>Shipping Rate RM {shipingCost.toFixed(2)}</p>
              </div>
            </Label>
          </FormGroup>
        </div>
      </div>
      <div
        className="cart__payment__option__block"
        style={{ marginTop: "-0px" }}
      >
        <div className="cart__payment__option__wrap">
          <FormGroup check>
            <Input
              name="paymentMethod"
              id="isDefaultAsShippingAddress"
              type="checkbox"
              checked={isSelfPickup ? true : false}
              onChange={() => {
                dispatch(setSelfPickup(true));
                if (couponType == "Free Shipping") {
                  dispatch(setDiscountAmount(0));
                  dispatch(setCouponContent(""));
                  dispatch(setIsAppliedCoupon(false));
                  dispatch(setCouponDiscountAmount(0));
                }
              }}
            />
            <Label for="isDefaultAsShippingAddress" check>
              <div className="cart__payment__option__content__block">
                <p>Self-Collect from Wisma Pineapple</p>
              </div>
            </Label>
          </FormGroup>
        </div>
      </div>
    </div>
  );
});

export default FinalOrderedItemsList;
