import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// Components
import Product from "../product/DynamicProductItem";
// Redux-Settings
import {
  setPageCount,
  selectProductList,
  selectTotalItem,
  setProductList,
  selectedPageNo,
  setSelectedPageNo,
} from "../../redux/productSlice";

// Imgaes
import CatalougePlaceHolder from "../../assets/images/placeholder/CatalougePlaceHolder.svg";

import "../../assets/scss/base/pages/catalogue/CataloguePagination.scss";
import "../../assets/scss/base/pages/global.scss";
import { selectPageCount } from "../../redux/cartSlice";
import getProductList from "../../service/catalogue.service";
import { setFilteredList, setTotalItem } from "../../redux/categoriesSlice";
import { useParams } from "react-router-dom";

function Items({ products }) {
  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        <div
          className="catalogueProductsContainerMain"
          style={{ minHeight: "zx250px" }}
        >
          {products?.length > 0 ? (
            products?.map((product) => (
              <div className="catalouge__product__item" key={product?.id}>
                <Product product={product} />
              </div>
            ))
          ) : (
            <div className="catalouge__item__notfound__block">
              <img src={CatalougePlaceHolder} alt="p-Coin" />
              <h3>No Products</h3>
              <p>Oops! No products found</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function CataloguePagination({
  itemsPerPage = 1,
  handleFetchProductBasedOnFilterListing,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const products = useSelector(selectProductList);
  const totalitems = useSelector(selectTotalItem);
  const totalPage = Number(totalitems) / 12;
  const { name } = useParams();

// const[selected,setSelected]=useState(3)

const selected = useSelector(selectedPageNo);

  
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(totalPage / itemsPerPage);
  console.log(location?.pathname.includes("/promotion"), "location");
  useEffect(() => {
    dispatch(setPageCount(endOffset));
  }, [endOffset]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = windowWidth <= 1100;
  const handleChange = async (number) => {
    const response = await getProductList({
      // ...(state?.promoFilter && { [state?.promoFilter]: true }),
      ...(name === "new-arrival" && {
        isNewArrival: name === "new-arrival" && true,
      }),
      ...(name === "trending" && { isTrending: name === "trending" && true }),
      ...(name === "best-selling" && {
        isBestSelling: name === "best-selling" && true,
      }),
      ...(name === "super-saver" && {
        isSuperSaver: name === "super-saver" && true,
      }),
      page: number,
    });

    if (response?.data?.success) {
      dispatch(setProductList(response?.data?.data?.list));
      dispatch(setFilteredList(response?.data?.data?.list));
      dispatch(setTotalItem(response?.data?.data?.count));
    }
    console.log(number, "number");
  };
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
  
    const newOffset = (event.selected * itemsPerPage) % totalPage;
    // setSelected(newOffset)
    dispatch(setSelectedPageNo(newOffset));
    setItemOffset(newOffset);
    if (location?.pathname.includes("/promotion")) {
      handleChange(event.selected + 1);
    } else {
      handleFetchProductBasedOnFilterListing(event.selected + 1);
    }
  };



  console.log('selected====>',selected)
  return (
    <>
      <Items products={products} />
      {isSmallScreen ? (
        <div className="paginationMainFlexDiv mob">
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            marginPagesDisplayed={0}
            pageCount={pageCount}
            previousLabel="< "
            renderOnZeroPageCount={null}
            breakClassName="PaginationLi"
            className="cataloguePaginationComponent"
            forcePage={selected}
          />
          {/* <p>Page 1 of 7</p> */}
        </div>
      ) : (
        <div className="paginationMainFlexDiv">
          <p style={{ width: "116px" }}>
            Page {endOffset} of {Math.ceil(totalPage)}
          </p>
          <ReactPaginate
            breakLabel="...."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={6}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            breakClassName="PaginationLi"
            className="cataloguePaginationComponent"
            forcePage={selected}
          />
        </div>
      )}
    </>
  );
}

export default CataloguePagination;
