import { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SlArrowUp } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import SignIn from "./pages/authentication/SignIn";
import SignUp from "./pages/authentication/SignUp";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import PhoneNumberVerification from "./pages/authentication/PhoneNumberVerification";
import ResetPasswordSuccessful from "./pages/authentication/ResetPasswordSuccessful";
import Home from "./pages/homepage/Home";
import OtpVerificationForResetPassword from "./pages/authentication/OtpVerificationForResetPassword";
import Account from "./pages/account/Account";
import PrivateRoute from "./utils/PrivateRoute";
import PCoins from "./pages/account/PCoins";
import DailyLoginBonus from "./pages/account/DailyLoginBonus";
import Orders from "./pages/account/Orders";
import AddressBook from "./pages/account/AddressBook";
import ChangePassword from "./pages/account/ChangePassword";
import Logout from "./pages/account/Logout";
import OtpVerificationChangeMobileNo from "./pages/account/OtpVerifyChangeMobileNo";
import Profile from "./pages/account/Profile";
import SingleCatalogueItem from "./pages/singleCatalogueItem/SingleCatalogueItem";
import Address from "./pages/cart/Address";
import Cart from "./pages/cart/Cart";
import CartItems from "./pages/cart/CartItems";

/* User Delete Account*/
import DeleteAccountPage from "./pages/DeleteAccount/DeleteAccount";

// RefactorV2
import CataloguePage from "./pages/cataloguePage/CataloguePage";
import ProductDetailsPage from "./pages/productDetailsPage/ProductDetailsPage";
import CartPage from "./pages/cartPage/CartPage";
import WishlistPage from "./pages/wishlist/wishlistPage";
import PromoPage from "./pages/PromoPage/PromoPage";

// API-Servce
import { getCategoriesListingArray } from "./service/dashboard.service";
import {
  getPCoinBalanceService,
  getSettingsService,
} from "./service/user.service";
import { getBannersList } from "./service/dashboard.service";
import cartListService from "./service/cart.service";

// Utility-Settings
import {
  getToken,
  getTokenExpireTime,
  getUser,
  setRefreshToken,
  setTokenExpireTime,
} from "./utils/AuthService";

// Redux-Settings
import { setLogin, isLoggedIn } from "./redux/authSlice";
import {
  setIsSmallScreen,
  setUserPCoinBalance,
  setPCoinValue,
  setScreenSize,
  setBannerList,
} from "./redux/utilitySlice";
import { setCategoriesList } from "./redux/categoriesSlice";
import {
  setCartList,
  setSelectedCartItemsList,
  setSelectedCartItemsId,
} from "./redux/cartSlice";
// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/base/pages/App.scss";
import AccountMenu from "./pages/account/AccountMenuNavigation/AccountMenu";
import SingleOngoingOrder from "./pages/account/SingleOngoingOrder";
import ProfilePcoin from "./pages/account/ProfilePCoin";
import OverviewPage from "./pages/account/OverviewPage";
import PasswordChangedSuccess from "./pages/account/PasswordChangedSucess";
import {
  selectLoginModalToggle,
  selectRegisterModalToggle,
} from "./redux/modalsSlice";
import OrderSuccessModal from "./pages/cartPage/OrderSuccessModal";
import PaymentFailedModal from "./pages/cartPage/PaymentFailedModal";
import FAQPage from "./pages/FAQPage/FAQPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicyPage";
import TermsService from "./pages/TermsService/TermsService";
import RefundReturnWarrantyPage from "./pages/RefundReturnWarranty/RefundReturnWarrantyPage";
import DeliveryPolicyPage from "./pages/DeliveryPolicy/DeliveryPolicyPage";
import PrivacyPolicyMobile from "./pages/PrivacyPolicyMobile/PrivacyPolicyMobile";
import FAQMobile from "./pages/FAQMobile/FAQMobile";
import TermsServiceMobile from "./pages/TermServiceMobile/TermsServiceMobile";
import ReturnRefundPolicyMobile from "./pages/ReturnRefundPolicyMobile/ReturnRefundPolicyMobile";
//images
import whatsApppIcon from "./assets/images/whatsApppIcon.svg";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import { refreshToken } from "./service/auth.service";

function App() {
  const token = getToken();
  const disptach = useDispatch();
  const isLoginModalOpen = useSelector(selectLoginModalToggle);
  const isRegisterModalOpen = useSelector(selectRegisterModalToggle);
  const [showButton, setShowButton] = useState(false);

  const isLoggedInUser = useSelector(isLoggedIn);
  //  console.log(token)
  useEffect(() => {
    ReactGA.initialize("G-EJKV6KT1PM");
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: "/landingpage",
      title: "Landing Page",
    });
  }, []);

  useEffect(() => {
    if (token) {
      disptach(setLogin(true));
    }
  }, [disptach, token]);

  useEffect(() => {
    const fetchRefreshToken = async () => {
      try {
        if (token?.tokenRefresh && token?.tokenAccess) {
          const result = await refreshToken();

          const newTokens = {
            tokenRefresh: result?.data?.data?.tokens?.refresh?.token,
            tokenAccess: result?.data?.data?.tokens?.access?.token,
            foodRefreshToken: result?.data?.data?.foodTokens?.refresh?.token,
            foodAccessToken: result?.data?.data?.foodTokens?.access?.token,
          };

          setRefreshToken(newTokens);

          setTokenExpireTime(
            result?.data?.data?.tokens?.access?.expires,
            result?.data?.data?.foodTokens?.access?.expires
          );
        }
      } catch (error) {
        console.error("Failed to refresh token:", error);
      }
    };

    const shouldRefreshToken = () => {
      const { tokensExpireTime, foodTokensExpireTime } = getTokenExpireTime();
      const now = new Date();

      if (
        new Date(tokensExpireTime) <= now ||
        new Date(foodTokensExpireTime) <= now
      ) {
        return true;
      }

      return false;
    };

    if (token && shouldRefreshToken()) {
      fetchRefreshToken();
    }
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      disptach(setIsSmallScreen(window.innerWidth <= 900));
    };
    window.addEventListener("resize", handleResize);
    disptach(setIsSmallScreen(window.innerWidth <= 900));
    disptach(setScreenSize(window.innerWidth));
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [disptach]);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  /**
   * @function fetchCategoriesList
   * @param {}
   * @description This function is used to fetch catagories
   */
  const fetchCategoriesList = async () => {
    const response = await getCategoriesListingArray({
      order: 1,
      filter: "Active",
      getAll: "true",
    });
    disptach(setCategoriesList(response?.data?.data?.list));
    return response;
  };

  /**
   * @function getPCoinBalanceService
   * @param {}
   * @description This function is used to user P-Coin balance
   */
  const getPCoinBalance = async () => {
    const response = await getPCoinBalanceService({ filter: "" });
    disptach(setUserPCoinBalance(response?.data?.data));
    return response;
  };

  /**
   * @function getPCoinValue
   * @param {}
   * @description This function is used to see per P-Coin value in RM
   */
  const getPCoinValue = async () => {
    const response = await getSettingsService({ key: "One_RM_PCoins" });
    disptach(setPCoinValue(response?.data?.data?.value));
    return response;
  };

  /**
   * @function fetchCartList
   * @param {}
   * @description This function is used to get list of cart item and cart-item-count
   */
  const fetchCartList = async () => {
    const response = await cartListService({ filter: "" });
    if (response?.data?.success) {
      disptach(setCartList(response?.data?.data?.list));
      disptach(setSelectedCartItemsList(response?.data?.data?.list));
      disptach(
        setSelectedCartItemsId(
          response?.data?.data?.list?.map((item) => item?.id)
        )
      );
    }
  };

  const fetchBanner = async () => {
    try {
      const response = await getBannersList({
        filter: "",
        type: "",
      });
      if (response?.data?.success) {
        disptach(setBannerList(response?.data?.data?.list));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isLoggedInUser) {
      fetchCartList();
    }
  }, [isLoggedInUser]);

  useEffect(() => {
    fetchCategoriesList();
    getPCoinBalance();
    getPCoinValue();
    fetchCartList();
    fetchBanner();
  }, []);

  // This function will scroll the window to the top
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />

          <Route path="/login" element={<Home />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/verification" element={<PhoneNumberVerification />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/user-delete" element={<DeleteAccountPage />} />
          <Route path="/faq-mobile" element={<FAQMobile />} />

          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route
            path="/privacypolicy-mobile"
            element={<PrivacyPolicyMobile />}
          />

          <Route path="/terms" element={<TermsService />} />
          <Route path="/terms-mobile" element={<TermsServiceMobile />} />

          <Route path="/deliverypolicy" element={<DeliveryPolicyPage />} />

          <Route
            path="/return-refund-warranty"
            element={<RefundReturnWarrantyPage />}
          />
          <Route
            path="/return-refund-warranty-mobile"
            element={<ReturnRefundPolicyMobile />}
          />

          <Route
            path="/otpverification"
            element={<OtpVerificationForResetPassword />}
          />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route
            path="/resetpassword/successful"
            element={<ResetPasswordSuccessful />}
          />
          <Route path="/" element={<Home />} />

          {/* USER ACCOUNT PAGES */}
          <Route element={<PrivateRoute />}>
            <Route path="/account/overview" element={<OverviewPage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/account/P-Coin" element={<ProfilePcoin />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path="/account/dailyLoginBonus"
              element={<DailyLoginBonus />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/account/orders" element={<Orders />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/account/order/:id" element={<SingleOngoingOrder />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/account/addressBook" element={<AddressBook />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/account" element={<ChangePassword />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/account" element={<Logout />} />
          </Route>

          <Route element={<PrivateRoute />}></Route>
          <Route
            path="/changeMobileNo/otpverification"
            element={<OtpVerificationChangeMobileNo />}
          />

          <Route element={<PrivateRoute />}>
            <Route
              path="/account/changepassword"
              element={<ChangePassword />}
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/account/profile" element={<Profile />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path="/changepassword/success"
              element={<PasswordChangedSuccess />}
            />
          </Route>
          <Route
            path="/account/dailyLoginBonus"
            element={<DailyLoginBonus />}
          />

          {/* CATALOGUE */}

          <Route
            path="/catalogue/:categoryName?/:subCategoryName?/:brandName?"
            element={<CataloguePage />}
          />

          <Route path="/promotion/:name" element={<PromoPage />} />

          <Route
            path="/product/:productName"
            element={<ProductDetailsPage />}
          />

          {/* CART  */}

          <Route path="/address" element={<Address />} />

          <Route path="/cart" element={<Cart />} />
          <Route path="/order/checkout" element={<OrderSuccessModal />} />

          <Route path="/cartItems" element={<CartItems />} />

          <Route element={<PrivateRoute />}>
            <Route path="/cart-page" element={<CartPage />} />
          </Route>

          {/* Wishlist  */}

          <Route element={<PrivateRoute />}>
            <Route path="/wishlist-page" element={<WishlistPage />} />
          </Route>
        </Routes>
        {isLoginModalOpen && <SignIn />}
        {showButton && (
          <div
            style={{
              position: "fixed",
              bottom: 100,
              right: 27,
              cursor: "pointer",
              zIndex: 2000,
              backgroundColor: "rgb(236 57 55)",
              color: "white",
              padding: "17px 19px",
              borderRadius: "50%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            onClick={() => handleScrollToTop()}
          >
            <SlArrowUp style={{ width: "20px", height: "20px" }} />
          </div>
        )}

        {/* {isRegisterModalOpen && <SignUp />} */}
      </BrowserRouter>
    
  );
}

export default App;
