import React from "react";
import withLayout from "../../components/higherOrderComponent/PagesLayout/PagesLayout";
import { Helmet } from "react-helmet";

const RefundReturnWarrantyPage = () => {
  return (
    <div style={{ fontFamily: "Poppins" }}>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Return, Refund & Warranty Policy</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <h2 style={{ margin: "2rem 0" }}>Return, Refund & Warranty Policy</h2>
      <p>
        <strong>Products Warranty</strong>
      </p>
      <p>
        All new products warranty is subject to the warranty policy of the
        individual vendor. Please refer to individual product for warranty
        period.
      </p>
      <p>
        <strong>Dead on Arrival (DOA)</strong>
      </p>
      <p>
        Meaning : Item shows symptoms of a hardware failure, preventing basic
        operation upon its first use out of the box, and then it may be deemed
        DOA.
      </p>
      <p>
        <strong>Important!</strong>
      </p>
      <ul>
        <li>
          Please note that original manufacturers’ packaging is part of the
          goods; please ensure you return the DOA product to us in their
          original packaging.
        </li>
        <li>
          The goods are second hand without the packaging and we may not
          therefore be able to process your return.
        </li>
        <li>
          Any free promotional gift issued with an order must also be returned
          if you are returning those goods.
        </li>
        <li>
          If the customer misrepresents the condition of the product, Pineapple
          Computer Systems Sdn. Bhd. may imposed a RM100 handling fee and the
          product will be returned to you at your expense.
        </li>
        <li>
          Any returned product that is damaged through customer misuse or is
          missing parts due to customer tampering will not be accepted.
        </li>
        <li>
          Product must be deemed DOA by the respective vendor in order to
          receive a replacement unit.
        </li>
        <li>
          If product is reported as DOA to Pineapple Computer Systems Sdn. Bhd.
          more than 7 days after you receive it, standard warranty shall apply.
        </li>
        <li>
          If the product is deemed by respective vendor NOT to be DOA, standard
          product warranty will apply.
        </li>
      </ul>
      <p>
        <strong>Returns &amp; Exchange Policy</strong>
      </p>

      <li>
        Should you change your mind on your item purchased, we are glad to
        provide an exchange.&nbsp;
        <strong>
          Pineapple Computer Systems Sdn. Bhd. do not practice refund policy
          unless necessary.
        </strong>
      </li>
      <li>
        Should customer choose a higher value product for the exchange, customer
        is required to pay the differences. No refund will be given should the
        value is lower.
      </li>
      <li>
        Before returning your product, please call our customer service for an
        RMA Exchange No.
      </li>
      <li>
        Exchange product must be returned to Pineapple Computer Systems Sdn
        Bhd&nbsp;within 7 days from the invoice date.
      </li>
      <li>
        Please ensure you return the goods to us in their original&nbsp;
        <strong>UNOPENED</strong>&nbsp;packaging for all exchanges.
      </li>
      <li>
        Please note that original manufacturers’ packaging is part of the goods,
        so please retain it incase you wish to return them for DOA or exchange.
      </li>
      <li>
        The goods are second hand without the packaging and we may not therefore
        be able to process your return.
      </li>
      <li>
        Any free promotional gift issued with an order must also be returned if
        you are returning those goods.
      </li>
      <li>
        Pineapple Computer Systems Sdn. Bhd. reserve&nbsp;all&nbsp;rights in any
        dispute and has the final decision
      </li>
    </div>
  );
};

export default withLayout(RefundReturnWarrantyPage, {
  isProductPage: false,
  isNestedPageName: false,
});
