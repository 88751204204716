import React, { memo, useState } from "react";
import { Nav, NavItem } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { RiMenu2Fill } from "react-icons/ri";
import { CiSearch, CiCircleRemove } from "react-icons/ci";
import Drawer from "react-modern-drawer";
import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";

// Components
import MainCategoriesDropdown from "../MainCategoriesDropdown/MainCategoriesDropdown";
// Redux-Settings
import {
  selectIsSmallScreen,
  selectUserPCoinBalance,
} from "../../../redux/utilitySlice";
import { setCategoryDropdownValue } from "../../../redux/utilitySlice";
// Images
import PCoinLarge from "../../../assets/P-CoinLargeIcon.png";
import SearchIcon from "../../../assets/images/homepage/search-icon.png";

// Styles
import "../../../assets/scss/base/pages/homepage/Navigation.scss";
import "react-modern-drawer/dist/index.css";
import { selectPcoinBalance } from "../../../redux/userSlice";
import { isLoggedIn } from "../../../redux/authSlice";
import getProductList from "../../../service/catalogue.service";
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

const Navigation = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggdin = useSelector(isLoggedIn);

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const PcoinBalance = useSelector(selectPcoinBalance);
  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <img
        src={SearchIcon}
        alt=""
        style={{
          padding: "0 8px",
        }}
      />{" "}
      {children}
    </components.Control>
  );
  const PlaceHolder = ({ children, ...props }) => (
    <components.Placeholder {...props}>
      <span
        style={{
          color: "#667085",
          fontSize: 16,
        }}
      >
        Search
      </span>
    </components.Placeholder>
  );
  const productLoadOptions = async (searchQuery, loadedOptions, additional) => {
    const page = additional?.page ?? 1;
    if (!searchQuery) {
      return {
        options: [],
        hasMore: false,
      };
    }
    try {
      const response = await getProductList({
        page,
        search: searchQuery || "",
        categoryId: "",
        brandId: "",
        subCategoryId: "",
        subCategoryTypeId: "",
        isOnFlashSale: "",
      });
      if (response?.data?.success) {
        const result = response?.data?.data?.list?.map((item) => ({
          value: String(item.id),
          // eslint-disable-next-line jsx-a11y/alt-text
          label: getImage(item),
          ...item,
        }));
        return {
          options: result,
          hasMore: Math.ceil(response?.data?.data?.count / 10) > page,
          additional: {
            page: page + 1,
          },
        };
      }
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error loading options:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  const [productSelectedValue, setProductSelectedValue] = useState(null);
  const getImage = (item) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            cursor: "Pointer",
            alignItems: "start",
            gap: "0.6rem",
          }}
          className="hover__State"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/product/${item?.slug}`);
          }}
        >
          {item?.thumbnail && (
            <img
              src={`${url}/${item.thumbnail}`}
              alt="icon"
              style={{ width: "30px", height: "30px" }}
            />
          )}
          <p> {item.name}</p>
        </div>
      </>
    );
  };
  // useEffect(() => {
  //   window.location.reload(true);
  // }, [productSelectedValue]);
  const productOnChange = (newValue) => {
    if (newValue) {
      setProductSelectedValue(newValue);
      // dispatch(
      //   setCategoryDropdownValue({
      //     state: {
      //       id: newValue?.categoryId,
      //       brandId: newValue?.brandId,
      //       subCatId: newValue?.SubCategory?.id,
      //       categoryName: newValue?.metadata?.category,
      //       subCatagoryName: newValue?.metadata?.subCategory,
      //       brandName: newValue?.metadata?.brand,
      //     },`
      //   })
      // );

      navigate(`/catalogue/${newValue?.slug}`);
    }
  };
  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      // productLoadOptions("enterPressed");
      const searchQuery = e.target.value;

      const slug = () => {
        return searchQuery.trim().split(" ").join("-");
      };
      // console.log(searchQuery.trim().split(" "), "hhh");
      navigate(`/catalogue?search=${slug()}`);
    }
  };

  return (
    <>
      <div className="navigation__block">
        <MainCategoriesDropdown />
        {isSmallScreen ? (
          <>
            <div className="navigation__block__search--block">
              <CiSearch className="navigation__block__search--icon" />
              <div className="Search__Container">
                <AsyncPaginate
                  loadOptions={productLoadOptions}
                  isSearchable
                  isClearable
                  // value={productSelectedValue}
                  onKeyDown={(e) => handleSearch(e)}
                  placeholder={"Search"}
                  components={{ Control, PlaceHolder }}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    placeholder: (base) => ({
                      ...base,
                      fontSize: "16px",
                      color: "#667085",
                      fontWeight: 400,
                      // fontFamily:
                      //   "inter_regular,fallback-inter_regular,Arial,sans-serif",
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "8px",
                      borderColor: state.isFocused ? "#D71513" : "#D0D5DD",
                      zIndex: "1000",
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      overflow: "visible",
                    }),

                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "10px 20px",
                      color: state.isSelected ? "#425EBE" : "#000",
                      fontSize: "14px",
                      backgroundColor: state.isSelected ? "#FFF3F3" : "white",
                      borderBottom: "1px solid #EDEDED",
                      zIndex: "1000",
                    }),
                  }}
                />
              </div>
            </div>
            <div style={{ zIndex: "1000" }}>
              <RiMenu2Fill
                onClick={toggleDrawer}
                className="bannerType-menu-icon"
              />
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="right"
                className="navigation__drawer__block__navbar"
              >
                <CiCircleRemove
                  onClick={toggleDrawer}
                  className="bannerType-menu-icon"
                />
                <Nav className="navigation__block__navbar">
                  <NavItem
                    // className={
                    //   location?.pathname?.split("/promotion/")[1] ===
                    //   "new-arrival"
                    //     ? "promo-nav-active"
                    //     : ""
                    // }
                    onClick={() => {
                      dispatch(
                        setCategoryDropdownValue({
                          state: {
                            categoryName: "New Arrival",
                            isPromo: true,
                            promoFilter: "isNewArrival",
                            bannerThumbType: "NewArrival",
                          },
                        })
                      );
                      navigate("/promotion/new-arrival", {
                        state: {
                          categoryName: "New Arrival",
                          isPromo: true,
                          promoFilter: "isNewArrival",
                          bannerThumbType: "NewArrival",
                        },
                      });
                    }}
                  >
                    New Arrival
                  </NavItem>
                  <NavItem
                    // className={
                    //   location?.pathname?.split("/promotion/")[1] === "trending"
                    //     ? "promo-nav-active"
                    //     : ""
                    // }
                    onClick={() => {
                      dispatch(
                        setCategoryDropdownValue({
                          state: {
                            categoryName: "Trending",
                            isPromo: true,
                            promoFilter: "isTrending",
                            bannerThumbType: "Trending",
                          },
                        })
                      );
                      navigate("/promotion/trending", {
                        state: {
                          categoryName: "Trending",
                          isPromo: true,
                          promoFilter: "isTrending",
                          bannerThumbType: "Trending",
                        },
                      });
                    }}
                  >
                    Trending
                  </NavItem>
                  <NavItem
                    // className={
                    //   location?.pathname?.split("/promotion/")[1] ===
                    //   "best-selling"
                    //     ? "promo-nav-active"
                    //     : ""
                    // }
                    onClick={() => {
                      dispatch(
                        setCategoryDropdownValue({
                          state: {
                            categoryName: "Best Selling",
                            isPromo: true,
                            promoFilter: "isBestSelling",
                            bannerThumbType: "Best_Selling",
                          },
                        })
                      );
                      navigate("/promotion/best-selling", {
                        state: {
                          categoryName: "Best Selling",
                          isPromo: true,
                          promoFilter: "isBestSelling",
                          bannerThumbType: "Best_Selling",
                        },
                      });
                    }}
                  >
                    Best Selling
                  </NavItem>
                  <NavItem
                    // className={
                    //   location?.pathname?.split("/promotion/")[1] ===
                    //   "super-saver"
                    //     ? "promo-nav-active"
                    //     : ""
                    // }
                    onClick={() => {
                      dispatch(
                        setCategoryDropdownValue({
                          state: {
                            categoryName: "Super Saver",
                            isPromo: true,
                            promoFilter: "isSuperSaver",
                            bannerThumbType: "Super_Saver",
                          },
                        })
                      );
                      navigate("/promotion/super-saver", {
                        state: {
                          categoryName: "Super Saver",
                          isPromo: true,
                          promoFilter: "isSuperSaver",
                          bannerThumbType: "Super_Saver",
                        },
                      });
                    }}
                  >
                    Super Saver
                  </NavItem>
                  {/* <NavItem className="redumption__btn">
                    <img alt='logo' src={pcoin}  />
                    <div className="text9Cat">Redemption Corner</div>
                </NavItem> */}
                  {isLoggdin && (
                    <div className="Available__Coin__Container">
                      <img
                        src={PCoinLarge}
                        alt="pcoin"
                        style={{ width: "30px", height: "40px" }}
                      />
                      <div>
                        <p className="Available__Coin__Container__No">
                          {PcoinBalance}
                        </p>
                        <p className="Available__Coin__Container__Text">
                          Available Pcoins
                        </p>
                      </div>
                    </div>
                  )}
                </Nav>
              </Drawer>
            </div>
          </>
        ) : (
          <div className="navigation__Main__Container">
            <Nav className="navigation__block__navbar">
              <NavItem
                className={
                  location?.pathname?.split("/promotion/")[1] === "new-arrival"
                    ? "promo-nav-active"
                    : ""
                }
                onClick={() => {
                  dispatch(
                    setCategoryDropdownValue({
                      state: {
                        categoryName: "New Arrival",
                        isPromo: true,
                        promoFilter: "isNewArrival",
                        bannerThumbType: "NewArrival",
                      },
                    })
                  );
                  navigate("/promotion/new-arrival", {
                    state: {
                      categoryName: "New Arrival",
                      isPromo: true,
                      promoFilter: "isNewArrival",
                      bannerThumbType: "NewArrival",
                    },
                  });
                }}
              >
                New Arrival
              </NavItem>
              <NavItem
                className={
                  location?.pathname?.split("/promotion/")[1] === "trending"
                    ? "promo-nav-active"
                    : ""
                }
                onClick={() => {
                  dispatch(
                    setCategoryDropdownValue({
                      state: {
                        categoryName: "Trending",
                        isPromo: true,
                        promoFilter: "isTrending",
                        bannerThumbType: "Trending",
                      },
                    })
                  );
                  navigate("/promotion/trending", {
                    state: {
                      categoryName: "Trending",
                      isPromo: true,
                      promoFilter: "isTrending",
                      bannerThumbType: "Trending",
                    },
                  });
                }}
              >
                Trending
              </NavItem>
              <NavItem
                className={
                  location?.pathname?.split("/promotion/")[1] === "best-selling"
                    ? "promo-nav-active"
                    : ""
                }
                onClick={() => {
                  dispatch(
                    setCategoryDropdownValue({
                      state: {
                        categoryName: "Best Selling",
                        isPromo: true,
                        promoFilter: "isBestSelling",
                        bannerThumbType: "Best_Selling",
                      },
                    })
                  );
                  navigate("/promotion/best-selling", {
                    state: {
                      categoryName: "Best Selling",
                      isPromo: true,
                      promoFilter: "isBestSelling",
                      bannerThumbType: "Best_Selling",
                    },
                  });
                }}
              >
                Best Selling
              </NavItem>
              <NavItem
                className={
                  location?.pathname?.split("/promotion/")[1] === "super-saver"
                    ? "promo-nav-active"
                    : ""
                }
                onClick={() => {
                  dispatch(
                    setCategoryDropdownValue({
                      state: {
                        categoryName: "Super Saver",
                        isPromo: true,
                        promoFilter: "isSuperSaver",
                        bannerThumbType: "Super_Saver",
                      },
                    })
                  );
                  navigate("/promotion/super-saver", {
                    state: {
                      categoryName: "Super Saver",
                      isPromo: true,
                      promoFilter: "isSuperSaver",
                      bannerThumbType: "Super_Saver",
                    },
                  });
                }}
              >
                Super Saver
              </NavItem>
              {/* <NavItem
              className={
                location?.pathname?.split("/promotion/")[1] ===
                "redemption-corner"
                  ? "redumption__btn redumption__btn__active"
                  : "redumption__btn"
              }
              onClick={() => {
                dispatch(
                  setCategoryDropdownValue({
                    state: {
                      categoryName: "Redemption Corner",
                      isPromo: true,
                      promoFilter: "isOnRedemption",
                      bannerThumbType: "Redemption Corner",
                    },
                  })
                );
                navigate("/promotion/redemption-corner", {
                  state: {
                    categoryName: "Redemption Corner",
                    isPromo: true,
                    promoFilter: "isOnRedemption",
                    bannerThumbType: "Redemption Corner",
                  },
                });
              }}
            >
              <img alt="logo" src={pcoin} />
              <div className="text9Cat">Redemption Corner</div>
            </NavItem> */}
            </Nav>
            {isLoggdin && (
              <div className="Available__Coin__Container">
                <img
                  src={PCoinLarge}
                  alt="pcoin"
                  style={{ width: "30px", height: "40px" }}
                />
                <div>
                  <p className="Available__Coin__Container__No">
                    {PcoinBalance}
                  </p>
                  <p className="Available__Coin__Container__Text">
                    Available Pcoins
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
});

export default Navigation;
