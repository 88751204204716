import axios from "axios";

import { getToken } from "../utils/AuthService";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

/**
 * @function wishlistService
 * @param
 * @description This function is used to add items to wishlist
 */

export const addWishlistService = async ({
  productId,
  productVariationsId,
}) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(
      `${url}/wishlist/addRemove`,
      {
        productId,
        productVariationsId,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function wishlistService
 * @param
 * @description This function is used to call items to wishlist
 */

// export const wishListService = async ({
//     filter = ''
// }) => {
//     try {
//         const tokens = getToken();
//         const {tokenAccess, foodAccessToken} = tokens;
//         const result = await axios.get(`${url}/wishlist/all?filter=${filter}`,
//         {
//             headers: {
//                 Authorization: `Bearer ${tokenAccess}`,
//                 foodauthtoken: `${foodAccessToken}`
//             }
//         });
//         return result;
//     } catch (err) {
//         return err;
//     }
// }
export const wishlistService = async ({ filter = " " }) => {
  try {
    const token = getToken();
    const { tokenAccess, foodAccessToken } = token;
    const result = await axios.get(`${url}/wishlist/all?filter=${filter}`, {
      headers: {
        Authorization: `Bearer ${tokenAccess}`,
        foodauthtoken: `${foodAccessToken}`,
      },
    });
    return result;
  } catch (err) {
    return err;
  }
};

//Move to wishlist function
export const movetoWishlistService = async ({
  productId,
  productVariationsId,
}) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.patch(
      `${url}/cart/moveToWishlist?id=${productId}`,
      {
        productVariationsId,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};
