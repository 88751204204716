import { memo } from "react";
import FooterBlock from "./FooterBlock";
import { ImTwitter } from "react-icons/im";
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";

import "../../assets/scss/base/pages/homepage/FooterCard.scss";

const FooterCard = memo(() => {
  return (
    <footer className="footerFooterCard">
      <FooterBlock />
      <div className="container3FooterCard">
        <div className="content10FooterCard">
          <div className="footer-textFooterCard">
            Copyright © 2024 Pineapple Computers Sdn Bhd. All rights reserved.
          </div>
          {/* <div className="social-iconsFooterCard">
            <a href="#" className="social-iconFooterCard">
              <BsFacebook />
            </a>
            <a href="#" className="social-iconFooterCard">
              <BsInstagram />
            </a>
            <a href="#" className="social-iconFooterCard">
              <BsLinkedin />
            </a>
            <a href="#" className="social-iconFooterCard">
              <ImTwitter />
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
});

export default FooterCard;
