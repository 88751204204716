import { memo } from "react";
import { useSelector } from "react-redux";
import "../../assets/scss/base/pages/cart/EarnCoinsForm.scss";
import { pcoin } from "../../assets/images/cart";
import {
  selectCartList,
  selectSelectedCartItemsList,
} from "../../redux/cartSlice";

const EarnCoinsForm = memo(({ earningPCoin }) => {
  // const selectedCartItemsList = useSelector(selectSelectedCartItemsList);
  // const TotalEarningPCoins = () => {
  //   let total = 0;
  //   selectedCartItemsList?.forEach((item) => {
  //     total = total + item?.ProductVariation?.earningPcoins;
  //   });

  //   return total;
  // };

  return (
    <div className="frame-wrapper2">
      <div className="you-can-earn-50-p-coins-for-co-parent">
        <div className="you-can-earn">
          You can earn{" "}
          <img className="p-coin-icon2EarnCoinsForm" alt="pcoin" src={pcoin} />{" "}
          {earningPCoin} P-Coins for completing this purchase
        </div>
      </div>
    </div>
  );
});

export default EarnCoinsForm;
