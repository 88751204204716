import axios from "axios";

import { getToken } from "../utils/AuthService";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

/**
 * @function voucherListService
 * @param
 * @description This function is used to get all voucher item
 */

export const voucherListService = async ({ productIds, amount }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.get(
      `${url}/vouchers/manage/list?filter=&productIds=${productIds}&amount=${amount}`,
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};
