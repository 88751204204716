import React from "react";
import moment from "moment";

//styles
import "../../assets/scss/base/ProductTracker/ProductTracker.scss";
//images
import ProcessIcon from "../../assets/images/ProductTracker/ProcessIcon.svg";
import ConfirmedIcon from "../../assets/images/ProductTracker/ConfirmedIcon.svg";
import toProcessIcon from "../../assets/images/ProductTracker/toProcessIcon.svg";

const ProductTracker = ({ deliverystatus }) => {
  // const Status=[
  //     {
  //         icon:"",
  //         text:"Confirmed",
  //         date:"Apr, 15",

  //     }
  // ]
  return (
    <div>
      <div className="ProductTracker__Inner__Cont">
        <p>
          <div className="ProductTracker__InnerInner__Cont">
            {deliverystatus?.confirmed?.done ? (
              <img
                src={ConfirmedIcon}
                alt="Confirmed-Icon"
                className="Confirmed__Icon"
              />
            ) : (
              <img src={ProcessIcon} alt="Process-icon" />
            )}

            <span className={"active__State"}>
              Order Confirmed{" "}
              {deliverystatus?.confirmed?.done &&
                moment
                  .unix(deliverystatus?.confirmed?.date)
                  .format("MMM, Do YYYY")}
            </span>
          </div>
        </p>
        <p>
          <hr
            className={
              deliverystatus?.confirmed?.done
                ? "active__Line"
                : "Progress__Tracker__Line"
            }
          />
          <div className="ProductTrackker__IconText">
            {deliverystatus?.shippedOut?.done ? (
              <img
                src={ConfirmedIcon}
                alt="Confirmed-Icon"
                className="Confirmed__Icon"
              />
            ) : deliverystatus?.confirmed?.done ? (
              <img
                src={ProcessIcon}
                alt="Process-icon"
                className="Confirmed__Icon"
              />
            ) : (
              <img
                src={toProcessIcon}
                alt="Process-icon"
                className="toProcessIcon"
              />
            )}
            <span
              className={
                deliverystatus?.shippedOut?.done
                  ? "active__State"
                  : "Product__Tracker__Stage"
              }
            >
              Shipped out by pineapple{" "}
              {deliverystatus?.shippedOut?.done &&
                moment
                  .unix(deliverystatus?.shippedOut?.date)
                  .format("MMM, Do YYYY")}
            </span>
          </div>
        </p>
        <p>
          <div className="ProductTracker__InnerInner__Cont">
            <hr className="Progress__Tracker__Line" />
            <div className="ProductTrackker__IconText">
              {deliverystatus?.onTheWay?.done ? (
                <img
                  src={ConfirmedIcon}
                  alt="Confirmed-Icon"
                  className="Confirmed__Icon"
                />
              ) : deliverystatus?.shippedOut?.done ? (
                <img
                  src={ProcessIcon}
                  alt="Process-icon"
                  className="Confirmed__Icon"
                />
              ) : (
                <img
                  src={toProcessIcon}
                  alt="Process-icon"
                  className="toProcessIcon"
                />
              )}
              <span
                className={
                  deliverystatus?.onTheWay?.done
                    ? "active__State"
                    : "Product__Tracker__Stage"
                }
              >
                On the way to you{" "}
                {deliverystatus?.onTheWay?.done &&
                  moment
                    .unix(deliverystatus?.onTheWay?.date)
                    .format("MMM, Do YYYY")}
              </span>
            </div>
          </div>
        </p>
        <p>
          <div className="ProductTracker__InnerInner__Cont">
            <hr className="Progress__Tracker__Line" />
            <div className="ProductTrackker__IconText">
              {deliverystatus?.delivered?.done ? (
                <img
                  src={ConfirmedIcon}
                  alt="Confirmed-Icon"
                  className="Confirmed__Icon"
                />
              ) : deliverystatus?.onTheWay?.done ? (
                <img
                  src={ProcessIcon}
                  alt="Process-icon"
                  className="Confirmed__Icon"
                />
              ) : (
                <img
                  src={toProcessIcon}
                  alt="Process-icon"
                  className="toProcessIcon"
                />
              )}
              <span
                className={
                  deliverystatus?.delivered?.done
                    ? "active__State"
                    : "Product__Tracker__Stage"
                }
              >
                Delivered by{" "}
                {deliverystatus?.delivered?.done &&
                  moment
                    .unix(deliverystatus?.delivered?.date)
                    .format("MMM, Do YYYY")}
              </span>
            </div>
          </div>
        </p>
      </div>
    </div>
  );
};

export default ProductTracker;
