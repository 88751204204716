import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

// Redux-Settings
import { setLogin } from "../../redux/authSlice";
import { phoneIcon } from "../../assets/images/authentication";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { selectTemporaryPhoneNumber } from "../../redux/utilitySlice";
// Styles
import "../../assets/scss/base/pages/index.scss";
import "../../assets/scss/base/pages/authentication/PhoneNumberVerification.scss";

// api service
import { OTPValidation, resendOTP } from "../../service/auth.service";
// Utility-Service
import { getToken } from "../../utils/AuthService";

import { setUserSession } from "../../utils/AuthService";
import { setUserDetail } from "../../redux/userSlice";

const OtpVerificationForResetPassword = () => {
  const { state } = useLocation();
  const token = getToken();
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const disptach = useDispatch();
  const navigate = useNavigate();
  const phone = useSelector(selectTemporaryPhoneNumber);

  // Time calculation
  const [lastRequestTime, setLastRequestTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [otpError, setOtpError] = useState("");
  const [otpSent, setOTPSent] = useState(false);
  const [attempt, setAttempt] = useState(3);

  const [formData, setFormData] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const [wrongOtp, setWrongOtp] = useState(false);
  const [expiredOtp, setExpiredOtp] = useState(false);

  const otpCode = `${formData.first}${formData.second}${formData.third}${formData.fourth}`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await OTPValidation({
      otpType: state?.type,
      otp: otpCode,
    });

    if (result?.data?.success) {
      const user = result?.data?.data?.user;
      setUserSession(token, user);
      // disptach(setUserDetail(user));
      // disptach(setLogin(true));
      navigate("/resetpassword");
    } else if (
      !result?.data?.success &&
      result?.data?.errorMessage === "Incorrect otp"
    ) {
      setFormData({
        first: "",
        second: "",
        third: "",
        fourth: "",
      });
      setAttempt(attempt - 1);

      setWrongOtp(true);
    } else if (
      !result?.data?.success &&
      result?.data?.errorMessage === "OTP expired"
    ) {
      setFormData({
        first: "",
        second: "",
        third: "",
        fourth: "",
      });
      setExpiredOtp(true);
    }
  };

  // Calculate the time elapsed since the last OTP request
  const calculateElapsedTime = () => {
    if (!lastRequestTime) return 0;
    const currentTime = new Date().getTime();
    return (currentTime - lastRequestTime) / 1000; // Convert to seconds
  };

  const startCountdown = () => {
    let timeLeft = 60 - Math.floor(calculateElapsedTime());
    setRemainingTime(timeLeft);

    const interval = setInterval(() => {
      timeLeft -= 1;
      if (timeLeft >= 0) {
        setRemainingTime(timeLeft);
        setOtpError(
          `Please wait for ${timeLeft} seconds before resending OTP.`
        );
      } else {
        clearInterval(interval);
        setOtpError("");
      }
    }, 3000); // Update every second
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    const timeLeft = Math.floor(calculateElapsedTime());
    if (timeLeft < 60) {
      startCountdown();
      return false;
    } else {
      // Perform OTP resend action here (setOTPSent(true) or make API request)
      // Update lastRequestTime with the current time
      const result = await resendOTP({
        otpType: state?.type,
      });

      if (result?.data?.success) {
        setFormData({
          first: "",
          second: "",
          third: "",
          fourth: "",
        });
        setLastRequestTime(new Date().getTime());
        setOtpError("");
        setOTPSent(true); // Set to true after OTP has been successfully resent
        setWrongOtp(false);

        setAttempt(3);
      }
    }
  };
  useEffect(() => {
    setLastRequestTime(new Date().getTime());
  }, []);

  return (
    <div className="phone-number-verification">
      <div className="phoneNumberVerificationContent">
        <div className="header1">
          <img className="featured-icon" alt="phone" src={phoneIcon} />
          <div className="text-and-supporting-text">
            <div className="text11">Check your Phone/E-mail</div>
            <div className="supporting-text">
              <p className="we-sent-a">{`We sent a verification code  to`}</p>
              <p className="p">
                {!isNaN(state?.phoneNumber) && "+6"}
                {state?.phoneNumber}
              </p>
            </div>
          </div>
        </div>
        <form>
          <div className="content4">
            <div className="verification-code-input-field">
              <div className="verification-code-input-field">
                <div className="input6">
                  <input
                    className="mega-input-field-base"
                    type="number"
                    min={0}
                    max={9}
                    ref={inputRefs[0]}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setWrongOtp(false);
                      setExpiredOtp(false);
                      if (inputValue.length > 1) {
                        e.target.value = inputValue.slice(0, 1); // Only keep the first digit
                      }
                      inputRefs[1].current.focus();
                      setFormData({ ...formData, first: e.target.value });
                    }}
                    value={formData?.first}
                    placeholder="-"
                    required
                  />
                  <input
                    className="mega-input-field-base"
                    type="number"
                    min={0}
                    max={9}
                    ref={inputRefs[1]}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setWrongOtp(false);
                      setExpiredOtp(false);
                      if (inputValue.length > 1) {
                        e.target.value = inputValue.slice(0, 1); // Only keep the first digit
                      }
                      inputRefs[1 + 1].current.focus();
                      setFormData({ ...formData, second: e.target.value });
                    }}
                    value={formData?.second}
                    placeholder="-"
                    required
                  />
                  <input
                    className="mega-input-field-base"
                    type="number"
                    min={0}
                    max={9}
                    ref={inputRefs[2]}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setWrongOtp(false);
                      setExpiredOtp(false);
                      if (inputValue.length > 1) {
                        e.target.value = inputValue.slice(0, 1); // Only keep the first digit
                      }
                      inputRefs[2 + 1].current.focus();
                      setFormData({ ...formData, third: e.target.value });
                    }}
                    value={formData?.third}
                    placeholder="-"
                    required
                  />
                  <input
                    className="mega-input-field-base"
                    type="number"
                    min={0}
                    max={9}
                    ref={inputRefs[3]}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setWrongOtp(false);
                      setExpiredOtp(false);
                      if (inputValue.length > 1) {
                        e.target.value = inputValue.slice(0, 1); // Only keep the first digit
                      }
                      setFormData({ ...formData, fourth: e.target.value });
                    }}
                    value={formData?.fourth}
                    placeholder="-"
                    required
                  />
                </div>
              </div>
              {wrongOtp && <p className="hintpass">Wrong Otp, Try again</p>}
              {expiredOtp && (
                <p className="hintpass">Otp expired, Get new otp</p>
              )}
              {otpError && <p className="hintpass">{otpError}</p>}
            </div>
            <button
              onClick={(e) => handleSubmit(e)}
              className={attempt > 0 ? "buttons3" : "disabling"}
            >
              <div className="text13">{state?.btnText}</div>
            </button>
          </div>
        </form>
        <div className="row1">
          <div className="didnt-receive-the">Didn’t receive the Code?</div>
          <button onClick={handleResendOTP} className="buttons4Verification">
            <div className="text14">Click to resend</div>
          </button>
        </div>
        <button className="buttons5">
          <AiOutlineArrowLeft className="backarrow" />
          <div onClick={() => navigate("/login")} className="text15">
            Back to log in
          </div>
        </button>
      </div>
    </div>
  );
};

export default OtpVerificationForResetPassword;
