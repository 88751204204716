import React, {useState} from 'react';
import {useLocation } from "react-router-dom"
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Redux-Settings
 import { 
  selectIsOrderPlcedSuccess,
  setIsOrderPlcedSuccess
 } from '../../redux/cartSlice';

// Images
import PlaceOrderSuccessThumb from "../../assets/images/cart/successfully-placed-order.png"
import PaymentFailedModal from './PaymentFailedModal';


function OrderSuccessModal() {
  const isOrderPlcedSuccessModal = useSelector(selectIsOrderPlcedSuccess)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location=useLocation();
    const OrderSuccessModalToggle = () => {
      dispatch(setIsOrderPlcedSuccess(!isOrderPlcedSuccessModal))
    }

    const closeBtn = (
        <button className="close" onClick={OrderSuccessModalToggle} type="button">
          &times;
        </button>
    );
    const query = new URLSearchParams(location?.search);
    const status=query.get('status')
    const ErrDesc=query.get('ErrDesc')
  return (
    <div>
      {
        status =="1" ?

      <Modal 
      className='orderPlace__success__Modal addrees__modal' 
      isOpen={true} 
      toggle={OrderSuccessModalToggle} 
      fullscreen
      backdropClassName='light__modal__background'
      >
        {/* <ModalHeader toggle={OrderSuccessModalToggle} close={closeBtn} />  */}
         <ModalBody> 
            <img src={PlaceOrderSuccessThumb} alt="" />
            <div className='orderPlace__success__Modal__content'>
              <h3>Your Order is Confirmed!</h3>
              <p>Thanks for your order.</p>
              <button onClick={() => navigate('/')}>
                Home
              </button>
            </div>
         </ModalBody> 
       
       </Modal> 
       :
       <PaymentFailedModal ErrDesc={ErrDesc}/>
       
      }
    </div>
  );
}



export default OrderSuccessModal;