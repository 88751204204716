import OrderingStepAddress from "../../components/cart/OrderingStepAddress";
import DeliveryAddressListing from "../../components/cart/DeliveryAddressListing";
import EarnPointsBillForm from "../../components/cart/EarnPointsBillForm";
import FooterCard from "../../components/homepage/FooterCard";
import '../../assets/scss/base/pages/cart/Address.scss';
import Header from "../../components/homepage/Header";
import CategoriesTab from '../../components/catalogue/CategoriesTab';


const Address = () => {
  return (
    <div className="addressAdressMain">
      <Header />
     <div className="divider" />
      <CategoriesTab />
      <div className="divider" />
      <OrderingStepAddress />
      <section className="address-innerAddress">
        <div className="frame-parentAddress">
          <DeliveryAddressListing />
          <EarnPointsBillForm />
        </div>
      </section>
      <FooterCard />
    </div>
  );
};

export default Address;
