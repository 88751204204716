import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import ReactStars from "react-rating-stars-component";
import { AiFillStar } from "react-icons/ai";

import { MdClear } from "react-icons/md";

//componenets
import OrderedItem from "../../SingleOnGoingOrder/OrderedItem";
//services
import { giveRatingReviewService } from "../../../service/reviewRating.service";
//images
import check from "../../../assets/images/account/Order/check.png";

//

import "../../../assets/scss/base/pages/account/Order/Modal/RateReviewModal.scss";
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const RateReviewModal = ({
  isOpen,
  setOpenModal,
  orderedItem,
  setIsOpenSuccessModal,
  selectedProduct,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState();
  const handleAddRatingReview = async () => {
    setIsLoading(true);
    const response = await giveRatingReviewService({
      rating: rating,
      review: review,
      id: Number(orderedItem?.OrderItems[0]?.id),
    });
    setIsOpenSuccessModal(response?.data?.data?.message);
    setOpenModal(false);
    setIsLoading(false);
  };
  const ratingChanged = (rate) => {
    setRating(rate);
  };
  const handleTextAreaValue = (text) => {
    setReview(text);
  };
  return (
    <div>
      <Modal
        isOpen={isOpen}
        className="Modal__Main"
        backdropClassName="light__modal__background"
      >
        <ModalBody>
          <div className="Modal__Body__Cont">
            <h5>Ratings & Reviews</h5>
            <MdClear
              className="Cross__Icon"
              onClick={() => setOpenModal(false)}
            />
          </div>
          <img
            src={`${imageCDN}/${selectedProduct?.Product?.thumbnail}`}
            alt="Banner"
            style={{ width: "150px" }}
          />
          <h5>{selectedProduct?.Product?.name}</h5>
          <div className="Modal__midContainer">
            <h6>Give your rating</h6>
            <ReactStars
              count={5}
              value={rating}
              onChange={ratingChanged}
              size={24}
              emptyIcon={<AiFillStar />}
              halfIcon={<AiFillStar />}
              fullIcon={<AiFillStar />}
              activeColor="#168952"
            />
          </div>
          <div className="Modal__lastContainer">
            <h6>Enter reviews for this product</h6>
            <p className="Modal__Header2">Review</p>
            <textarea
              type="text"
              placeholder="Enter review here"
              className="Modal__TextPlace"
              onChange={(e) => handleTextAreaValue(e.target.value)}
            />
          </div>
          <button className="Modal__Btn" onClick={handleAddRatingReview}>
            {isLoading ? "Loading..." : "Submit"}
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RateReviewModal;
