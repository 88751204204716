// cartListSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartList: [],
  filteredList: [],
  selectedCartItemsList: [],
  selectedCartItemsId: [],
  totalProductDiscount: 0,
  totalAmount: 0,
  subtotalAmount: 0,
  discountAmount: 0,
  pCoinAmount: 0,
  pageCount: 0,
  totalItem: 0,
  totalRedeemablePcoinsOnPrice: 0,
  isAppliedVoucher: false,
  voucherContent: "",
  voucherDiscountAmount: 0,
  isAppliedCoupon: false,
  couponDiscountAmount: 0,
  couponContent: "",
  isAppliedPCoin: false,
  pCoinDiscountAmount: 0,
  cartPageTabIndex: 0,
  redeemedpCoin: 0,
  shipingCost: 0,
  isSelfPickup: false,
  weight: 0,
  deepLink: null,
  isOrderPlcedSuccess: false,
  isPaymentFailModal: false,
  paymentMethod: "1",
  couponType: "",
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartList: (state, action) => {
      state.cartList = action.payload;
    },
    setSelectedCartItemsList: (state, action) => {
      state.selectedCartItemsList = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setTotalItem: (state, action) => {
      state.totalItem = action.payload;
    },
    setTotalProductDiscount: (state, action) => {
      state.totalProductDiscount = action.payload;
    },
    setTotalPcoinsBasedOnPrice: (state, action) => {
      state.totalRedeemablePcoinsOnPrice = action.payload;
    },
    setTotalAmount: (state, action) => {
      state.totalAmount = action.payload;
    },
    setSubtotalAmount: (state, action) => {
      state.subtotalAmount = action.payload;
    },
    setDiscountAmount: (state, action) => {
      state.discountAmount = action.payload;
    },
    setPCoinAmount: (state, action) => {
      state.pCoinAmount = action.payload;
    },
    setSelectedCartItemsId: (state, action) => {
      state.selectedCartItemsId = action.payload;
    },
    setIsAppliedVoucher: (state, action) => {
      state.isAppliedVoucher = action.payload;
    },
    setVoucherContent: (state, action) => {
      state.voucherContent = action.payload;
    },
    setVoucherDiscountAmount: (state, action) => {
      state.voucherDiscountAmount = action.payload;
    },
    setpCoinDiscountAmount: (state, action) => {
      state.pCoinDiscountAmount = action.payload;
    },
    setRedeemedpCoin: (state, action) => {
      state.redeemedpCoin = action.payload;
    },
    setIsAppliedCoupon: (state, action) => {
      state.isAppliedCoupon = action.payload;
    },
    setCouponType: (state, action) => {
      state.couponType = action.payload;
    },
    setCouponContent: (state, action) => {
      state.couponContent = action.payload;
    },
    setCouponDiscountAmount: (state, action) => {
      state.couponDiscountAmount = action.payload;
    },
    setIsAppliedPCoin: (state, action) => {
      state.isAppliedPCoin = action.payload;
    },
    setCartPageTabIndex: (state, action) => {
      state.cartPageTabIndex = action.payload;
    },
    setWeight: (state, action) => {
      state.weight = action.payload;
    },
    setShipingCost: (state, action) => {
      state.shipingCost = action.payload;
    },
    setIsOrderPlcedSuccess: (state, action) => {
      state.isOrderPlcedSuccess = action.payload;
    },
    setIsPaymentModalFailure: (state, action) => {
      state.isPaymentFailModal = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },

    setDeepLink: (state, action) => {
      state.deepLink = action.payload;
    },

    setSelfPickup: (state, action) => {
      state.isSelfPickup = action.payload;
    },
  },
});

export const {
  setCartList,
  setFilteredList,
  setPageCount,
  setTotalItem,
  setSelectedCartItemsList,
  setTotalProductDiscount,
  setTotalAmount,
  setSubtotalAmount,
  setDiscountAmount,
  setPCoinAmount,
  setSelectedCartItemsId,
  setIsAppliedVoucher,
  setIsAppliedCoupon,
  setCouponType,
  setIsAppliedPCoin,
  setVoucherDiscountAmount,
  setVoucherContent,
  setCartPageTabIndex,
  setpCoinDiscountAmount,
  setRedeemedpCoin,
  setCouponContent,
  setCouponDiscountAmount,
  setShipingCost,
  setWeight,
  setPaymentMethod,
  setIsOrderPlcedSuccess,
  setIsPaymentModalFailure,
  setDeepLink,
  setTotalPcoinsBasedOnPrice,
  setSelfPickup,
} = cartSlice.actions;

export const selectCartList = (state) => state.cart.cartList;
export const selectSelectedCartItemsList = (state) =>
  state.cart.selectedCartItemsList;
export const selectFiltered = (state) => state.cart.filteredList;
export const selectPageCount = (state) => state.cart.pageCount;
export const selectTotalItem = (state) => state.cart.totalItem;
export const selectTotalProductDiscount = (state) =>
  state.cart.totalProductDiscount;
export const selecttotalAmount = (state) => state.cart.totalAmount;

export const selectSubtotalAmount = (state) => state.cart.subtotalAmount;
export const selectDiscountAmount = (state) => state.cart.discountAmount;
export const selectTotalPcoinsBasedOnPrice = (state) =>
  state.cart.totalRedeemablePcoinsOnPrice;
export const selectPCoinAmount = (state) => state.cart.pCoinAm;
export const selectSelectedCartItemsId = (state) =>
  state.cart.selectedCartItemsId;
export const selectIsAppliedVoucher = (state) => state.cart.isAppliedVoucher;
export const selectIsAppliedCoupon = (state) => state.cart.isAppliedCoupon;
export const selectCouponType = (state) => state.cart.couponType;
export const selectIsAppliedPCoin = (state) => state.cart.isAppliedPCoin;
export const selectVoucherDiscountAmount = (state) =>
  state.cart.voucherDiscountAmount;
export const selectVoucherContent = (state) => state.cart.voucherContent;
export const selectCartPageTabIndex = (state) => state.cart.cartPageTabIndex;
export const selectpCoinDiscountAmount = (state) =>
  state.cart.pCoinDiscountAmount;
export const selectReedemedpCoin = (state) => state.cart.redeemedpCoin;
export const selectCouponContent = (state) => state.cart.couponContent;
export const selectCouponDiscountAmount = (state) =>
  state.cart.couponDiscountAmount;
export const selectShipingCost = (state) => state.cart.shipingCost;
export const selectWeight = (state) => state.cart.weight;
export const selectPaymentMethod = (state) => state.cart.paymentMethod;
export const selectIsOrderPlcedSuccess = (state) =>
  state.cart.isOrderPlcedSuccess;
export const selectIsPaymentFailure = (state) => state.cart.isPaymentFailModal;
export const selectDeepLink = (state) => state.cart.deepLink;

export const selectSelfPickup = (state) => state.cart.isSelfPickup;

export default cartSlice.reducer;
