import OrderingSteps from "../../components/cart/OrderingSteps";
import BillContainer from "../../components/cart/BillContainer";

import { empty_cart } from "../../assets/images/cart";
import FooterCard from "../../components/homepage/FooterCard";
import Header from "../../components/homepage/Header";
import CategoriesTab from '../../components/catalogue/CategoriesTab';

// Styles
import "../../assets/scss/base/pages/cart/Cart.scss";
import { Helmet } from "react-helmet";

const Cart = () => {

  return (
    <div className="cartCartMain">
      <Helmet>
                <meta charSet="utf-8" />
                <title >Shopping Cart</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <Header />
      <CategoriesTab />
      <div className="divider" />
      <OrderingSteps />
      <section className="cart-innerCartMain">
        <div className="frame-parentCartMain">
          <div className="frame-groupCartMain">
            <div className="group-parentCartMain">
              <img className="frame-childCartMain" alt="empty-cart-icon" src={empty_cart} />
              <div className="your-cart-is-empty-parentCartMain">
                <div className="your-cart-isCartMain">Your Cart is Empty!</div>
                <div className="add-items-toCartMain">Add items to your cart</div>
              </div>
            </div>
            <button className="add-items-wrapperCartMain">
              <div className="add-itemsCartMain">+ Add Items</div>
            </button>
          </div>
          <BillContainer />
        </div>
      </section>
      <FooterCard />
    </div>
  );
};

export default Cart;
