import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { BsArrowLeft } from "react-icons/bs";
// API-Service
import {
  addressMakeDefaultService,
  addressListService,
} from "../../../service/address.service";
// Redux-Settings
import {
  setShippingAddressList,
  setDefaultShippingAddressId,
  selectShippingAddressList,
  selectIsAsDefaultAsShippingAddress,
} from "../../../redux/addressSlice";

// Components
import AddAddressModal from "./AddAddressModal";
import UpdateAddressModal from "./UpdateAddressModal";
import RemoveAddressModal from "./RemoveAddressModal";

function ShippingAddressListingModal({
  shippingAddressListingModaltoggle,
  isOpenShippingAddressListingModal,
  setAddAddressClicked,
  setUpdateAddressClicked,
}) {
  const shippingAddressList = useSelector(selectShippingAddressList);
  const asDefaultasShippingAddress = useSelector(
    selectIsAsDefaultAsShippingAddress
  );
  // Add Modal-Controll
  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggle = () => setIsOpenModal(!isOpenModal);

  // Update Modal-Controll
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const toggleUpdate = () => setIsUpdateOpenModal(!isUpdateOpenModal);
  const dispatch = useDispatch();

  // Remove Modal-Controll
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const toggleRemoveModal = () => setIsOpenRemoveModal(!isOpenRemoveModal);

  // Set-Form-value
  const [formValue, setFormValue] = useState({});
  const [addressType, setAddressType] = useState("");

  //  Selected Address Settings
  const [selectedShippingAddress, setSelectedShippingAddress] = useState("");

  const closeBtn = (
    <button
      className="close"
      onClick={shippingAddressListingModaltoggle}
      type="button"
    >
      &times;
    </button>
  );

  const AddBackBtn = (
    <button
      className="close"
      onClick={() => setIsOpenModal(!isOpenModal)}
      type="button"
    >
      <BsArrowLeft />
    </button>
  );
  const EditBackBtn = (
    <button
      className="close"
      onClick={() => setIsUpdateOpenModal(!isUpdateOpenModal)}
      type="button"
    >
      <BsArrowLeft />
    </button>
  );
  const RemoveBackBtn = (
    <button
      className="close"
      onClick={() => setIsOpenRemoveModal(!isOpenRemoveModal)}
      type="button"
    >
      <BsArrowLeft />
    </button>
  );

  useEffect(() => {
    setSelectedShippingAddress(
      shippingAddressList?.find((item) => item?.isDefault === true)?.id
    );
  }, [shippingAddressList]);

  const fetchShippingAddressList = async () => {
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Shipping",
    });
    if (response?.data?.success) {
      dispatch(setShippingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultShippingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  };

  const handleMakeDefaultShippingAddress = async (id) => {
    const response = await addressMakeDefaultService({
      id,
      type: "Shipping",
    });
    if (response?.data?.success) {
      fetchShippingAddressList();
    }
  };

  return (
    <Modal
      modalClassName="left__modal"
      className="addrees__modal big__modal"
      backdropClassName="light__modal__background"
      isOpen={isOpenShippingAddressListingModal}
      toggle={shippingAddressListingModaltoggle}
      fullscreen
    >
      {!isUpdateOpenModal && !isOpenRemoveModal && !isOpenModal && (
        <ModalHeader
          toggle={shippingAddressListingModaltoggle}
          close={closeBtn}
        >
          Select Address
        </ModalHeader>
      )}
      {isOpenModal && (
        <ModalHeader
          toggle={shippingAddressListingModaltoggle}
          close={AddBackBtn}
        >
          Add Address
        </ModalHeader>
      )}

      {isUpdateOpenModal && (
        <ModalHeader
          toggle={shippingAddressListingModaltoggle}
          close={EditBackBtn}
        >
          Update Address
        </ModalHeader>
      )}

      {isOpenRemoveModal && (
        <ModalHeader
          toggle={shippingAddressListingModaltoggle}
          close={RemoveBackBtn}
        >
          Remove Address
        </ModalHeader>
      )}

      {/* <ModalHeader toggle={shippingAddressListingModaltoggle} close={closeBtn}>
          {!isUpdateOpenModal && !isOpenRemoveModal && !isOpenModal && "Select Address"}
          {isOpenModal && "Add Address"}
          {isUpdateOpenModal && "Update Address"}
          {isOpenRemoveModal && "Remove Address"}
        </ModalHeader> */}

      <ModalBody>
        {!isUpdateOpenModal && !isOpenRemoveModal && !isOpenModal && (
          <div className="address__block">
            <div className="address__title__block">
              <h3>Shipping Address</h3>
              <button
                onClick={() => {
                  toggle();
                  setAddressType("Shipping");
                }}
              >
                + Add Address
              </button>
            </div>
            <div className="address__item__block">
              {shippingAddressList?.length > 0 ? (
                <>
                  {shippingAddressList?.map((address) => {
                    return (
                      <div className="frame-parent6DeliveryAddressListing">
                        <div className="frame-wrapperDeliveryAddressListing">
                          <FormGroup check>
                            <Input
                              type="radio"
                              value={address.id}
                              checked={selectedShippingAddress === address.id}
                              onChange={() => {
                                setSelectedShippingAddress(address.id);
                                setTimeout(() => {
                                  handleMakeDefaultShippingAddress(address.id);
                                }, 2000);
                              }}
                              className="frame-inputDeliveryAddressListing"
                            />
                          </FormGroup>
                        </div>
                        <div className="sinan-afthar-parentDeliveryAddressListing">
                          <div className="sinan-aftharDeliveryAddressListing">
                            {address?.receiptient}
                          </div>
                          <div className="jalan-tun-sambanthan-containerDeliveryAddressListing">
                          <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.company}
                            </p>
                          <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.tin}
                            </p>
                            <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.address}
                            </p>
                            <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.city}
                            </p>
                            <p className="jalan-tun-sambanthanDeliveryAddressListing">
                              {address?.state}, {address?.zipCode} -{" "}
                              {address?.country}
                            </p>
                          
                          </div>
                          <div className="jalan-tun-sambanthan-containerDeliveryAddressListing">
                            <span>{`Mobile : `}</span>
                            <span className="spanDeliveryAddressListing">
                              {address?.mobile}
                            </span>
                          </div>
                          <div className="frame-parent7DeliveryAddressListing">
                            <button
                              onClick={() => {
                                setFormValue(address);
                                toggleRemoveModal();
                                setAddressType("Shipping");
                              }}
                              className="remove-wrapperDeliveryAddressListing"
                            >
                              <div className="removeDeliveryAddressListing">
                                Remove
                              </div>
                            </button>
                            <button
                              onClick={() => {
                                setFormValue(address);
                                toggleUpdate();
                                setAddressType("Shipping");
                              }}
                              className="remove-wrapperDeliveryAddressListing"
                            >
                              <div className="editDeliveryAddressListing">
                                Edit
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="address__empty__block">
                  <h3>There is no default shipping address found!</h3>
                </div>
              )}
            </div>
            <button
              onClick={() => {
                toggle();
                setAddressType("Shipping");
              }}
              className="add-new-address-wrapperDeliveryAddressListing"
            >
              <div className="add-new-addressDeliveryAddressListing">
                + Add New Address
              </div>
            </button>
          </div>
        )}
        {isOpenModal && (
          <AddAddressModal
            toggle={toggle}
            addressType={addressType}
            isOpenModal={isOpenModal}
            setAddAddressClicked={setAddAddressClicked}
          />
        )}
        {isUpdateOpenModal && (
          <UpdateAddressModal
            toggleUpdate={toggleUpdate}
            addressType={addressType}
            isUpdateOpenModal={isUpdateOpenModal}
            formValue={formValue}
            setUpdateAddressClicked={setUpdateAddressClicked}
          />
        )}
        {isOpenRemoveModal && (
          <RemoveAddressModal
            toggleRemoveModal={toggleRemoveModal}
            addressType={addressType}
            isOpenRemoveModal={isOpenRemoveModal}
            formValue={formValue}
          />
        )}
      </ModalBody>
    </Modal>
  );
}

export default ShippingAddressListingModal;
