import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AccountLinksTab from "../../../components/account/AccountLinksTab";
import { getUserProfile } from "../../../service/user.service";
import withLayout from "../../../components/higherOrderComponent/PagesLayout/PagesLayout";
import Orders from "../Orders";
import Header from "../../../components/homepage/Header";
import Navigation from "../../../components/homepage/Navigation/Navigation";
import FooterBlock from "../../../components/homepage/FooterBlock";
import AddressBook from "../AddressBook";
import Order from "../../../components/order/Order";
import Loader from "../../../components/micro/loader/Loader";
import FooterCard from "../../../components/homepage/FooterCard";
import { Toaster } from "react-hot-toast";
import { selectUserDetails } from "../../../redux/userSlice";
import whatsApppIcon from "../../../assets/images/whatsApppIcon.svg";

const AccountMenu = ({ children }) => {
  // const [user, setUser] = useState({});
  const userDetail = useSelector(selectUserDetails);
  // console.log(userDetail, "user");
  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     const response = await getUserProfile();

  //     if (response?.data?.success) {
  //       setUser(response?.data?.data);
  //     } else {
  //       console.log(response?.data?.errorMessage);
  //     }
  //   };

  //   fetchProfile();
  // }, [user]);
  return (
    <div>
      <Header />
      <Navigation />
      <section className="frame-parentAccount">
        <div className="frame-groupAccount">
          <div className="account-parentAccount">
            <h3 className="accountAccount">Account</h3>
            <div className="steven-robertAccount">{userDetail?.username}</div>
          </div>
          <div className="frame-childAccount" />
        </div>
        <div className="frame-containerAccount">
          <AccountLinksTab />
          {/*Ui Component*/}

          {children}
        </div>
      </section>

      <div
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
          cursor: "pointer",
          zIndex: 2000,
        }}
        onClick={() =>
          window.open(
            "https://api.whatsapp.com/send?phone=60193278400",
            "_blank"
          )
        }
      >
        <img
          style={{ width: "70px" }}
          src={whatsApppIcon}
          alt="whats-app-icon"
        />
      </div>
      <FooterCard />
      <Toaster position="top-right" />
    </div>
  );
};
// export default withLayout(AccountMenu,{isProductPage: false, isNestedPageName: false});
export default AccountMenu;
