import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  pages: 0,
  totalNotifications: 0,
};
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationList: (state, action) => {
      state.notifications = action.payload;
    },

    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setTotalNotification: (state, action) => {
      state.totalNotifications = action.payload;
    },
  },
});
export const { setNotificationList, setPages, setTotalNotification } =
  notificationSlice.actions;
export const selectNotifications = (state) => state.notification.notifications;
export const selectPages = (state) => state.notification.pages;
export const selectTotalItem = (state) => state.notification.totalNotifications;

export default notificationSlice.reducer;
