// productSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productList: [],
  filteredList: [],
  pageCount: 0,
  selectedPageNumber: 0,
  totalItem: 0
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductList: (state, action) => {
        state.productList = action.payload
    },
    setFilteredList: (state, action) => {
        state.filteredList = action.payload
    },
    setPageCount: (state, action) => {
        state.pageCount = action.payload
    },
    setTotalItem: (state, action) => {
        state.totalItem = action.payload
    },
    setSelectedPageNo: (state, action) => {
        state.selectedPageNumber = action.payload
    }
  }
});

export const { 
    setProductList, 
    setFilteredList,
    setPageCount, 
    setTotalItem,
    setSelectedPageNo
 } = productSlice.actions;

export const selectProductList  = (state) => state.product.productList 
export const selectFiltered  = (state) => state.product.filteredList 
export const selectPageCount  = (state) => state.product.pageCount 
export const selectedPageNo  = (state) => state.product.selectedPageNumber 
export const selectTotalItem  = (state) => state.product.totalItem 

export default productSlice.reducer;
