import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LuEye, LuEyeOff } from "react-icons/lu";

import "../../assets/scss/base/pages/authentication/ResetPassword.scss";
import "../../assets/scss/base/pages/index.scss";
import { logo } from "../../assets/images/authentication";
import { resetPassword } from "../../service/auth.service";
import { useNavigate } from "react-router-dom";
import { setLoginModalToggle } from "../../redux/modalsSlice";
import XClose from "../../assets/images/homepage/x-close.svg";
import Alertircle from "../../assets/images/homepage/alert-circle.svg";
import toast, { Toaster } from "react-hot-toast";
import { removeUserSession } from "../../utils/AuthService";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordMatch, setPasswordMatch] = useState(false);

  const [passwordOneLowerUpperCase, setPasswordOneLowerUpperCase] =
    useState(false);
  const [passwordOneNumber, setPasswordOneNumber] = useState(false);
  const [passwordOneSpecialCharacter, setPasswordOneSpecialCharacter] =
    useState(false);
  const [passwordLength, setPasswordLength] = useState(false);

  const [passwordFieldClicked, setPasswordFieldClicked] = useState(false);
  const [confirmPasswordFieldClicked, setConfirmPasswordFieldClicked] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePasswordTwo, setTogglePasswordTwo] = useState(false);

  const handleShowPasswordOne = () => {
    setTogglePassword(!togglePassword);
  };
  const passwordNumberRegex = /^(?=.*\d)/;
  const passwordSpecialRegex = /^(?=.*\W)/;
  const passwordLengthRegex = /^.{8,}$/;
  const passwordLowerUpperRegex = /^(?=.*[a-z])(?=.*[A-Z])/;
  let timeoutId;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordMatch) {
      if (
        passwordOneLowerUpperCase &&
        passwordMatch &&
        passwordOneLowerUpperCase &&
        passwordOneSpecialCharacter &&
        passwordLength
      ) {
        try {
          const result = await resetPassword({
            password: newPassword,
          });

          if (result?.data?.success) {
            navigate("/resetpassword/successful");
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={Alertircle} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>Please clear all the error</h4>
                {/* <p>This product is not removed from wishlist</p> */}
              </div>

              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#D92D20",
              color: "#FFD7D3",
            },
          }
        );
      }
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Password is not same</h4>
              {/* <p>This product is not removed from wishlist</p> */}
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };
  const handleChange = (value) => {
    if (value) {
      setPasswordFieldClicked(true);
    }
    if (value == confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }

    setNewPassword(value);

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setPasswordOneLowerUpperCase(passwordLowerUpperRegex.test(value));
      setPasswordOneSpecialCharacter(passwordSpecialRegex.test(value));
      setPasswordOneNumber(passwordNumberRegex.test(value));
      setPasswordLength(passwordLengthRegex.test(value));
    }, 300);
  };
  const handleConfirmPassword = (value) => {
    if (newPassword == value) {
      setConfirmPassword(value);
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
    if (value?.length > 0) {
      setConfirmPasswordFieldClicked(true);
    } else {
      setConfirmPasswordFieldClicked(false);
    }
  };
  const handleTogglePasswordTwo = () => {
    setTogglePasswordTwo(!togglePasswordTwo);
  };
  return (
    <div className="reset-passwordReset">
      <div className="contentReset">
        <div className="headerReset">
          <img
            className="logo-1-icon"
            alt="logo"
            onClick={() => navigate("/")}
            src={logo}
          />
          <div className="text-parentReset">
            <div className="text8Reset">Create New Password</div>
            <div className="label-textReset">
              Enter new password which is never used before
            </div>
          </div>
        </div>
        <form className="content2Reset" method="post">
          <div className="formReset">
            <div className="input-fieldReset">
              <div className="input-fieldReset">
                <div className="label1Reset">Password*</div>
                <div className="pass-div" style={{ width: "100%" }}>
                  <input
                    className="input4Reset"
                    onChange={(e) => handleChange(e.target.value)}
                    type={togglePassword ? "text" : "password"}
                    placeholder="Create a password"
                    style={{ width: "100%" }}
                  />
                  {togglePassword ? (
                    <LuEyeOff
                      className="eye-icon-1"
                      onClick={handleShowPasswordOne}
                    />
                  ) : (
                    <LuEye
                      className="eye-icon-1"
                      onClick={handleShowPasswordOne}
                    />
                  )}
                </div>
                {passwordFieldClicked && (
                  <div className="hint-text4">
                    Your password must contain:
                    <br />
                    {passwordOneLowerUpperCase ? (
                      <p className="password-lower-upper-green">
                        {" "}
                        ✔ {`a lowercase and uppercase letter (a-z; A-Z)`}{" "}
                      </p>
                    ) : (
                      <p className="password-lower-upper-red">
                        {" "}
                        ✔ {`a lowercase and uppercase letter (a-z; A-Z)`}{" "}
                      </p>
                    )}
                    {passwordOneNumber ? (
                      <p className="password-number-green"> ✔ a number </p>
                    ) : (
                      <p className="password-number-red"> ✔ a number </p>
                    )}
                    {passwordOneSpecialCharacter ? (
                      <p className="password-special-character-green">
                        {" "}
                        ✔ {`a special character ($ & ! @ ^ *)`}{" "}
                      </p>
                    ) : (
                      <p className="password-special-character-red">
                        {" "}
                        ✔ {`a special character ($ & ! @ ^ *)`}{" "}
                      </p>
                    )}
                    {passwordLength ? (
                      <p className="password-length-green">
                        {" "}
                        ✔ at least 8 characters{" "}
                      </p>
                    ) : (
                      <p className="password-length-red">
                        {" "}
                        ✔ at least 8 characters{" "}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="hint-text1Reset">
                Must be at least 8 characters.
              </div>
            </div>
            <div className="input-fieldReset">
              <div className="input-fieldReset">
                <div className="label1Reset">Confirm Password*</div>
                <div className="pass-div" style={{ width: "100%" }}>
                  <input
                    className="input4Reset"
                    type={togglePasswordTwo ? "text" : "password"}
                    placeholder="Confirm Created password"
                    onChange={(e) => handleConfirmPassword(e.target.value)}
                    style={{ width: "100%" }}
                  />
                  {togglePasswordTwo ? (
                    <LuEyeOff
                      className="eye-icon-1"
                      onClick={handleTogglePasswordTwo}
                    />
                  ) : (
                    <LuEye
                      className="eye-icon-1"
                      onClick={handleTogglePasswordTwo}
                    />
                  )}
                </div>
                {confirmPasswordFieldClicked && (
                  <span
                    className={
                      passwordMatch
                        ? "confirm-password-check-green"
                        : "confirm-password-check-red"
                    }
                  >
                    Password is {!passwordMatch && "not"} same
                  </span>
                )}
              </div>
              <div className="hint-text1Reset">
                Must be at least 8 characters.
              </div>
            </div>
          </div>

          <button onClick={handleSubmit} className="actions1Reset">
            <div className="buttons1Reset">
              <div className="text9Reset">Reset Password</div>
            </div>
          </button>
          <div className="rowReset">
            <div className="remember-passwordReset">Remember password?</div>
            <button className="buttons2Reset">
              <img className="icon7Reset" alt="" src="/icon111.svg" />
              <div
                className="text10Reset"
                onClick={() => {
                  navigate("/");
                  dispatch(setLoginModalToggle(true));
                }}
              >
                Login
              </div>
              <img className="icon7Reset" alt="" src="/icon111.svg" />
            </button>
          </div>
        </form>
      </div>
      <Toaster position="top-right" />
    </div>
  );
};

export default ResetPassword;
