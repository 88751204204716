import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DeliveryAddressListing from "../../components/cart/DeliveryAddressListing";
import AccountMenu from "./AccountMenuNavigation/AccountMenu";

//styles
import "../../assets/scss/base/pages/account/AddressBook/AddressBookPage.scss";
//images
import EmptyAddressBookBanner from "../../assets/images/account/AddressBook/EmptyAdressBook.svg";
import { useSelector } from "react-redux";
import {
  selectBillingAddressList,
  selectShippingAddressList,
  setBillingAddressList,
  setDefaultBillingAddressId,
  setDefaultShippingAddressId,
  setShippingAddressList,
} from "../../redux/addressSlice";
import addressListService from "../../service/address.service";
import { useEffect } from "react";
import Loader from "../../components/micro/loader/Loader";
import { Helmet } from "react-helmet";

const AddressBook = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const shippingAddressList = useSelector(selectShippingAddressList);
  const billingAddressList = useSelector(selectBillingAddressList);
  const [isAddAdressModeOn, setIsAddAdressModeOn] = useState(false);
  const fetchShippingAddressList = async () => {
    setIsLoading(true);
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Shipping",
    });
    if (response?.data?.success) {
      dispatch(setShippingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultShippingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
    setIsLoading(false);
  };

  const fetchBillingAddressList = async () => {
    const response = await addressListService({
      getAll: true,
      filter: "Active",
      type: "Billing",
    });
    if (response?.data?.success) {
      dispatch(setBillingAddressList(response?.data?.data?.list));
      dispatch(
        setDefaultBillingAddressId(
          response?.data?.data?.list?.find((item) => item?.isDefault)?.id || ""
        )
      );
    }
  };
  useEffect(() => {
    fetchShippingAddressList();
    fetchBillingAddressList();
  }, []);

  // THis is Account Books 
  return (
    <AccountMenu>
       <Helmet>
                <meta charSet="utf-8" />
                <title > Account | Address Book</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <div className="AddressBook__Main__Container">
        <h4 className="AddressBook__Main__Container__Header">Address Book</h4>
        {isLoading ? (
          <Loader />
        ) : (shippingAddressList?.length || billingAddressList?.length) < 1 ? (
          !isAddAdressModeOn ? (
            <div className="AddressBook__Empty__Container">
              <img
                src={EmptyAddressBookBanner}
                alt="EmptyAddresBookBanner"
                className="AddressBook__Empty__Container__Banner"
              />
              <h4 className="AddressBook__Empty__Container__Header">
                You haven't added any address yet!
              </h4>
              <p className="AddressBook__Empty__Container__Para">
                Add new address now
              </p>
              <button
                className="AddressBook__Empty__Container__Btn"
                onClick={() => setIsAddAdressModeOn(true)}
              >
                + Add new Address
              </button>
            </div>
          ) : (
            <DeliveryAddressListing style={{ width: "100%" }} />
          )
        ) : (
          <DeliveryAddressListing style={{ width: "100%" }} />
        )}
      </div>
    </AccountMenu>
  );
};

export default AddressBook;
