// productSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSmallScreen: false,
  userPCoinBalance: 0,
  screenSize: 0,
  pCoinValue: 0,
  temporaryPhoneNumber: '',
  categoryDropdownValue: {},
  isLoginPopup: false,
  bannerList: []
};

const utilitySlice = createSlice({
  name: 'utility',
  initialState,
  reducers: {
    setIsSmallScreen: (state, action) => {
        state.isSmallScreen = action.payload
    },
    setScreenSize:(state, action) => {
      state.screenSize = action.payload
    },
    setUserPCoinBalance: (state, action) => {
      state.userPCoinBalance = action.payload
    },
    setPCoinValue: (state, action) => {
      state.pCoinValue = action.payload
    },
    setTemporaryPhoneNumber: (state, action) => {
      state.temporaryPhoneNumber = action.payload
    },
    setCategoryDropdownValue: (state, action) => {
      state.categoryDropdownValue = action.payload
    },
    setIsLoginPopup: (state, action) => {
      state.isLoginPopup = action.payload
    },
    setBannerList: (state, action) => {
      state.bannerList = action.payload
    }
  }
});

export const { 
    setIsSmallScreen,
    setUserPCoinBalance,
    setPCoinValue,
    setTemporaryPhoneNumber,
    setCategoryDropdownValue,
    setIsLoginPopup,
    setScreenSize,
    setBannerList
 } = utilitySlice.actions;

 export const selectScreenSize  = (state) => state.utility.screenSize 
 export const selectIsSmallScreen  = (state) => state.utility.isSmallScreen 
 export const selectUserPCoinBalance  = (state) => state.utility.userPCoinBalance 
 export const selectPCoinValue  = (state) => state.utility.pCoinValue 
 export const selectTemporaryPhoneNumber  = (state) => state.utility.temporaryPhoneNumber 
 export const selectCategoryDropdownValue = (state) => state.utility.categoryDropdownValue 
 export const selectIsLoginPopup = (state) => state.utility.isLoginPopup 
 export const selectBannerList = (state) => state.utility.bannerList 

export default utilitySlice.reducer;
