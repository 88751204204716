import "../../assets/scss/base/pages/authentication/ResetPasswordSuccessful.scss";
import "../../assets/scss/base/pages/index.scss";
import { passwordChangeSuccess } from "../../assets/images/authentication";
import { logo } from "../../assets/images/authentication";
import { useNavigate } from "react-router-dom";
import { setLoginModalToggle } from "../../redux/modalsSlice";
import { useDispatch } from "react-redux";
import { removeUserSession } from "../../utils/AuthService";
import { setLogout } from "../../redux/authSlice";
import { setUserDetail } from "../../redux/userSlice";
import {
  setCartList,
  setSelectedCartItemsId,
  setSelectedCartItemsList,
} from "../../redux/cartSlice";
import {
  setNotificationList,
  setPages,
  setTotalNotification,
} from "../../redux/notificationSlice";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLoginClick = () => {
    dispatch(setLogout(false));
    removeUserSession();
    dispatch(setUserDetail(" "));
    dispatch(setCartList([]));
    dispatch(setSelectedCartItemsList([]));
    dispatch(setSelectedCartItemsId([]));
    dispatch(setNotificationList([]));
    dispatch(setPages(0));
    dispatch(setTotalNotification(0));
    navigate("/");
    dispatch(setLoginModalToggle(true));
  };

  return (
    <div className="reset-password">
      <div className="content">
        <div>
          <img
            className="logo"
            alt="logo"
            onClick={() => navigate("/")}
            src={logo}
          />
        </div>
        <img
          className="logo-1-icon"
          alt="reset password success"
          src={passwordChangeSuccess}
        />
        <div className="text6">
          <div className="welcome-back-glad">Password Changed!</div>
          <div className="hurray-your-password">
            Hurray! Your password has been changed successfully.
          </div>
        </div>
        <button onClick={handleLoginClick} className="buttons">
          Back to Login
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
