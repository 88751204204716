import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { MdClear } from "react-icons/md";

//images
import check from "../../../assets/images/account/Order/check.png";

//styled
import "../.././../assets/scss/base/pages/account/Order/Modal/SuccessMessageModal.scss";

const SuccessMessageModal = ({ isOpenSuccesModal, setIsOpenSuccessModal }) => {
  return (
    <div className="main">
      <Modal isOpen={isOpenSuccesModal}  backdropClassName='light__modal__background'>
        <MdClear
          className="Success__Modal__Cross__Icon"
          onClick={() => {
            setIsOpenSuccessModal(false);
          }}
        />
        <ModalBody>
          <div className="Success__Modal__Cont">
            <img src={check} alt="succesIcon" className="S" />
            <p className="Modal__Message__Text">
              Your rating and review has been <br />
              successfully implemented
            </p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SuccessMessageModal;
