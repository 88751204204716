import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";

import xClose from "../../assets/images/homepage/xClose.svg";

import "../../assets/scss/base/pages/SplashPop/SplashPopup.scss";
import { getBannersList } from "../../service/dashboard.service";
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

const SplashBannerPopup = (args) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [splashBanner, setSplashBanner] = useState("");

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await getBannersList({
          filter: "Active",
          type: "Splash",
        });
        if (response?.data?.success) {
          setSplashBanner(response?.data?.data?.list);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchBanner();
  }, []);

  const handleClose = () => setModalOpen(false);
  return (
    <div style={{ position: "relative" }}>
      {splashBanner.length > 0 && (
        <Dialog open={modalOpen} onClose={handleClose}>
          <img
            src={xClose}
            alt="cross-Tcon"
            className="Cross_Icon"
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",
              background: "white",
              borderRadius: "50%",
              cursor: "Pointer",
            }}
            onClick={() => handleClose()}
          />
          {splashBanner[0]?.file && (
            <img
              src={`${url}/${splashBanner[0]?.file}`}
              alt="splash-Banner"
              style={{ width: "100%", height: "495px" }}
            />
          )}

          {/* <h1>sjdhjsdh</h1> */}
        </Dialog>
      )}
    </div>
  );
};

export default SplashBannerPopup;
